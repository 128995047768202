import { Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import ParticleComponent from "./Components/Particles/Particles";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import FixedSocialBar from "./Components/FixedSocialBar/FixedSocialBar";
import Faq from "./Components/Faq/Faq";
import MediaAboutUs from "./Components/MediaAboutUs/MediaAboutUs";
import AssociatePartners from "./Components/AssociatePartners/AssociatePartners";
import Home from "./pages/Home";
import Team from "./pages/Team/Team";
function App() {
  AOS.init({
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function

    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
  });
  return (
    <div>
      <ParticleComponent />
      <FixedSocialBar />
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/team" element={<Team />}></Route>
      </Routes>
      <Faq />
      <AssociatePartners />
      <MediaAboutUs />
      <Footer />
    </div>
  );
}

export default App;
