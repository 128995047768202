import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  Cell,
  Tooltip,
} from "recharts";
import styles from "./Tokenomics.module.css";

const CustomizedLabel = (props) => {
  const { x, y, width, value } = props;
  const labelX = x + width / 2;
  const labelY = y - 10;

  return (
    <g>
      <text
        x={labelX}
        y={labelY}
        fill="#000"
        textAnchor="middle"
        className={styles.topLabel}
        fontSize={16}
      >
        {value}%
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0];
    return (
      <div className={styles.tooltip}>
        <p className={styles.value}>{data.value}%</p>
        <p className={styles.label}>{label}</p>
      </div>
    );
  }
  return null;
};

const Tokenomics = () => {
  const [tooltipData, setTooltipData] = useState(null);
  const data = [
    { label: "ICO", value: 10, color: "#4C8ED5" },
    { label: "Airdrop", value: 15, color: "#D67320" },
    { label: "Community Reward", value: 10, color: "#B668E8" },
    { label: "Product Marketing", value: 20, color: "#ffa500" },
    { label: "Foundation", value: 15, color: "#26CAB8" },
    { label: "Partnership & Ecosystem", value: 30, color: "#E6527E" },
  ];

  const [barWidth, setBarWidth] = useState(65);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setBarWidth(30);
      } else {
        setBarWidth(65);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (data, index) => {
    setTooltipData({ ...data[index], index });
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
  };

  return (
    <section id="tokenomics" className="sectionPadding">
      <div className={["appContainer", styles.wrapper].join(" ")}>
        <h3 className={["sectionHeading", styles.heading].join(" ")}>
          <span className="highlight">UNS </span>Tokenomics
        </h3>

        <div className={styles.tokenomics}>
          <div className={styles.chartContainer}>
            <ResponsiveContainer className={styles.rechartsWrapper}>
              <BarChart
                data={data}
                isAnimationActive={false}
                barSize={barWidth}
                margin={{ top: 27 }}
              >
                <YAxis hide />
                <XAxis
                  dataKey="label"
                  axisLine={false}
                  tickLine={false}
                  interval={0}
                  tick={{ width: 90 }}
                />
                <Bar dataKey="value" isAnimationActive={false}>
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                      onMouseEnter={() => handleMouseEnter(data, index)}
                      onMouseLeave={handleMouseLeave}
                      cursor="pointer" // Set the cursor to pointer on hover
                    />
                  ))}
                  <LabelList
                    dataKey="value"
                    content={<CustomizedLabel />}
                    position="insideTop"
                  />
                </Bar>
                {/* Show CustomTooltip component */}
                <Tooltip
                  cursor={false}
                  content={<CustomTooltip />}
                  isAnimationActive={false}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <h4 className={styles.total}>Maximum Supply : 1 Billion</h4>
      </div>
    </section>
  );
};

export default Tokenomics;
