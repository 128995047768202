import React from "react";
import styles from "./Team.module.css";

import {
  pandirajan,
  snajeevanR,
  mAskshayavalarmathi,
  jagadheeshKumarM,
  kViswanathan,
  asNallasivam,
  nChandrasekar,
  sPalanisamy,
  aRavichandran,
  sSaravanakumar,
  sJagadheeshKumar,
  govindrarajV,
  ramalingamR,
  perumalG,
  aSathya,
  trThangavelu,
  cGanasan,
  aNithya,
  tMohankumar,
  kKrishnamoorthis,
  pVijaayakanth,
  vChinnasamy,
  aSabapathi,
  pKathiravan,
  nNatrayan,
  mPerumal,
  gKrittianan,
  mGnanavel,
  gRanagasamy,
  muthusamyKm,
  sanmugumvG,
} from "../../images/images";

const Team = () => {
  const teamsData = [
    { img: pandirajan, name: "PANDIYRAJAN P ", position: "Group Manager" },
    { img: "https://i.ibb.co/Yd7jHdb/SANJEEVAN-R.png", name: "SANJEEVAN R ", position: "CMO" },
    {
      img: mAskshayavalarmathi,
      name: "M.AKSHAYAVALARMATHI",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: jagadheeshKumarM,
      name: "JAGADHEESH KUMAR M",
      position: "Energies (Solar Power Plants & Wind Mills)",
    },
    {
      img: kViswanathan,
      name: "K. VISWANATHAN ",
      position: "Agriculture (Hydroponic Farming)",
    },
    { img: asNallasivam, name: "A.S. NALLASIVAM ", position: "CLA" },
    {
      img: nChandrasekar,
      name: "N CHANDRASEKAR ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: sPalanisamy,
      name: "S.PALANISAMY ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: aRavichandran,
      name: "A.RAVICHANDRAN ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: sSaravanakumar,
      name: "S SARAVANAKUMAR",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: sJagadheeshKumar,
      name: "S.JAGADHEESH KUMAR ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: govindrarajV,
      name: "GOVINDARAJ V ",
      position: "Energies (Solar Power Plants & Wind Mills)",
    },
    // {
    //   img: ramalingamR,
    //   name: "RAMALINGAM R ",
    //   position: "Energies (Solar Power Plants & Wind Mills)",
    // },
    {
      img: perumalG,
      name: "PERUMAL.G ",
      position: "Energies (Solar Power Plants & Wind Mills)",
    },
    {
      img: aSathya,
      name: "A SATHYA ",
      position: "Energies (Solar Power Plants & Wind Mills)",
    },
    {
      img: trThangavelu,
      name: "T. R. THANGAVELU ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: cGanasan,
      name: "C GANESAN",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: aNithya,
      name: "A NITHYA ",
      position: "Agriculture (Hydroponic Farming)",
    },
    { img: tMohankumar, name: "T MOHANKUMAR", position: "Crypto Exchange" },
    {
      img: kKrishnamoorthis,
      name: "K KRISHNAMOORTHI ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: pVijaayakanth,
      name: "P. VIJAYAKANTH ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: vChinnasamy,
      name: "V CHINNASAMY  ",
      position: "Energies (Solar Power Plants & Wind Mills)",
    },
    {
      img: aSabapathi,
      name: "A.SABAPATHI ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: pKathiravan,
      name: "P KATHIRAVAN  ",
      position: "Energies (Solar Power Plants & Wind Mills)",
    },
    {
      img: nNatrayan,
      name: "N.NATRAYAN ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: mPerumal,
      name: "M.PERUMAL ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: gKrittianan,
      name: "G.KRITTINAN ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: mGnanavel,
      name: "M.GNANAVEL ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: gRanagasamy,
      name: "G RANGASAMY ",
      position: "Agriculture (Hydroponic Farming)",
    },
    {
      img: muthusamyKm,
      name: "Muthusamy K M ",
      position: "Advisor (Agriculture)",
    },
    { img: sanmugumvG, name: "Sanmugum V G ", position: "Advisor ( Exchange)" },
  ];
  return (
    <section className="sectionPadding">
      <div className={["appContainer", styles.wrapper].join(" ")}>
        <h3 className={["sectionHeading", styles.heading].join(" ")}>
          <span className="highlight"> UNS CORE </span>TEAM MEMBERS
        </h3>
        <div className={styles.teamWrapper}>
          {teamsData.slice(0, teamsData.length - 6).map((el, i) => (
            <div className={styles.teamCard} key={i}>
              <figure>
                <img src={el.img} alt={el.name} />
              </figure>
              <figcaption>
                <div className={styles.nameAndPosition}>
                  <h4 className={styles.name}>{el.name}</h4>
                  <h6 className={styles.position}>{el.position}</h6>
                </div>
              </figcaption>
            </div>
          ))}{" "}
          {teamsData.slice(-6).map((el, i) => (
            <div
              className={[styles.teamCard, styles.secondTeamCard].join(" ")}
              key={i}
            >
              <figure>
                <img src={el.img} alt={el.name} />
              </figure>
              <figcaption>
                <div className={styles.nameAndPosition}>
                  <h4 className={styles.name}>{el.name}</h4>
                  <h6 className={styles.position}>{el.position}</h6>
                </div>
              </figcaption>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
