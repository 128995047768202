import React, { useState } from "react";
import styles from "./Faq.module.css";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { faq } from "../../images/images";
import "./Faq.css";

const Faq = () => {
  const [activeFaq, setActiveFaq] = useState(null);

  const faqs = [
    {
      question: "What is UNS token ?",
      answer:
        " UNS token is a BEP-20 token with a vision to become a multisector utility token. As a first step towards building its ecosystem, UNS token will have its own Cryptocurrency Exchange called as UNS Exchange, in which UNS token will be the governing native token followed by partnering with various projects in 4 different sectors of Education, Healthcare, Agriculture and Energy.",
    },
    {
      question: "What is the competitive advantage of UNS token ?",
      answer:
        "Unlike many other Exchange tokens that act only as the utility token within the Crypto Exchange, UNS token will additionally act as utility token in the Exchange on the one hand and will bring multiple utility across various sectors, becoming useful for day to day value transfer and service utility.",
    },
    {
      question: "Why UNS token is called multi sector utility token?",
      answer:
        "UNS is focused on building partnerships with various organizations specifically in essential sectors: Agriculture, Education, Energies, Healthcare, Ecommerce, Blockchain Enthusiast Organizations and Individuals, CEXes, DEXes, Gaming Development Organizations, Sports Product and Service Companies, Hospitality Service Companies etc.",
    },

    {
      question: "What is the price of UNS token ?",
      answer:
        "UNS price can be found on Aggregator platforms like Coinmarketcap and Coingecko.",
    },
    {
      question: "Where can I use UNS token?",
      answer:
        " UNS tokens can be used on the UNS store and UNS staking platform.",
    },
    {
      question: "Will there be any incentives when we use UNS tokens?",
      answer: `Yes, there will be multiple incentives available while using the token that include fee reduction, airdrops, launch rewards and on top of all these, in-house incentives also will be there. There will be deliberate incentive systems built with partners who we collaborate with while building UNS token utility so that, the UNS token holders can benefit from a passive income stream. On staking UNS tokens at https://staking.uns.technology, you can enjoy benefits in terms of staking rewards (<a href='https://docs.google.com/document/d/107rnCWwrI6BpV2kEVjoYEjwK4iTCBQb4/edit?usp=sharing&ouid=104345514601730377212&rtpof=true&sd=true' target="_blank" class="tAndC">T & C Apply</a>).`,
    },
    {
      question: "What is the supply structure of UNS token ?",
      answer:
        "The maximum supply of UNS token is 1 billion. All the tokens that are distributed during ICO has a lock up period spread over 2 years so as to ensure there will not be any market dumps. All the tokens kept aside for ecosystem building and promotions have a lockup period spread over 4 years so as to protect the funds and interest of early investors and at the same time to ensure that there won’t be any sudden dumps in the market.",
    },
    {
      question: "How is UNS token protecting investor interests ?",
      answer:
        "20% of UNS tokens kept aside for product and marketing is vested for 4 years and the foundation /team tokens are also vested for 4 years.UNS token has limited supply So ultimately building a demand and utility is enough to ensure an organic sustainable growth for the token there by protecting investor funds .ICO funds are channelled into exchange and partnership developments which also adds value towards investor funds and helps in demand building and ecosystem development.",
    },
    {
      question: "Who are behind UNS token?",
      answer:
        "SURESH SATHYANARAYANAN the Honorary CEO and His Team of Experts in Hydroponics, renewable energy, wealth based coaching, pharma & blockchain technology.",
    },
    {
      question: "What is the blockchain used for UNS token ?",
      answer:
        "UNS token is created on binance blockchain and hence, it is a BEP-20 token.",
    },
    {
      question:
        "Where is UNS token listed ? Will it be available in centralised exchanges ?",
      answer:
        " UNS token listing will start with PANCAKESWAP, followed by UNISWAP. Then it will be available in Centralised Exchanges including UNS Exchange and phase 3 onwards as per the road map.",
    },
    {
      question: "Will there be a demand for UNS token in long term?",
      answer:
        " Huge Organic Demand is expected for UNS TOKEN since it will act as a backbone of UNS Exchange on the  one hand and it’ll also have its projects across the Globe in all the much needed 4 service sectors for mankind.",
    },
    {
      question: "How can I get in touch with project team ?",
      answer:
        "You can get in touch with through <a href='mailTo:info@uns.technology'>info@uns.technology</a>",
    },
    {
      question: "Where is UNS Whitepaper available ?",
      answer:
        "UNS Whitepaper is available at <a href='www.uns.html'>www.uns.technology.</a>",
    },
  ];
  const socialMedias = [
    { name: "Telegram", link: "https://t.me/uns_token" },
    { name: "Twitter", link: "https://bit.ly/3wgm8vW" },
    {
      name: "Facebook",
      link: "https://www.facebook.com/people/UNS/100085407069571/",
    },
    { name: "Instagram", link: "https://bit.ly/3ptgQth" },
    { name: "Discord", link: "https://bit.ly/3dvzgqv" },
    { name: "YouTube", link: "https://bit.ly/3PXGjpP" },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A80963356&amp;keywords=uns%20digital%20technologies%20private%20limited&amp;origin=RICH_QUERY_SUGGESTION&amp;position=0&amp;searchId=80df9acc-c544-4ba5-9856-b4c5a94df046&amp;sid=CRs",
    },
  ];
  return (
    <section className={["sectionPadding", styles.mainWrapper].join(" ")}>
      <div className={["appContainer", styles.wrapper].join(" ")}>
        <figure className={styles.figure}>
          <img src={faq} alt="#" className={styles.image} />
        </figure>
        <h3 className={["sectionHeading", styles.heading].join(" ")}>
          Looking for Answer <span className="highlight">?</span>
        </h3>
        <div className={[styles.allFaqs].join(" ")}>
          {faqs.map((el, i) => (
            <div key={i} className={styles.faq}>
              <div
                className={[
                  styles.questionContainer,
                  !activeFaq === i && styles.paddingHide,
                ].join(" ")}
              >
                <p
                  className={styles.question}
                  onClick={() => {
                    if (activeFaq === i) {
                      setActiveFaq(null);
                    } else {
                      setActiveFaq(i);
                    }
                  }}
                >
                  {" "}
                  {el.question}
                </p>
                {activeFaq === i ? (
                  <MdKeyboardArrowUp
                    className={styles.icon}
                    onClick={() => setActiveFaq(null)}
                  />
                ) : (
                  <MdKeyboardArrowDown
                    className={styles.icon}
                    onClick={() => setActiveFaq(i)}
                  />
                )}
              </div>
              {activeFaq === i && (
                <p
                  dangerouslySetInnerHTML={{ __html: el.answer }}
                  className={styles.answer}
                ></p>
              )}
            </div>
          ))}
          <div className={styles.faq}>
            <div
              className={[
                styles.questionContainer,
                !activeFaq && styles.paddingHide,
              ].join(" ")}
            >
              <p
                className={styles.question}
                onClick={() => {
                  if (activeFaq === "socialMedia") {
                    setActiveFaq(null);
                  } else {
                    setActiveFaq("socialMedia");
                  }
                }}
              >
                {" "}
                Where can I get updates about UNS token project ?
              </p>

              {activeFaq ? (
                <MdKeyboardArrowUp
                  className={styles.icon}
                  onClick={() => setActiveFaq(null)}
                />
              ) : (
                <MdKeyboardArrowDown
                  className={styles.icon}
                  onClick={() => setActiveFaq("socialMedia")}
                />
              )}
            </div>
            {activeFaq === "socialMedia" && (
              <div>
                <p className={styles.answer}>
                  {" "}
                  Through social media handles, especially Twitter, YouTube and
                  Telegram.
                </p>
                <ul className={styles.socialMediaContainer}>
                  {socialMedias.map((socialMedia, index) => (
                    <li key={index}>
                      <a
                        href={socialMedia.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.socialMedia}
                      >
                        #{socialMedia.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Faq;
