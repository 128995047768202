import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import styles from "./Team.module.css";
import {
  sureshSathyanarayanan,
  gokulaVarma,
  munishBatra,
  anandJohn,
  taisiyaShuykova,
  ilonaKarpinskaya,
  viewMoreTeamMember,
} from "../../../images/images";
import { Link } from "react-router-dom";

const Team = () => {
  const teamsData = [
    {
      img: sureshSathyanarayanan,
      name: "SURESH SATHYANARAYANAN",
      position: "CEO (Hon)",
      linkedin: "https://bit.ly/3K9q73h",
    },
    {
      img: gokulaVarma,
      name: "GOKULA VARMA",
      position: "CTO",
      linkedin: "https://bit.ly/3R0iTku",
    },
    {
      img: munishBatra,
      name: "MUNISH BATRA",
      position: "Head-Learning and Development",
      linkedin: "https://www.linkedin.com/in/munish-batra/",
    },
    {
      img: anandJohn,
      name: "ANAND JOHN",
      position: "Consultant",
      linkedin: "https://www.linkedin.com/in/anand-john-b7804013/",
    },
    {
      img: taisiyaShuykova,
      name: "taisiya Shuykova",
      position: "Advisor",
      linkedin: "https://www.linkedin.com/in/taisiya-shuykova/",
    },
    {
      img: ilonaKarpinskaya,
      name: "Ilona Karpinskaya",
      position: "Advisor",
      linkedin: "https://www.linkedin.com/in/ilona-karpinskaya-159139208/",
    },
  ];
  return (
    <section id="team" className={["sectionPadding", styles.wrapper].join(" ")}>
      {" "}
      <h3 className={["sectionHeading", styles.heading].join(" ")}>
        <span className="highlight">Meet </span>The Team
      </h3>
      <div className={["appContainer", styles.wrapper].join(" ")}>
        <div className={styles.teamWrapper}>
          {teamsData.map((el, i) => (
            <div className={styles.teamCard} key={i}>
              <figure>
                <img src={el.img} alt={el.name} />
              </figure>
              <figcaption>
                <div>
                  <h4 className={styles.name}>{el.name}</h4>
                  <h6 className={styles.position}>{el.position}</h6>
                </div>

                <a
                  href={el.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.iconContaner}
                >
                  <FaLinkedinIn className={styles.linkedinIcon} />
                </a>
              </figcaption>
            </div>
          ))}

          <div className={styles.teamCard}>
            <figure>
              <img src={"https://i.ibb.co/cxg275k/Untitled-1.png"} alt="" />
            </figure>
            <figcaption style={{ justifyContent: "center" }}>
              <Link onClick={() => {
                let to="https://uns.technology/team.pdf";
                window.open(to, "_blank");
              }} className={styles.button}>
                VIEW UNS CORE TEAM MEMBERS
              </Link>
            </figcaption>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
