import React from "react";
import styles from "./RoadMap.module.css";

const RoadMap = () => {
  const roadmapData = [
    {
      date: "Q3 2022",
      info: "ICO",
    },
    {
      date: "Q4 2022",
      info: " UNS Airdrop campaign",
    },
    {
      date: "Q1 2023",
      info: "Listing on CEX",
    },
    {
      date: "Q2 2023",
      info: "Setting up 2 Hydroponic Units",
    },
    {
      date: "Q3 2023",
      info: "E-commerce platform launch with UNS as utility currency <br /> Onboarding exotic vegetables and fruits, Educational courses, Solar power products and healthcare products",
    },
    {
      date: "Q4 2023",
      info: " Listing on DEX",
    },
    {
      date: "Q1 2024",
      info: " Own Centralized Exchange Launch",
    },
    {
      date: "Q2 2024",
      info: "Real Estate Tokenization (NFT) <br /> Setting up one more hydroponic unit with training centre",
    },
    {
      date: "Q3 2024",
      info: " Gaming pltform launch <br /> Starting solar power plant installation",
    },
    {
      date: "Q1 2025",
      info: "Own Blockchain launch <br /> Electricity generation and distribution start from solar power plants",
    },

    {
      date: "Q2 2025",
      info: " Hospitality Service Launch <br/>Sports launch and service launch",
    },
   
  ];
  return (
    <section
      id="roadmap"
      className={["sectionPadding", styles.wrapper].join(" ")}
    >
      <h3 className={["sectionHeading", styles.heading].join(" ")}>
        Road <span className="highlight">Map </span>
      </h3>

      <p className={styles.title}>
        UNS TOKEN ROADMAP IS THE FIRST STEP TOWARDS BUILDING <br />
        THE UNS UTILITY ECOSYSTEM LEADING TO THE EXCHANGE & MULTISECTOR
        PARTNERSHIPS.
      </p>

      <div className={styles.roadmap}>
        {roadmapData.map((el, i) => {
          if (i % 2 === 0) {
            return (
              <div
                key={i}
                className={[styles.roadmapCard, styles.invertCard].join(" ")}
              >
                <div className={styles.storyCard}>
                  <div className={styles.storyContent}>
                    <h4 dangerouslySetInnerHTML={{ __html: el.info }}></h4>
                  </div>
                </div>
                <div className={styles.storyDateCard}>
                  <h4>{el.date}</h4>
                </div>
              </div>
            );
          } else {
            return (
              <div className={styles.roadmapCard} key={i}>
                <div className={styles.storyDateCard}>
                  <h4>{el.date}</h4>
                </div>
                <div className={styles.storyCard}>
                  <div className={styles.storyContent}>
                    <h4 dangerouslySetInnerHTML={{ __html: el.info }}></h4>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </section>
  );
};

export default RoadMap;
