import React from "react";

function Icon() {
  return (
    <svg
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      className="animated"
      version="1.1"
      viewBox="0 0 500 500"
    >
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 253.418,
          MsTransformOrigin: 253.418,
          transformOrigin: 253.418,
        }}
      >
        <path
          d="M312.57 432.43c59.86-31.51 106.93-79.69 134.27-135.88 25.74-52.89 53.93-123 38.79-150.83C459.91 98.36 67.62 23.05 23.86 66s131.78 385.28 206 390c25.46 1.63 54.97-9 82.71-23.57z"
          style={{
            WebkitTransformOrigin: 253.418,
            MsTransformOrigin: 253.418,
            transformOrigin: 253.418,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M312.57 432.43c59.86-31.51 106.93-79.69 134.27-135.88 25.74-52.89 53.93-123 38.79-150.83C459.91 98.36 67.62 23.05 23.86 66s131.78 385.28 206 390c25.46 1.63 54.97-9 82.71-23.57z"
          style={{
            WebkitTransformOrigin: 253.418,
            MsTransformOrigin: 253.418,
            transformOrigin: 253.418,
          }}
          fill="#FFF"
          className="animable"
          opacity="0.7"
        ></path>
      </g>
      <g
        className="animable animator-hidden"
        style={{
          WebkitTransformOrigin: 295.87,
          MsTransformOrigin: 295.87,
          transformOrigin: 295.87,
        }}
      >
        <ellipse
          cx="396.8"
          cy="436.23"
          fill="#263238"
          rx="65.14"
          ry="14.48"
          style={{
            WebkitTransformOrigin: 396.8,
            MsTransformOrigin: 396.8,
            transformOrigin: 396.8,
          }}
          className="animable"
        ></ellipse>
        <ellipse
          cx="194.94"
          cy="436.23"
          fill="#263238"
          rx="65.14"
          ry="14.48"
          style={{
            WebkitTransformOrigin: 194.94,
            MsTransformOrigin: 194.94,
            transformOrigin: 194.94,
          }}
          className="animable"
        ></ellipse>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 396.853,
          MsTransformOrigin: 396.853,
          transformOrigin: 396.853,
        }}
      >
        <path
          d="M373.22 412.41s-5.28 7.84-7.23 9.53-12.5 7.83-14.86 8.68-2.18 2.33-1.57 3.6 4.17 4 13.95 3.6 10.64-.42 14.27-1.26 13.83-.21 13.83-.21 6.8-.22 8.33-1.91.94-13.77.94-13.77z"
          style={{
            WebkitTransformOrigin: 375.079,
            MsTransformOrigin: 375.079,
            transformOrigin: 375.079,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M400.88 429.86c-2.32.28-5.81.8-10.66 1.83-10 2.11-11.11 2.75-25.57 3a48.61 48.61 0 01-15.47-2 3.23 3.23 0 00.38 1.55c.61 1.27 4.17 4 13.95 3.6s10.64-.42 14.27-1.27 13.83-.2 13.83-.2 6.8-.22 8.33-1.91c.53-.61.8-2.46.94-4.6z"
          style={{
            WebkitTransformOrigin: 375.03,
            MsTransformOrigin: 375.03,
            transformOrigin: 375.03,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M409.05 416.86a36.52 36.52 0 01-3.36 7.63c-2.43 4.23-9.77 8.89-14.08 11.86s-4.14 4.23-1 5.71 15.26 2.12 19.3 1.91 16-3.6 17.73-3.81 3.82.22 5.38-3.17-.2-11.87-.2-11.87-10-7.83-14-8.68-7.59-1.91-9.77.42z"
          style={{
            WebkitTransformOrigin: 410.986,
            MsTransformOrigin: 410.986,
            transformOrigin: 410.986,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M433 437a9.06 9.06 0 00.63-3.41 33.6 33.6 0 00-6.56 2.56c-3.66 2.12-8 4.45-18 4.66a144.69 144.69 0 01-20.69-1.5c-.49 1.1.32 1.9 2.17 2.76 3.15 1.49 15.26 2.12 19.3 1.91s16-3.6 17.73-3.81 3.9.21 5.42-3.17z"
          style={{
            WebkitTransformOrigin: 410.935,
            MsTransformOrigin: 410.935,
            transformOrigin: 410.935,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M366.28 300s3 41.38 4.54 51.33 3.62 48 3.6 49a57.56 57.56 0 01-.13 5.86c-.21.58-2.17.78-2.39 1.75a42.47 42.47 0 00-.35 7c.17 1 2.13 1.17 5.41 3.12S390.37 423 394.1 423s7.24.39 7.25-.2.24-10.93.31-14-.89-3.9-.64-6.44-.65-102.36-.65-102.36l3.31 1s2.07 65.19 2.68 73-.22 35.93-.22 35.93-1.78.78-1.8 1.56c-.07 3.48 1.14 7.35 1.73 10.76a28.17 28.17 0 0110.63-.51c3.19.34 7 .57 10 1.8a18.72 18.72 0 006.15 1.62 4.92 4.92 0 002.23-3.32 16.59 16.59 0 00-1.56-9.37c-1.87-3.9-2.4-6.44-2.06-12.68s5-31.64 5.2-40 1.32-32.79 1.57-35.14 1.61-28.3 1.61-28.3L436 291l-69.8 2.53z"
          style={{
            WebkitTransformOrigin: 403.02,
            MsTransformOrigin: 403.02,
            transformOrigin: 403.02,
          }}
          fill="#1DD21D"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M381.26 304.13l19.21 13.79h.06c-.09-10.66-.16-17.92-.16-17.92l3.31 1s.22 6.93.54 16.54h0l22.13-14.2-23.49-11.09-16.73.61z"
          style={{
            WebkitTransformOrigin: 403.805,
            MsTransformOrigin: 403.805,
            transformOrigin: 403.805,
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M384.14 131.3L357 143.09s-18.59 42-19.1 45.68 3.54 5.36 6.73 7.07 17.62 1.08 17.62 1.08l-6.17 103.87s6.19 2.36 13.89 4.51 20.52 4.7 20.52 4.7l2.84-11.37 2.53 12s20.89-.42 28.91-3 26.92-10.29 26.92-10.29l-4.4-73.63-1.15-15.87.86-19.09s9.51-2.14 9.57-4.71-5.57-41.36-6.57-43.72-10.22-5.36-11.29-5.58-21.59-6.65-21.59-6.65-23.41-1.5-32.98 3.21z"
          style={{
            WebkitTransformOrigin: 397.213,
            MsTransformOrigin: 397.213,
            transformOrigin: 397.213,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M389.43 125.73s-3.68.85-4.35 1.71-6.29 11.8-6.29 11.8l5.75 3-6.15 5.57 15.9 29.81L424.47 147l-6.37-4.07 5.71-5.15s-2.8-9.43-4.68-11.79a13.12 13.12 0 00-4.23-3.44z"
          style={{
            WebkitTransformOrigin: 401.43,
            MsTransformOrigin: 401.43,
            transformOrigin: 401.43,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 401.715,
            MsTransformOrigin: 401.715,
            transformOrigin: 401.715,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M387.31 124.01L388.38 133.88 395.23 164.76 414.71 131.09 416.46 120.16 386.97 119.94 387.31 124.01z"
          className="animable"
        ></path>
        <path
          d="M386.74 82.41s-6.37 5.57-6 9.43a42 42 0 002.18 8.8l.5 6.43 1.51-.43-.63-10.08 7.67-6.22 13.73.66s4 4.07 5.48 5.15 4.88 3.43 4.88 3.43l1.1 8.36 1.73-.43s.54-4.93.78-5.79.95-13.51.79-16.08-8.82-9.87-17.62-11.15-14.06 3-15.83 5.14-.27 2.78-.27 2.78z"
          style={{
            WebkitTransformOrigin: 400.604,
            MsTransformOrigin: 400.604,
            transformOrigin: 400.604,
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M393.08 87.34s-8.77 7.29-8.81 9.22-.08 22.73 1 24.66 7.08 10.73 10.06 12.44 6 2.15 8.6.22 11-10.72 12.58-13.72.93-22.31.93-22.31 1 1.29-2.34-1.5a17.32 17.32 0 01-4.79-6.86s-13.63.85-17.23-2.15z"
          style={{
            WebkitTransformOrigin: 400.918,
            MsTransformOrigin: 400.918,
            transformOrigin: 400.918,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M417.39 97.85v.26c.33.16 0-.26 0-.26z"
          style={{
            WebkitTransformOrigin: 417.463,
            MsTransformOrigin: 417.463,
            transformOrigin: 417.463,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M395.28 133.66c3 1.72 6 2.15 8.6.22s11-10.72 12.58-13.72c1.47-2.8 1-19.76.94-22a8.86 8.86 0 01-1.47-1l.13 2.5-.41 18.23-3.72 2.78a25.89 25.89 0 00-14.12-4.07c-8 .21-10.84 3.43-10.84 3.43l-2.7-23.38c0 1.93-.08 22.73 1 24.66s7.03 10.69 10.01 12.35z"
          style={{
            WebkitTransformOrigin: 400.894,
            MsTransformOrigin: 400.894,
            transformOrigin: 400.894,
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M396.33 106v10.94a2.78 2.78 0 003 1.07 3 3 0 002-2.15"
          style={{
            WebkitTransformOrigin: 398.83,
            MsTransformOrigin: 398.83,
            transformOrigin: 398.83,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M417.66 104.93s1.22-6.43 2.93-6 2.28 4.07.89 8.57-3.76 4.51-3.76 4.51z"
          style={{
            WebkitTransformOrigin: 419.96,
            MsTransformOrigin: 419.96,
            transformOrigin: 419.96,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M385 104.93s-.93-6.44-2.67-6-2.45 4.07-1.26 8.58 3.55 4.5 3.55 4.5z"
          style={{
            WebkitTransformOrigin: 382.74,
            MsTransformOrigin: 382.74,
            transformOrigin: 382.74,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M403.07 103s5.18-.86 8.18 0"
          style={{
            WebkitTransformOrigin: 407.16,
            MsTransformOrigin: 407.16,
            transformOrigin: 407.16,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M394.68 102.57s-4.47-2.36-6.87-.86"
          style={{
            WebkitTransformOrigin: 391.245,
            MsTransformOrigin: 391.245,
            transformOrigin: 391.245,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M408.56 107.5c0 1.19-.58 2.15-1.23 2.15s-1.16-1-1.14-2.15.58-2.14 1.24-2.14 1.16.96 1.13 2.14z"
          style={{
            WebkitTransformOrigin: 407.375,
            MsTransformOrigin: 407.375,
            transformOrigin: 407.375,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M393.49 107.5c0 1.18-.57 2.14-1.23 2.14s-1.16-1-1.13-2.14.57-2.14 1.23-2.14 1.16.96 1.13 2.14z"
          style={{
            WebkitTransformOrigin: 392.31,
            MsTransformOrigin: 392.31,
            transformOrigin: 392.31,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M395.11 121.87a10.3 10.3 0 009.5-1.07"
          style={{
            WebkitTransformOrigin: 399.86,
            MsTransformOrigin: 399.86,
            transformOrigin: 399.86,
          }}
          fill="none"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 366.17,
            MsTransformOrigin: 366.17,
            transformOrigin: 366.17,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M364.1 154.89L366.38 175.39 368.24 162.5"
          className="animable"
        ></path>
        <path
          d="M429.85 161.41a20.12 20.12 0 00-1.54 4.62c-.48 3.7 2.72 18.34 2.72 18.34"
          style={{
            WebkitTransformOrigin: 429.645,
            MsTransformOrigin: 429.645,
            transformOrigin: 429.645,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M434.91 151.19s-1.1 2-2.4 4.57"
          style={{
            WebkitTransformOrigin: 433.71,
            MsTransformOrigin: 433.71,
            transformOrigin: 433.71,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 439.71,
            MsTransformOrigin: 439.71,
            transformOrigin: 439.71,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M434.98 187.28L444.44 180.28"
          className="animable"
        ></path>
        <path
          d="M444.87 187.3l-38.95-1.37-22.05 13.66 56.34 12.31s11.21-2 14-4.88 3.94-10 4-13.27-2.09-11.91-2.09-11.91"
          style={{
            WebkitTransformOrigin: 421.041,
            MsTransformOrigin: 421.041,
            transformOrigin: 421.041,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M348.8 181.26l63.4-5.35 2.94 12.87-62.51 23.36s-9.09 2.15-13.06-3.43a20.18 20.18 0 01-3.73-15.87c1-4.5 4.94-9.43 4.94-9.43"
          style={{
            WebkitTransformOrigin: 375.318,
            MsTransformOrigin: 375.318,
            transformOrigin: 375.318,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M412.6 177.41s9.69-.21 12.44 1.93 9.94 7.94 9.94 7.94l-20.23-.62z"
          style={{
            WebkitTransformOrigin: 423.79,
            MsTransformOrigin: 423.79,
            transformOrigin: 423.79,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M375.31 179.29s-12-5.08-13.8-4.88-8 6.24-8 6.24z"
          style={{
            WebkitTransformOrigin: 364.41,
            MsTransformOrigin: 364.41,
            transformOrigin: 364.41,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 392.92,
            MsTransformOrigin: 392.92,
            transformOrigin: 392.92,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M392.89 283.73L392.95 290.03"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 392.8,
            MsTransformOrigin: 392.8,
            transformOrigin: 392.8,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M392.76 205.06L392.84 278.64"
          className="animable"
        ></path>
        <path
          d="M434.38 248.77a13.89 13.89 0 003.07-1.16"
          style={{
            WebkitTransformOrigin: 435.915,
            MsTransformOrigin: 435.915,
            transformOrigin: 435.915,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M410.45 246.24l.11 3.91s11.39.63 19.89-.6"
          style={{
            WebkitTransformOrigin: 420.45,
            MsTransformOrigin: 420.45,
            transformOrigin: 420.45,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M414 245.07s13.5.59 23.16-1.75"
          style={{
            WebkitTransformOrigin: 425.58,
            MsTransformOrigin: 425.58,
            transformOrigin: 425.58,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M362.28 245.07s7 4.49 19.33 2.93"
          style={{
            WebkitTransformOrigin: 371.945,
            MsTransformOrigin: 371.945,
            transformOrigin: 371.945,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M362.76 241.16s7.35 4.3 18.95 2.35"
          style={{
            WebkitTransformOrigin: 372.235,
            MsTransformOrigin: 372.235,
            transformOrigin: 372.235,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 184.365,
          MsTransformOrigin: 184.365,
          transformOrigin: 184.365,
        }}
      >
        <path
          style={{
            WebkitTransformOrigin: 184.365,
            MsTransformOrigin: 184.365,
            transformOrigin: 184.365,
          }}
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M27.25 135.01L39 135.01 45.19 117.39 51.07 146.77 56.63 107.49 62.2 139.34 67.77 124.5 73.02 133.78 84.16 133.78 88.8 83.98 94.98 169.96 100.86 117.08 105.81 147.07 111.68 133.78 253.64 133.78 258.9 155.43 265.08 113.98 270.03 142.75 275.6 119.55 281.48 133.78 291.06 133.78 297.25 142.44 303.13 112.75 308.38 168.72 314.26 88.93 319.52 142.13 325.7 110.89 330.65 133.47 341.48 133.47"
          className="animable"
          opacity="0.3"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 184.365,
            MsTransformOrigin: 184.365,
            transformOrigin: 184.365,
          }}
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M27.25 239.53L39 239.53 45.19 221.9 51.07 251.28 56.63 212 62.2 243.85 67.77 229.01 73.02 238.29 84.16 238.29 88.8 188.5 94.98 274.47 100.86 221.59 105.81 251.59 111.68 238.29 253.64 238.29 258.9 259.94 265.08 218.49 270.03 247.26 275.6 224.06 281.48 238.29 291.06 238.29 297.25 246.95 303.13 217.26 308.38 273.24 314.26 193.44 319.52 246.64 325.7 215.4 330.65 237.98 341.48 237.98"
          className="animable"
          opacity="0.3"
        ></path>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 175.039,
          MsTransformOrigin: 175.039,
          transformOrigin: 175.039,
        }}
      >
        <path
          d="M72.87 331.32c-30 0-50.21-11.24-54.56-30.61-5-22.41 13.4-56.6 65.72-78.68 99.25-41.89 199.27-24.64 230.42 17.76A82.58 82.58 0 01327 264.26l-8.14 2.5a74 74 0 00-11.3-21.93c-14.06-19.13-45.43-33-83.94-37-43.36-4.57-91.77 3.26-136.31 22.06-34.58 14.6-66.55 43-60.72 69 3.62 16.12 22.72 25 51.12 23.86 27.79-1.16 58.58-7.1 91.49-17.65l2.6 8.11c-33.64 10.78-65.18 16.85-93.74 18-1.74.08-3.48.11-5.19.11z"
          style={{
            WebkitTransformOrigin: 172.261,
            MsTransformOrigin: 172.261,
            transformOrigin: 172.261,
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M104 286s8.47 25.4 32.32 41.56A228.18 228.18 0 00226.61 363c34.37 5.39 50 3.34 57.72-7.44s19.24-34.12 5.64-84.14-18.21-46.69-18.21-46.69a43.61 43.61 0 00-12.32-22.58c-10.51-10.26-29.24-16.93-29.24-16.93s-7.95 9.49-24.63 9.49-36.17-6.93-48.49-6.41-28.47 16.93-40.79 56.18S104 286 104 286z"
          style={{
            WebkitTransformOrigin: 200.134,
            MsTransformOrigin: 200.134,
            transformOrigin: 200.134,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M261.5 332.15c1.27-3.17 1.9-5.7-1.9-12s-16.46-17.1-17.73-27.23-1.9-17.73 2.54-12 19.63 32.29 21.53 15.83-15.2-32.93-16.47-36.73 22.8 0 19.63-10.13-17.73-10.76-21.53-19.63-3.8-13.3-7.6-15.83c-2.85-1.9-31.07-16.32-48.58-21-2.71-.42-5.43-.91-8.11-1.42a7.66 7.66 0 00-4.49.83s7.36 15.25 12.43 19.68 19.63 4.43 18.36 11.4-.63 24.06 5.7 32.29 13.93-1.26 8.23 31 0 43.06 1.9 47.49 4.43 15.2-10.76 16.46c-9.42.79-10.33 4.49-8.75 7.49 6.77 1.76 13.69 3.22 20.71 4.32 26.84 4.21 42.26 3.88 51.4-1.59a7.79 7.79 0 00-1.31-4.52c-5.07-8.25-16.46-21.55-15.2-24.71z"
          style={{
            WebkitTransformOrigin: 228.401,
            MsTransformOrigin: 228.401,
            transformOrigin: 228.401,
          }}
          fill="#FFF"
          className="animable"
          opacity="0.58"
        ></path>
        <path
          d="M143.09 215.64c-.81 7.33-17.36 24.63-29.06 36C104 284 104 286 104 286s8.47 25.4 32.32 41.56a222.11 222.11 0 0041.89 22c-1.4-3.87-3.95-8.48-8.5-11.08-8.87-5.07-14.57-13.93-20.9-21.53s-10.13-5.7-15.2-11.4 0-22.79 8.24-25.33 7.59-10.13 8.86-18.36 10.81-28.45 17.73-32.93 9.5-20.26 9.5-20.26c2.27-7.37 1.17-14.26-2.89-18.31-6.72-1.29-12.93-2.32-17.95-2.11-8.82.47-12.74 15.99-14.01 27.39z"
          style={{
            WebkitTransformOrigin: 141.551,
            MsTransformOrigin: 141.551,
            transformOrigin: 141.551,
          }}
          fill="#FFF"
          className="animable"
          opacity="0.58"
        ></path>
        <path
          d="M104 286s8.47 25.4 32.32 41.56A228.18 228.18 0 00226.61 363c34.37 5.39 50 3.34 57.72-7.44s19.24-34.12 5.64-84.14-18.21-46.69-18.21-46.69a43.61 43.61 0 00-12.32-22.58c-10.51-10.26-29.24-16.93-29.24-16.93s-7.95 9.49-24.63 9.49-36.17-6.93-48.49-6.41-28.47 16.93-40.79 56.18S104 286 104 286z"
          style={{
            WebkitTransformOrigin: 200.134,
            MsTransformOrigin: 200.134,
            transformOrigin: 200.134,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M208.68 209.85s8.17 9.43 16.74 18.26c4.64 4.78 12.56 25.49 9.51 39.95s-5.33 34.63.76 45.66 4.95 23.59 11.42 31.58 14.46 8.75 15.22 16.36"
          style={{
            WebkitTransformOrigin: 235.505,
            MsTransformOrigin: 235.505,
            transformOrigin: 235.505,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M228.08 231s7.74 3.75 11.24 8a30.31 30.31 0 0016.73 10.49c7.74 1.75 14.23 8 16 13.73"
          style={{
            WebkitTransformOrigin: 250.065,
            MsTransformOrigin: 250.065,
            transformOrigin: 250.065,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M261.54 294.68c-.25-2.25-4-10-9.74-14.48s-6.49-10-8.49-13.23-7.74-3.75-7.74-3.75"
          style={{
            WebkitTransformOrigin: 248.555,
            MsTransformOrigin: 248.555,
            transformOrigin: 248.555,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M231.83 293.18s.75 3.5 10.24 9.24 11 13.23 13.23 17.48"
          style={{
            WebkitTransformOrigin: 243.565,
            MsTransformOrigin: 243.565,
            transformOrigin: 243.565,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M248.06 307.66s1.25 8 2.74 11.49"
          style={{
            WebkitTransformOrigin: 249.43,
            MsTransformOrigin: 249.43,
            transformOrigin: 249.43,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M235.69 313.72s-2.11 1.18-2.61 15.91a56 56 0 006.49 28.22"
          style={{
            WebkitTransformOrigin: 236.307,
            MsTransformOrigin: 236.307,
            transformOrigin: 236.307,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M239.32 322.14a40.49 40.49 0 005.74 9.49c4 5 15.73 9.24 18 10.24"
          style={{
            WebkitTransformOrigin: 251.19,
            MsTransformOrigin: 251.19,
            transformOrigin: 251.19,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M221.59 224.27s-1.5 3.75-2.24 12.49 3 12.23 3 12.23"
          style={{
            WebkitTransformOrigin: 220.802,
            MsTransformOrigin: 220.802,
            transformOrigin: 220.802,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M167.05 190.7s3.88 13.87-1.67 32.73-25.51 34.94-34.38 44.92-13.31 23.3-9.43 32.17 10.54 23.3 33.28 30"
          style={{
            WebkitTransformOrigin: 144.264,
            MsTransformOrigin: 144.264,
            transformOrigin: 144.264,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M161.5 233.41s2.77 3.33 0 17.75 3.33 24.4 3.33 24.4"
          style={{
            WebkitTransformOrigin: 162.804,
            MsTransformOrigin: 162.804,
            transformOrigin: 162.804,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M123.23 280.56s-7.21 6.65-8.32 12.75 2.22 11.69 2.22 11.69"
          style={{
            WebkitTransformOrigin: 118.957,
            MsTransformOrigin: 118.957,
            transformOrigin: 118.957,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M137.65 322.15s6.66 8.88 15 13.32"
          style={{
            WebkitTransformOrigin: 145.15,
            MsTransformOrigin: 145.15,
            transformOrigin: 145.15,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M271.76 224.68a43.61 43.61 0 00-12.32-22.58c-10.51-10.26-29.24-16.93-29.24-16.93s-7.95 9.49-24.63 9.49c-1.94 0-3.92-.1-5.91-.26-2.81 4-4.11 8.42-2.3 12.83 0 0 1 3.34 5.9 5.13s8.72-2.56 14.37-.51 7.69 7.18 21 8 13.35.52 16.68 3.59 11.57 5.34 16.45 1.24z"
          style={{
            WebkitTransformOrigin: 234.143,
            MsTransformOrigin: 234.143,
            transformOrigin: 234.143,
          }}
          fill="#C4C4C4"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M109.11 198.24s-7.7 19.51-7.7 27.46-3.07 43.1-1.79 54.65 11.54 6.67 18-.52 8.21-14.88 20.26-26.16 16.9-15.67 21.77-26.17 8.07-18.75 5.83-24.77-15.07-17.82-27.12-19.87-25.66 2.54-29.25 15.38z"
          style={{
            WebkitTransformOrigin: 132.758,
            MsTransformOrigin: 132.758,
            transformOrigin: 132.758,
          }}
          fill="#C4C4C4"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M165.48 202.73s-11.2 2.09-16.65-2.22-12.94-7.72-14.53-17.71-3.17-36.77 3.86-49.48 16.12-18.16 16.12-18.16l-13.39-15.11a6.51 6.51 0 012.27-10.35l.2-.08a6.5 6.5 0 016.89 1.57L162 103.1a3.67 3.67 0 005.32-.1h0a3.67 3.67 0 00.1-4.82l-11-13.11a5.68 5.68 0 013.08-9.2h.22a6.8 6.8 0 016.41 2.32l13.6 15.76a2.8 2.8 0 003.5.6h0a2.8 2.8 0 001.42-2.77l-2.34-20.33a5.9 5.9 0 017-6.51h.23a5.93 5.93 0 014.27 4.59l4.86 24.51s10.89.23 18.84 27.47 1.59 42.9.68 47.21-30 17.48-39.5 24.06a155.55 155.55 0 00-13.21 9.95z"
          style={{
            WebkitTransformOrigin: 177.198,
            MsTransformOrigin: 177.198,
            transformOrigin: 177.198,
          }}
          fill="#1DD21D"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M119.77 110.92a7.64 7.64 0 00-4 6.62 126.53 126.53 0 01-3.91 26.68 134.3 134.3 0 00-3.4 40.17c.23 5 .68 13.85.68 13.85s9.08-7.72 14.3-11.12 12.15 1.41 12.15 1.41-2.19-16.39 1.21-24.53 19.07-17.47 25.2-19.52 9.08-5.44 7.26-12.25-6.13-8-8.85-7.27-7.72 1.82-15.21 3-8.17-2.27-8.63-7.71c-.12-1.48-.19-2.84-.23-4a7.67 7.67 0 00-7.34-7.55 18.21 18.21 0 00-9.23 2.22z"
          style={{
            WebkitTransformOrigin: 138.979,
            MsTransformOrigin: 138.979,
            transformOrigin: 138.979,
          }}
          fill="#999"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <ellipse
          cx="163.81"
          cy="133.78"
          fill="#454545"
          stroke="#263238"
          strokeLinecap="round"
          rx="4.54"
          ry="7.94"
          style={{
            WebkitTransformOrigin: 163.81,
            MsTransformOrigin: 163.81,
            transformOrigin: 163.81,
          }}
          strokeLinejoin="round"
          className="animable"
          transform="rotate(-10.81)"
        ></ellipse>
        <path
          style={{
            WebkitTransformOrigin: 165.705,
            MsTransformOrigin: 165.705,
            transformOrigin: 165.705,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M162.01 103.1L169.4 110.52"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 182.42,
            MsTransformOrigin: 182.42,
            transformOrigin: 182.42,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M179.79 93.91L185.05 100.25"
          className="animable"
        ></path>
        <path
          d="M165.48 202.73S168 169.55 187.73 152s55.27-23.31 59.66-23.38c1.46 0 3.79 4.42 3.79 11.35s5.61 15.72 2.33 17.18-20.56 3.6-26 7.25-1.46 3.65 1.82 12 3.65 10.57-1.46 16.77-22.25 15.32-35 13.5-14.59-9.12-19-7.3-8.39 3.36-8.39 3.36z"
          style={{
            WebkitTransformOrigin: 210.009,
            MsTransformOrigin: 210.009,
            transformOrigin: 210.009,
          }}
          fill="#999"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M255.09 141.93c1.69 7.88 1 14.71-1.58 15.26s-6-5.39-7.69-13.27-1-14.71 1.57-15.26 6.01 5.39 7.7 13.27z"
          style={{
            WebkitTransformOrigin: 250.455,
            MsTransformOrigin: 250.455,
            transformOrigin: 250.455,
          }}
          fill="#454545"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M213.6 182.62a35.74 35.74 0 00-1.63 5.31"
          style={{
            WebkitTransformOrigin: 212.785,
            MsTransformOrigin: 212.785,
            transformOrigin: 212.785,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M227.48 164.44a43.74 43.74 0 00-11.68 13.5"
          style={{
            WebkitTransformOrigin: 221.64,
            MsTransformOrigin: 221.64,
            transformOrigin: 221.64,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M195.17 136.47a33.67 33.67 0 012.94-3.45"
          style={{
            WebkitTransformOrigin: 196.64,
            MsTransformOrigin: 196.64,
            transformOrigin: 196.64,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M182.58 156.87s4.44-8.37 9.2-15.59"
          style={{
            WebkitTransformOrigin: 187.18,
            MsTransformOrigin: 187.18,
            transformOrigin: 187.18,
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M220.12 410.12c-14.68 0-24.44-2.92-29.14-8.72-3.32-4.11-4-9.57-1.82-15.38 2.31-6.23 11.1-15.83 26.12-28.53 11-9.28 21.45-17 21.56-17l3.59 4.9c-11.34 8.29-41.63 32.14-45.57 42.77-1.41 3.82-1.13 7 .85 9.45 3.1 3.83 13.49 9.52 48.21 4.48l.87 6a174 174 0 01-24.67 2.03z"
          style={{
            WebkitTransformOrigin: 216.346,
            MsTransformOrigin: 216.346,
            transformOrigin: 216.346,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M222.11 395.07c-3.85 0-7.71-.15-11.54-.47-29.06-2.42-43.48-13.41-44.09-33.6l6.08-.19c.34 11.45 5 24.94 38.51 27.74s70.86-8.76 71.23-8.88l1.81 5.81c-1.4.44-31.11 9.59-62 9.59z"
          style={{
            WebkitTransformOrigin: 225.295,
            MsTransformOrigin: 225.295,
            transformOrigin: 225.295,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M240.67 415.22a8.51 8.51 0 01-1.4-16.91c.07 0 7.1-1.2 14.37-3.11a8.51 8.51 0 014.36 16.47c-8.05 2.12-15.58 3.38-15.89 3.43a8.57 8.57 0 01-1.44.12z"
          style={{
            WebkitTransformOrigin: 248.231,
            MsTransformOrigin: 248.231,
            transformOrigin: 248.231,
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M170.34 374.33a8.51 8.51 0 01-7.75-5c-2.73-6-2.81-13.33-2.81-14.15a8.52 8.52 0 0117 0 21.6 21.6 0 001.28 7.14 8.53 8.53 0 01-7.75 12zm6.47-19.15h0z"
          style={{
            WebkitTransformOrigin: 169.289,
            MsTransformOrigin: 169.289,
            transformOrigin: 169.289,
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M322.52 321.61c5.28-9.08 15.14-31.05 6.83-58.07a6.69 6.69 0 00-12.79 3.94 59.48 59.48 0 01-5.12 46.59c-2.35-.7-5.38-1.51-8.86-2.24-8.73-1.84-15.22-1.91-28.51 2.67-12.45 4.3-42.17 25.88-45.52 28.33a6.69 6.69 0 007.89 10.8c8.65-6.32 33.05-23.4 42-26.48 10.94-3.77 15.1-3.55 21.39-2.23a93.74 93.74 0 0110.59 2.86 33.33 33.33 0 017.08 15.34c2.65 17-25.34 27.65-40.37 33.35l-2.86 1.09a6.69 6.69 0 002.41 12.94 6.79 6.79 0 002.4-.45l2.79-1.07c18.58-7 53.16-20.15 48.85-47.91a46.07 46.07 0 00-8.2-19.46z"
          style={{
            WebkitTransformOrigin: 279.254,
            MsTransformOrigin: 279.254,
            transformOrigin: 279.254,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <path
          d="M93.93 321.53l1.53 8.39c23.81-2.5 49.41-8.11 76.37-16.75l-2.6-8.11c-26.65 8.55-51.9 14.06-75.3 16.47z"
          style={{
            WebkitTransformOrigin: 132.88,
            MsTransformOrigin: 132.88,
            transformOrigin: 132.88,
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <circle
          cx="238.02"
          cy="281.36"
          r="35.78"
          style={{
            WebkitTransformOrigin: 238.02,
            MsTransformOrigin: 238.02,
            transformOrigin: 238.02,
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
          transform="rotate(-45)"
        ></circle>
        <circle
          cx="241.29"
          cy="281.36"
          r="35.78"
          style={{
            WebkitTransformOrigin: 241.29,
            MsTransformOrigin: 241.29,
            transformOrigin: 241.29,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
          transform="rotate(-45)"
        ></circle>
        <path
          d="M172.39 314.91l-3.71-11.58a458.24 458.24 0 0062.67-25.12l5.57 10.79c-1.09.58-27.11 13.91-64.53 25.91z"
          style={{
            WebkitTransformOrigin: 202.8,
            MsTransformOrigin: 202.8,
            transformOrigin: 202.8,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></path>
        <circle
          cx="238.02"
          cy="281.36"
          r="14.49"
          style={{
            WebkitTransformOrigin: 238.02,
            MsTransformOrigin: 238.02,
            transformOrigin: 238.02,
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></circle>
        <circle
          cx="240.47"
          cy="281.36"
          r="14.49"
          style={{
            WebkitTransformOrigin: 240.47,
            MsTransformOrigin: 240.47,
            transformOrigin: 240.47,
          }}
          fill="#FFF"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animable"
        ></circle>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood
            floodColor="#32DFEC"
            floodOpacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood floodColor="red" floodOpacity="0.5" result="PINK"></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
