import React from "react";
import styles from "./AboutUs.module.css";
import { multi, powering, utility } from "../../../images/images";
import AboutSvg from "../../../images/AnimatingSvg/About";

const AboutUs = () => {
  const data = [
    {
      icon: powering,
      title: "POWERING THE UNS ECOSYSTEM",
      info: " UNS Token, a Multisector Utility BEP20 Token that enables in connecting real-life utilities to the digital world.",
    },
    {
      icon: multi,
      title: "MULTI-SECTOR UTILITY INTEGRATIONS",
      info: " UNS is focused on building partnerships with various organizations specifically in essential sectors: Agriculture, Education, Energies, Healthcare, Ecommerce, Blockchain Enthusiast Organizations and Individuals, CEXes, DEXes, Gaming Development Organizations, Sports Product and Service Companies, Hospitality Service Companies etc.",
    },
    {
      icon: utility,
      title: "UTILITY AT THE CORE",
      info: "  UNS – Bridging Digital World to Real World Utility by integrating payments through UNS Token in the essential sectors.",
    },
  ];
  return (
    <section
      id="about"
      className={["sectionPadding", styles.wrapper].join(" ")}
    >
      <div className="appContainer">
        <div className={styles.about}>
          <div className={styles.imageContainer}>
            <figure className={styles.figure}>
              <AboutSvg />
            </figure>
          </div>
          <div className={styles.headingAndCards} data-aos="fade-up">
            <h3 className={["sectionHeading", styles.heading].join(" ")}>
              Know About <span className="highlight">UNS</span>
            </h3>

            <div className={styles.cards}>
              {data.map((el, i) => (
                <div className={styles.card} key={i}>
                  <figure className={styles.iconContainer}>
                    <img src={el.icon} alt="#" className={styles.icon} />
                  </figure>
                  <figcaption className={styles.textContainer}>
                    <h5 className={styles.title}>{el.title}</h5>
                    <p className={styles.info}>{el.info}</p>
                  </figcaption>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
