import React from "react";
import styles from "./Footer.module.css";
const Footer = () => {
  return (
    <footer className={["sectionPadding", styles.mainWrapper].join(" ")}>
      <div className={styles.footer}>
        <h6 className={styles.text}>
          For Enquiry mail us : info@uns.technology
        </h6>
        <h6 className={styles.text}>
          For support mail us : support@uns.technology
        </h6>
        <h6 className={styles.disclaimer}>
          <a href="https://docs.google.com/document/d/107rnCWwrI6BpV2kEVjoYEjwK4iTCBQb4/edit?pli=1" target="_blank" rel="noopener noreferrer">
            T & C
          </a>
        </h6>
      </div>
    </footer>
  );
};

export default Footer;
