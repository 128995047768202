import React from "react";

const HeroSvg = () => {
  return (
    <svg
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      className="animated"
      version="1.1"
      viewBox="5 5 500 500"
    >
      <g
        style={{
          WebkitTransformOrigin: 245.18,
          MsTransformOrigin: 245.18,
          transformOrigin: 245.18,
        }}
        className="animable animator-hidden"
      >
        <ellipse
          cx="245.18"
          cy="412.39"
          fill="#F5F5F5"
          rx="193.89"
          ry="11.32"
          style={{
            WebkitTransformOrigin: 245.18,
            MsTransformOrigin: 245.18,
            transformOrigin: 245.18,
          }}
          className="animable"
        ></ellipse>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 249.416,
          MsTransformOrigin: 249.416,
          transformOrigin: 249.416,
        }}
        className="animable animator-active"
      >
        <path
          d="M113.55 186.34h-37a5.73 5.73 0 01-5.81-6.34l7.74-88.46a7 7 0 016.81-6.27h9.62a1.63 1.63 0 011.65 1.57v.3a2.26 2.26 0 002.3 2.19H108a2.9 2.9 0 002.79-2.33l.07-.3a1.88 1.88 0 011.82-1.49h9.63a5.79 5.79 0 014.32 1.86 5.85 5.85 0 011.48 4.49l-7.75 88.47a7 7 0 01-6.81 6.31zM85.29 86.25a6 6 0 00-5.82 5.35l-7.74 88.47a4.74 4.74 0 004.8 5.27h37a6 6 0 005.82-5.36l7.74-88.46a4.86 4.86 0 00-1.22-3.74 4.79 4.79 0 00-3.58-1.53h-9.63a.89.89 0 00-.84.7l-.07.3a3.91 3.91 0 01-3.75 3.06h-9.13a3.24 3.24 0 01-3.3-3.15v-.3a.63.63 0 00-.65-.61z"
          style={{
            WebkitTransformOrigin: 99.4226,
            MsTransformOrigin: 99.4226,
            transformOrigin: 99.4226,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 102.585,
            MsTransformOrigin: 102.585,
            transformOrigin: 102.585,
          }}
          fill="#1DD21D"
          d="M78.27 99.14H126.9V100.14H78.27z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 117.49,
            MsTransformOrigin: 117.49,
            transformOrigin: 117.49,
          }}
          fill="#1DD21D"
          d="M112.68 85.25H122.30000000000001V86.25H112.68z"
          className="animable"
        ></path>
        <path
          d="M113.26 140.47H84.88l2.48-28.38h28.38zm-27.29-1h26.37l2.31-26.38H88.28z"
          style={{
            WebkitTransformOrigin: 100.31,
            MsTransformOrigin: 100.31,
            transformOrigin: 100.31,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M113.6 172.52H78.82l.92-10.52h34.78zm-33.69-1h32.77l.75-8.53H80.65z"
          style={{
            WebkitTransformOrigin: 96.67,
            MsTransformOrigin: 96.67,
            transformOrigin: 96.67,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M90.72 155.06A2.74 2.74 0 0188 152a3.28 3.28 0 013.21-2.94 2.74 2.74 0 012.06.89 2.78 2.78 0 01.7 2.14 3.28 3.28 0 01-3.25 2.97zm.44-5a2.27 2.27 0 00-2.21 2 1.74 1.74 0 00.45 1.38 1.77 1.77 0 001.32.56 2.27 2.27 0 002.21-2 1.84 1.84 0 00-.45-1.38 1.77 1.77 0 00-1.32-.53z"
          style={{
            WebkitTransformOrigin: 90.9827,
            MsTransformOrigin: 90.9827,
            transformOrigin: 90.9827,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M97.91 155.06a2.72 2.72 0 01-2.76-3 3.27 3.27 0 013.2-2.94 2.75 2.75 0 012.77 3 3.29 3.29 0 01-3.21 2.94zm.44-5a2.27 2.27 0 00-2.21 2 1.74 1.74 0 001.77 1.94 2.27 2.27 0 002.21-2 1.74 1.74 0 00-1.77-1.94z"
          style={{
            WebkitTransformOrigin: 98.1334,
            MsTransformOrigin: 98.1334,
            transformOrigin: 98.1334,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M105.11 155.06a2.78 2.78 0 01-2.07-.88 2.81 2.81 0 01-.7-2.15 3.28 3.28 0 013.2-2.94 2.71 2.71 0 012.06.89 2.75 2.75 0 01.71 2.14 3.28 3.28 0 01-3.2 2.94zm.43-5a2.27 2.27 0 00-2.21 2 1.81 1.81 0 00.45 1.38 1.77 1.77 0 001.33.56 2.26 2.26 0 002.2-2 1.74 1.74 0 00-1.77-1.94z"
          style={{
            WebkitTransformOrigin: 105.325,
            MsTransformOrigin: 105.325,
            transformOrigin: 105.325,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 378.565,
            MsTransformOrigin: 378.565,
            transformOrigin: 378.565,
          }}
          fill="#1DD21D"
          d="M354.25 286.54H402.88V287.54H354.25z"
          className="animable"
        ></path>
        <path
          d="M389.5 373.74h-37a5.74 5.74 0 01-5.8-6.35l7.74-88.47a7 7 0 016.82-6.27h9.62a1.62 1.62 0 011.64 1.58v.3a2.25 2.25 0 002.29 2.19H384a2.91 2.91 0 002.78-2.28l.06-.3a1.92 1.92 0 011.83-1.49h9.62a5.76 5.76 0 014.32 1.86 5.82 5.82 0 011.48 4.5l-7.74 88.46a7 7 0 01-6.85 6.27zm-28.25-100.09a6 6 0 00-5.82 5.36l-7.74 88.46a4.82 4.82 0 001.22 3.74 4.76 4.76 0 003.58 1.53h37a6 6 0 005.82-5.35l7.74-88.47a4.74 4.74 0 00-4.8-5.27h-9.62a.91.91 0 00-.85.7l-.06.3a3.93 3.93 0 01-3.76 3.07h-9.15a3.24 3.24 0 01-3.29-3.15v-.3a.62.62 0 00-.64-.62z"
          style={{
            WebkitTransformOrigin: 375.394,
            MsTransformOrigin: 375.394,
            transformOrigin: 375.394,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M395.71 300.44h-36.54a2.65 2.65 0 01-2.67-2.93 3.16 3.16 0 013.08-2.84h36.55a2.65 2.65 0 012 .86 2.7 2.7 0 01.69 2.07 3.17 3.17 0 01-3.11 2.84zm-36.13-4.77a2.15 2.15 0 00-2.09 1.93 1.67 1.67 0 00.43 1.3 1.63 1.63 0 001.25.54h36.54a2.15 2.15 0 002.09-1.93 1.71 1.71 0 00-.42-1.3 1.66 1.66 0 00-1.25-.54z"
          style={{
            WebkitTransformOrigin: 377.659,
            MsTransformOrigin: 377.659,
            transformOrigin: 377.659,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M394.88 310h-36.55a2.65 2.65 0 01-2-.86 2.68 2.68 0 01-.68-2.06 3.16 3.16 0 013.09-2.84h36.54a2.63 2.63 0 012.67 2.92 3.15 3.15 0 01-3.07 2.84zm-36.13-4.76a2.14 2.14 0 00-2.09 1.92 1.73 1.73 0 00.42 1.31 1.69 1.69 0 001.25.53h36.55a2.15 2.15 0 002.12-2 1.7 1.7 0 00-.43-1.31 1.66 1.66 0 00-1.25-.53z"
          style={{
            WebkitTransformOrigin: 376.802,
            MsTransformOrigin: 376.802,
            transformOrigin: 376.802,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M394 319.48h-36.5a2.63 2.63 0 01-2.67-2.92 3.17 3.17 0 013.09-2.84h36.54a2.65 2.65 0 012.67 2.93 3.16 3.16 0 01-3.13 2.83zm-36.12-4.76a2.15 2.15 0 00-2.09 1.93 1.64 1.64 0 001.67 1.83H394a2.14 2.14 0 002.09-1.92 1.64 1.64 0 00-1.67-1.84z"
          style={{
            WebkitTransformOrigin: 375.979,
            MsTransformOrigin: 375.979,
            transformOrigin: 375.979,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M391.76 345.58h-36.54a2.65 2.65 0 01-2.67-2.93 3.15 3.15 0 013.08-2.83h36.55a2.63 2.63 0 012.67 2.92 3.17 3.17 0 01-3.09 2.84zm-36.13-4.76a2.15 2.15 0 00-2.09 1.92A1.67 1.67 0 00354 344a1.63 1.63 0 001.25.54h36.54a2.15 2.15 0 002.09-1.93 1.67 1.67 0 00-.42-1.3 1.65 1.65 0 00-1.25-.53z"
          style={{
            WebkitTransformOrigin: 373.701,
            MsTransformOrigin: 373.701,
            transformOrigin: 373.701,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M390.93 355.1h-36.55a2.64 2.64 0 01-2-.85 2.71 2.71 0 01-.69-2.07 3.17 3.17 0 013.09-2.84h36.54a2.65 2.65 0 012.67 2.92 3.15 3.15 0 01-3.06 2.84zm-36.13-4.76a2.14 2.14 0 00-2.09 1.92 1.73 1.73 0 00.42 1.31 1.69 1.69 0 001.25.53h36.55a2.14 2.14 0 002.09-1.92 1.7 1.7 0 00-.43-1.31 1.66 1.66 0 00-1.25-.53z"
          style={{
            WebkitTransformOrigin: 372.841,
            MsTransformOrigin: 372.841,
            transformOrigin: 372.841,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M390.09 364.62h-36.54a2.63 2.63 0 01-2.67-2.92 3.17 3.17 0 013.09-2.84h36.54a2.65 2.65 0 012.67 2.93 3.16 3.16 0 01-3.09 2.83zM354 359.86a2.15 2.15 0 00-2.09 1.93 1.64 1.64 0 001.67 1.83h36.54a2.16 2.16 0 002.1-1.92 1.67 1.67 0 00-.43-1.3 1.65 1.65 0 00-1.25-.54z"
          style={{
            WebkitTransformOrigin: 372.029,
            MsTransformOrigin: 372.029,
            transformOrigin: 372.029,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M413.56 193.9h-37a5.76 5.76 0 01-4.32-1.85 5.86 5.86 0 01-1.48-4.5l7.74-88.47a7 7 0 016.82-6.26h9.62a1.62 1.62 0 011.65 1.57v.3a2.26 2.26 0 002.3 2.19H408a2.91 2.91 0 002.78-2.27l.07-.31a1.87 1.87 0 011.82-1.48h9.62a5.72 5.72 0 015.8 6.35l-7.74 88.47a7 7 0 01-6.79 6.26zM385.3 93.82a6 6 0 00-5.82 5.35l-7.74 88.47a4.74 4.74 0 004.8 5.26h37a6 6 0 005.82-5.35l7.74-88.47a4.74 4.74 0 00-4.81-5.26h-9.62a.9.9 0 00-.85.69l-.06.31a3.94 3.94 0 01-3.76 3.06h-9.14a3.24 3.24 0 01-3.3-3.15v-.3a.63.63 0 00-.65-.61z"
          style={{
            WebkitTransformOrigin: 399.428,
            MsTransformOrigin: 399.428,
            transformOrigin: 399.428,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 402.585,
            MsTransformOrigin: 402.585,
            transformOrigin: 402.585,
          }}
          fill="#1DD21D"
          d="M378.27 106.71H426.9V107.71H378.27z"
          className="animable"
        ></path>
        <path
          d="M415.92 183.57h-39.36l2.44-28.38h39.4zm-38.27-1H415l2.31-26.38H380z"
          style={{
            WebkitTransformOrigin: 397.48,
            MsTransformOrigin: 397.48,
            transformOrigin: 397.48,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 397.48,
            MsTransformOrigin: 397.48,
            transformOrigin: 397.48,
          }}
          fill="#1DD21D"
          d="M396.98 146.78H397.98V191.97H396.98z"
          className="animable"
          transform="rotate(-52.72)"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 397.485,
            MsTransformOrigin: 397.485,
            transformOrigin: 397.485,
          }}
          fill="#1DD21D"
          d="M372.94 168.88H422.03V169.88H372.94z"
          className="animable"
          transform="rotate(-33.89)"
        ></path>
        <path
          d="M421.07 124.74h-39.36l.92-10.52H422zm-38.27-1h37.35l.75-8.52h-37.35z"
          style={{
            WebkitTransformOrigin: 401.855,
            MsTransformOrigin: 401.855,
            transformOrigin: 401.855,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 401.845,
            MsTransformOrigin: 401.845,
            transformOrigin: 401.845,
          }}
          fill="#1DD21D"
          d="M381.68 118.98H422.01V119.98H381.68z"
          className="animable"
          transform="rotate(-13.67)"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 401.85,
            MsTransformOrigin: 401.85,
            transformOrigin: 401.85,
          }}
          fill="#1DD21D"
          d="M401.35 100.12H402.35V138.83H401.35z"
          className="animable"
          transform="rotate(-75.76)"
        ></path>
        <path
          d="M419.87 138.43h-39.36l.92-10.53h39.36zm-38.27-1H419l.75-8.53h-37.4z"
          style={{
            WebkitTransformOrigin: 400.65,
            MsTransformOrigin: 400.65,
            transformOrigin: 400.65,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 400.655,
            MsTransformOrigin: 400.655,
            transformOrigin: 400.655,
          }}
          fill="#1DD21D"
          d="M380.49 132.67H420.82V133.67H380.49z"
          className="animable"
          transform="rotate(-13.67)"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 400.65,
            MsTransformOrigin: 400.65,
            transformOrigin: 400.65,
          }}
          fill="#1DD21D"
          d="M400.15 113.81H401.15V152.52H400.15z"
          className="animable"
          transform="rotate(-75.76)"
        ></path>
        <path
          d="M418.67 152.12h-39.36l.92-10.53h39.36zm-38.27-1h37.36l.74-8.53h-37.35z"
          style={{
            WebkitTransformOrigin: 399.45,
            MsTransformOrigin: 399.45,
            transformOrigin: 399.45,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 399.455,
            MsTransformOrigin: 399.455,
            transformOrigin: 399.455,
          }}
          fill="#1DD21D"
          d="M379.29 146.35H419.62V147.35H379.29z"
          className="animable"
          transform="rotate(-13.67)"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 399.45,
            MsTransformOrigin: 399.45,
            transformOrigin: 399.45,
          }}
          fill="#1DD21D"
          d="M398.95 127.5H399.95V166.21H398.95z"
          className="animable"
          transform="rotate(-75.76)"
        ></path>
        <path
          d="M407.88 239.39a16 16 0 1116-16 16 16 0 01-16 16zm0-31a15 15 0 1015 15 15 15 0 00-15-14.98z"
          style={{
            WebkitTransformOrigin: 407.88,
            MsTransformOrigin: 407.88,
            transformOrigin: 407.88,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M405.12 232.65l-7.46-9.86.8-.61 6.26 8.29a44.57 44.57 0 0116.74-23l2.23-1.57.57.82-2.26 1.59a43.51 43.51 0 00-16.64 23.38z"
          style={{
            WebkitTransformOrigin: 410.96,
            MsTransformOrigin: 410.96,
            transformOrigin: 410.96,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 220.673,
          MsTransformOrigin: 220.673,
          transformOrigin: 220.673,
        }}
        className="animable"
      >
        <path
          d="M297.17 107.79h-26L245 407.1h26a24.26 24.26 0 0023.7-21.79l22.38-255.73a19.66 19.66 0 00-19.91-21.79z"
          style={{
            WebkitTransformOrigin: 281.098,
            MsTransformOrigin: 281.098,
            transformOrigin: 281.098,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M265.67 407.1h-107a19.65 19.65 0 01-19.88-21.79l22.37-255.73a24.26 24.26 0 0123.7-21.79h107a19.65 19.65 0 0119.89 21.79l-22.38 255.73a24.27 24.27 0 01-23.7 21.79z"
          style={{
            WebkitTransformOrigin: 225.27,
            MsTransformOrigin: 225.27,
            transformOrigin: 225.27,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M291.41 112.79H263.6a4 4 0 00-3.86 3.16l-.19.87a9.86 9.86 0 01-9.44 7.72h-26.44a7.94 7.94 0 01-8.09-7.72v-.87a3.26 3.26 0 00-3.32-3.16h-27.84a18.7 18.7 0 00-18.26 16.79l-22.37 255.73a15.14 15.14 0 0015.32 16.79h107a18.71 18.71 0 0018.27-16.79l22.37-255.73a15.14 15.14 0 00-15.34-16.79z"
          style={{
            WebkitTransformOrigin: 225.27,
            MsTransformOrigin: 225.27,
            transformOrigin: 225.27,
          }}
          fill="#FFF"
          className="animable"
        ></path>
        <g
          style={{
            WebkitTransformOrigin: 237.67,
            MsTransformOrigin: 237.67,
            transformOrigin: 237.67,
          }}
          className="animable"
          opacity="0.2"
        >
          <path
            d="M231.2 115.62a2.36 2.36 0 01-2.31 2.13 1.92 1.92 0 01-1.94-2.13 2.36 2.36 0 012.31-2.12 1.92 1.92 0 011.94 2.12z"
            style={{
              WebkitTransformOrigin: 229.074,
              MsTransformOrigin: 229.074,
              transformOrigin: 229.074,
            }}
            className="animable"
          ></path>
          <path
            d="M246.45 113.5h-7.28a2.36 2.36 0 00-2.31 2.12 1.91 1.91 0 001.93 2.13h7.29a2.36 2.36 0 002.31-2.13 1.91 1.91 0 00-1.94-2.12z"
            style={{
              WebkitTransformOrigin: 242.624,
              MsTransformOrigin: 242.624,
              transformOrigin: 242.624,
            }}
            className="animable"
          ></path>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 179.475,
            MsTransformOrigin: 179.475,
            transformOrigin: 179.475,
          }}
          fill="#263238"
          d="M178.25 124.5L180.5 124.5 180.7 122.25 178.45 122.25 178.25 124.5z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 184.07,
            MsTransformOrigin: 184.07,
            transformOrigin: 184.07,
          }}
          fill="#263238"
          d="M182.95 122.25L182.75 124.5 185 124.5 185.2 122.25 185.39 120 183.14 120 182.95 122.25z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 188.67,
            MsTransformOrigin: 188.67,
            transformOrigin: 188.67,
          }}
          fill="#263238"
          d="M187.84 117.75L187.64 120 187.45 122.25 187.25 124.5 189.5 124.5 189.7 122.25 189.89 120 190.09 117.75 187.84 117.75z"
          className="animable"
        ></path>
        <path
          d="M284.58 117.9l-.14 1.65h-1.1l-.29 3.3h1.1l-.15 1.65h9.9l.57-6.6zm4 4.95h-3.3l.29-3.3h3.3z"
          style={{
            WebkitTransformOrigin: 288.76,
            MsTransformOrigin: 288.76,
            transformOrigin: 288.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 213.825,
            MsTransformOrigin: 213.825,
            transformOrigin: 213.825,
          }}
          fill="#263238"
          d="M280.1 296.25L135.38 296.25 147.56 157.09 292.27 157.09 280.1 296.25z"
          className="animable"
          opacity="0.8"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 210.005,
            MsTransformOrigin: 210.005,
            transformOrigin: 210.005,
          }}
          fill="#1DD21D"
          d="M276.27 292.06L131.56 292.06 143.73 152.9 288.45 152.9 276.27 292.06z"
          className="animable"
        ></path>
        <g
          style={{
            WebkitTransformOrigin: 210,
            MsTransformOrigin: 210,
            transformOrigin: 210,
          }}
          className="animable"
          opacity="0.8"
        >
          <path
            style={{
              WebkitTransformOrigin: 210.005,
              MsTransformOrigin: 210.005,
              transformOrigin: 210.005,
            }}
            fill="#FFF"
            d="M276.27 292.06L131.56 292.06 143.73 152.9 288.45 152.9 276.27 292.06z"
            className="animable"
          ></path>
          <path
            d="M276.73 292.56H131l.05-.55 12.23-139.61H289zm-144.63-1h143.71L287.9 153.4H144.19z"
            style={{
              WebkitTransformOrigin: 210,
              MsTransformOrigin: 210,
              transformOrigin: 210,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 211.165,
            MsTransformOrigin: 211.165,
            transformOrigin: 211.165,
          }}
          fill="#FFF"
          d="M268.22 253.73L146.32 253.73 154.11 164.67 276.01 164.67 268.22 253.73z"
          className="animable"
          opacity="0.8"
        ></path>
        <path
          d="M268.68 254.23H145.77l7.88-90.06h122.91zm-121.82-1h120.9l7.7-88.06H154.57z"
          style={{
            WebkitTransformOrigin: 211.165,
            MsTransformOrigin: 211.165,
            transformOrigin: 211.165,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 211.16,
            MsTransformOrigin: 211.16,
            transformOrigin: 211.16,
          }}
          fill="#1DD21D"
          d="M210.66 136.82H211.66V281.57H210.66z"
          className="animable"
          transform="rotate(-52.03)"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 211.165,
            MsTransformOrigin: 211.165,
            transformOrigin: 211.165,
          }}
          fill="#FFF"
          d="M146.32 253.73L276.01 164.67"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 211.165,
            MsTransformOrigin: 211.165,
            transformOrigin: 211.165,
          }}
          fill="#1DD21D"
          d="M132.5 208.7H289.83000000000004V209.7H132.5z"
          className="animable"
          transform="rotate(-34.5)"
        ></path>
        <path
          d="M193.58 266.77a5.12 5.12 0 01-5 4.6 4.15 4.15 0 01-4.2-4.6 5.13 5.13 0 015-4.6 4.15 4.15 0 014.2 4.6z"
          style={{
            WebkitTransformOrigin: 188.98,
            MsTransformOrigin: 188.98,
            transformOrigin: 188.98,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M210.72 266.77a5.12 5.12 0 01-5 4.6 4.15 4.15 0 01-4.19-4.6 5.12 5.12 0 015-4.6 4.14 4.14 0 014.19 4.6z"
          style={{
            WebkitTransformOrigin: 206.126,
            MsTransformOrigin: 206.126,
            transformOrigin: 206.126,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M227.87 266.77a5.12 5.12 0 01-5 4.6 4.14 4.14 0 01-4.19-4.6 5.12 5.12 0 015-4.6 4.15 4.15 0 014.19 4.6z"
          style={{
            WebkitTransformOrigin: 223.274,
            MsTransformOrigin: 223.274,
            transformOrigin: 223.274,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 161.43,
            MsTransformOrigin: 161.43,
            transformOrigin: 161.43,
          }}
          fill="#1DD21D"
          d="M192.46 370.45L124.7 370.45 130.4 305.29 198.16 305.29 192.46 370.45z"
          className="animable"
        ></path>
        <g
          style={{
            WebkitTransformOrigin: 161.43,
            MsTransformOrigin: 161.43,
            transformOrigin: 161.43,
          }}
          className="animable"
          opacity="0.8"
        >
          <path
            style={{
              WebkitTransformOrigin: 161.43,
              MsTransformOrigin: 161.43,
              transformOrigin: 161.43,
            }}
            fill="#FFF"
            d="M192.46 370.45L124.7 370.45 130.4 305.29 198.16 305.29 192.46 370.45z"
            className="animable"
          ></path>
          <path
            d="M192.92 371h-68.77l5.79-66.16h68.77zm-67.67-1H192l5.61-64.16h-66.75z"
            style={{
              WebkitTransformOrigin: 161.43,
              MsTransformOrigin: 161.43,
              transformOrigin: 161.43,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 161.975,
            MsTransformOrigin: 161.975,
            transformOrigin: 161.975,
          }}
          fill="#FFF"
          d="M188.69 352.5L131.61 352.5 135.26 310.8 192.34 310.8 188.69 352.5z"
          className="animable"
        ></path>
        <path
          d="M189.15 353h-58.08l3.73-42.7h58.08zm-57-1h56.07l3.56-40.7h-56.06z"
          style={{
            WebkitTransformOrigin: 161.975,
            MsTransformOrigin: 161.975,
            transformOrigin: 161.975,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 161.975,
            MsTransformOrigin: 161.975,
            transformOrigin: 161.975,
          }}
          fill="#FFF"
          d="M135.26 310.8L188.69 352.5"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 161.97,
            MsTransformOrigin: 161.97,
            transformOrigin: 161.97,
          }}
          fill="#1DD21D"
          d="M161.47 297.76H162.47V365.53999999999996H161.47z"
          className="animable"
          transform="rotate(-52.03)"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 161.975,
            MsTransformOrigin: 161.975,
            transformOrigin: 161.975,
          }}
          fill="#FFF"
          d="M131.61 352.5L192.34 310.8"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 161.975,
            MsTransformOrigin: 161.975,
            transformOrigin: 161.975,
          }}
          fill="#1DD21D"
          d="M125.14 331.15H198.81V332.15H125.14z"
          className="animable"
          transform="rotate(-34.5)"
        ></path>
        <path
          d="M153.74 358.61a2.39 2.39 0 01-2.34 2.15 1.93 1.93 0 01-2-2.15 2.4 2.4 0 012.34-2.16 2 2 0 012 2.16z"
          style={{
            WebkitTransformOrigin: 151.567,
            MsTransformOrigin: 151.567,
            transformOrigin: 151.567,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M161.77 358.61a2.4 2.4 0 01-2.34 2.15 1.94 1.94 0 01-2-2.15 2.41 2.41 0 012.34-2.16 2 2 0 012 2.16z"
          style={{
            WebkitTransformOrigin: 159.597,
            MsTransformOrigin: 159.597,
            transformOrigin: 159.597,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M169.8 358.61a2.4 2.4 0 01-2.34 2.15 1.94 1.94 0 01-2-2.15 2.4 2.4 0 012.34-2.16 2 2 0 012 2.16z"
          style={{
            WebkitTransformOrigin: 167.627,
            MsTransformOrigin: 167.627,
            transformOrigin: 167.627,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 238.38,
            MsTransformOrigin: 238.38,
            transformOrigin: 238.38,
          }}
          fill="#1DD21D"
          d="M269.41 370.45L201.65 370.45 207.35 305.29 275.11 305.29 269.41 370.45z"
          className="animable"
        ></path>
        <g
          style={{
            WebkitTransformOrigin: 238.385,
            MsTransformOrigin: 238.385,
            transformOrigin: 238.385,
          }}
          className="animable"
          opacity="0.8"
        >
          <path
            style={{
              WebkitTransformOrigin: 238.38,
              MsTransformOrigin: 238.38,
              transformOrigin: 238.38,
            }}
            fill="#FFF"
            d="M269.41 370.45L201.65 370.45 207.35 305.29 275.11 305.29 269.41 370.45z"
            className="animable"
          ></path>
          <path
            d="M269.87 371h-68.76l5.79-66.16h68.76zm-67.67-1H269l5.62-64.16h-66.81z"
            style={{
              WebkitTransformOrigin: 238.385,
              MsTransformOrigin: 238.385,
              transformOrigin: 238.385,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 238.925,
            MsTransformOrigin: 238.925,
            transformOrigin: 238.925,
          }}
          fill="#FFF"
          d="M265.64 352.5L208.56 352.5 212.21 310.8 269.29 310.8 265.64 352.5z"
          className="animable"
        ></path>
        <path
          d="M266.1 353H208l3.73-42.7h58.09zm-57-1h56.07l3.56-40.7h-56.06z"
          style={{
            WebkitTransformOrigin: 238.91,
            MsTransformOrigin: 238.91,
            transformOrigin: 238.91,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 238.925,
            MsTransformOrigin: 238.925,
            transformOrigin: 238.925,
          }}
          fill="#FFF"
          d="M212.21 310.8L265.64 352.5"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 238.93,
            MsTransformOrigin: 238.93,
            transformOrigin: 238.93,
          }}
          fill="#1DD21D"
          d="M238.43 297.76H239.43V365.53999999999996H238.43z"
          className="animable"
          transform="rotate(-52.03)"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 238.925,
            MsTransformOrigin: 238.925,
            transformOrigin: 238.925,
          }}
          fill="#FFF"
          d="M208.56 352.5L269.29 310.8"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 238.925,
            MsTransformOrigin: 238.925,
            transformOrigin: 238.925,
          }}
          fill="#1DD21D"
          d="M202.09 331.15H275.76V332.15H202.09z"
          className="animable"
          transform="rotate(-34.5)"
        ></path>
        <path
          d="M230.69 358.61a2.39 2.39 0 01-2.34 2.15 1.93 1.93 0 01-2-2.15 2.4 2.4 0 012.34-2.16 1.94 1.94 0 012 2.16z"
          style={{
            WebkitTransformOrigin: 228.52,
            MsTransformOrigin: 228.52,
            transformOrigin: 228.52,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M238.72 358.61a2.39 2.39 0 01-2.34 2.15 1.93 1.93 0 01-2-2.15 2.4 2.4 0 012.34-2.16 1.94 1.94 0 012 2.16z"
          style={{
            WebkitTransformOrigin: 236.55,
            MsTransformOrigin: 236.55,
            transformOrigin: 236.55,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M246.75 358.61a2.39 2.39 0 01-2.34 2.15 1.94 1.94 0 01-2-2.15 2.41 2.41 0 012.35-2.16 2 2 0 011.99 2.16z"
          style={{
            WebkitTransformOrigin: 244.577,
            MsTransformOrigin: 244.577,
            transformOrigin: 244.577,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 232.81,
            MsTransformOrigin: 232.81,
            transformOrigin: 232.81,
          }}
          fill="#1DD21D"
          d="M268.2 147.13L196.58 147.13 197.42 137.53 269.04 137.53 268.2 147.13z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 183.135,
            MsTransformOrigin: 183.135,
            transformOrigin: 183.135,
          }}
          fill="#263238"
          d="M177.6 144.98H188.67V145.98H177.6z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 183.455,
            MsTransformOrigin: 183.455,
            transformOrigin: 183.455,
          }}
          fill="#263238"
          d="M177.92 141.24H188.98999999999998V142.24H177.92z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 183.785,
            MsTransformOrigin: 183.785,
            transformOrigin: 183.785,
          }}
          fill="#263238"
          d="M178.25 137.5H189.32V138.5H178.25z"
          className="animable"
        ></path>
        <g
          style={{
            WebkitTransformOrigin: 228.315,
            MsTransformOrigin: 228.315,
            transformOrigin: 228.315,
          }}
          className="animable"
          opacity="0.2"
        >
          <path
            d="M223.75 124.54l-73.86 274.63a15.52 15.52 0 009.22 2.93h5.78l74.64-277.56z"
            style={{
              WebkitTransformOrigin: 194.71,
              MsTransformOrigin: 194.71,
              transformOrigin: 194.71,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M291.41 112.79H263.6a4 4 0 00-3.86 3.16l-.19.87a9.88 9.88 0 01-9.1 7.71L175.8 402.1h56.94l74-275.17a15.07 15.07 0 00-15.33-14.14z"
            style={{
              WebkitTransformOrigin: 241.27,
              MsTransformOrigin: 241.27,
              transformOrigin: 241.27,
            }}
            fill="#FFF"
            className="animable"
          ></path>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 236.165,
            MsTransformOrigin: 236.165,
            transformOrigin: 236.165,
          }}
          fill="#1DD21D"
          d="M165.9 131.85H306.43V133.35H165.9z"
          className="animable"
        ></path>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 353.103,
          MsTransformOrigin: 353.103,
          transformOrigin: 353.103,
        }}
        className="animable"
      >
        <path
          d="M318.68 167.92a2.07 2.07 0 01.59-.83 2 2 0 01.75-.25 13.5 13.5 0 016.12-.2.74.74 0 01.4.21.7.7 0 01.11.37c.16 1.13.31 2.26.46 3.38a1.16 1.16 0 01-.49 1.33 34.81 34.81 0 01-5.7 3.57c-1 .2-4.08-.8-5-1.19s1.86-4.52 2.76-6.39z"
          style={{
            WebkitTransformOrigin: 321.45,
            MsTransformOrigin: 321.45,
            transformOrigin: 321.45,
          }}
          fill="#CB7A63"
          className="animable"
        ></path>
        <path
          d="M296.19 202.21c.9-7.13 16.83-26.36 18.93-29.14 1.16-1.55 6-.4 7 2.54-4.35 6-6.75 11.13-9.72 17.95a102.42 102.42 0 01-4.89 9.2c-8.08 11.24-11.71 2.55-11.32-.55z"
          style={{
            WebkitTransformOrigin: 309.141,
            MsTransformOrigin: 309.141,
            transformOrigin: 309.141,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <g
          style={{
            isolation: "isolate",
            WebkitTransformOrigin: 309.141,
            MsTransformOrigin: 309.141,
            transformOrigin: 309.141,
          }}
          className="animable"
          opacity="0.1"
        >
          <path
            d="M296.19 202.21c.9-7.13 16.83-26.36 18.93-29.14 1.16-1.55 6-.4 7 2.54-4.35 6-6.75 11.13-9.72 17.95a102.42 102.42 0 01-4.89 9.2c-8.08 11.24-11.71 2.55-11.32-.55z"
            style={{
              WebkitTransformOrigin: 309.141,
              MsTransformOrigin: 309.141,
              transformOrigin: 309.141,
            }}
            className="animable"
          ></path>
        </g>
        <path
          d="M353.65 219.21s-5.06.07-6.64 1.46l-2.19 1.94a1 1 0 00-.07 1.47c1.18 1.33 3.76 3.92 6.65 4.94a1.93 1.93 0 001.91-.33s3.5-4.38 2.94-5.28z"
          style={{
            WebkitTransformOrigin: 350.386,
            MsTransformOrigin: 350.386,
            transformOrigin: 350.386,
          }}
          fill="#CB7A63"
          className="animable"
        ></path>
        <path
          d="M367 219.69c-.45.25-.91.49-1.39.74l-7.15 2.65c-4.87 2.38-5.24-3.63-5.24-3.63l1.95-1.69 13.28-11s-12.36-9.16-20.36-16.88c-6-6.95-5.24-11.48 1.85-10.72 5.09.54 28.45 22.36 30.48 24.69s-2.58 9.9-13.42 15.84z"
          style={{
            WebkitTransformOrigin: 362.472,
            MsTransformOrigin: 362.472,
            transformOrigin: 362.472,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M367 219.69c-.45.25-.91.49-1.39.74l-7.15 2.65c-4.87 2.38-5.24-3.63-5.24-3.63l1.95-1.69 13.28-11s-12.36-9.16-20.36-16.88c-6-6.95-5.24-11.48 1.85-10.72 5.09.54 28.45 22.36 30.48 24.69s-2.58 9.9-13.42 15.84z"
          style={{
            isolation: "isolate",
            WebkitTransformOrigin: 362.472,
            MsTransformOrigin: 362.472,
            transformOrigin: 362.472,
          }}
          className="animable"
          opacity="0.1"
        ></path>
        <path
          d="M370.83 195.63l-.11-.12z"
          style={{
            WebkitTransformOrigin: 370.775,
            MsTransformOrigin: 370.775,
            transformOrigin: 370.775,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <g
          style={{
            WebkitTransformOrigin: 361.478,
            MsTransformOrigin: 361.478,
            transformOrigin: 361.478,
          }}
          className="animable"
        >
          <path
            d="M324.61 151c-.41-.07-.83.36-.95 1s.1 1.2.51 1.27.84-.36 1-1-.17-1.27-.56-1.27z"
            style={{
              WebkitTransformOrigin: 324.419,
              MsTransformOrigin: 324.419,
              transformOrigin: 324.419,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M323.51 159.19a3.6 3.6 0 01-2.87-1.13 22.82 22.82 0 004-4.76z"
            style={{
              WebkitTransformOrigin: 322.64,
              MsTransformOrigin: 322.64,
              transformOrigin: 322.64,
            }}
            fill="#B74D3C"
            className="animable"
          ></path>
          <path
            d="M327.82 149.14a3 3 0 00-2.51-1h-.12a.37.37 0 01-.38-.37.38.38 0 01.37-.39 3.71 3.71 0 013.17 1.2.46.46 0 01.1.25.4.4 0 01-.63.32z"
            style={{
              WebkitTransformOrigin: 326.63,
              MsTransformOrigin: 326.63,
              transformOrigin: 326.63,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M332.71 186.9c10.69-.11 12.21-6 12.21-6-3.24-3-3.52-8.74-2.95-13.84a11.78 11.78 0 01-3.11 3.11 9.77 9.77 0 01-7.8 1.56.33.33 0 01-.13 0 2.84 2.84 0 01.06.31c.72 4.06.25 8.43-4 9.79.01 0-6.05 5.17 5.72 5.07z"
            style={{
              WebkitTransformOrigin: 335.18,
              MsTransformOrigin: 335.18,
              transformOrigin: 335.18,
            }}
            fill="#CB7A63"
            className="animable"
          ></path>
          <path
            d="M330.6 170.23c.12.49.25 1 .33 1.5a.33.33 0 00.13 0 9.77 9.77 0 007.8-1.56 11.78 11.78 0 003.14-3.07c.14-1.29.35-2.56.56-3.72L331 170z"
            style={{
              WebkitTransformOrigin: 336.58,
              MsTransformOrigin: 336.58,
              transformOrigin: 336.58,
            }}
            fill="#CB7A63"
            className="animable"
          ></path>
          <path
            d="M346 180.48s-1.61-4.65-3.93-6.08-12.33.31-13.89 1-3.07 4.31-3.07 4.31z"
            style={{
              WebkitTransformOrigin: 335.555,
              MsTransformOrigin: 335.555,
              transformOrigin: 335.555,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M346 180.48s-1.61-4.65-3.93-6.08-12.33.31-13.89 1-3.07 4.31-3.07 4.31z"
            style={{
              WebkitTransformOrigin: 335.555,
              MsTransformOrigin: 335.555,
              transformOrigin: 335.555,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.8"
          ></path>
          <path
            d="M332.9 410.37c-1 0-1.89-.13-2.2-.57a.66.66 0 010-.77.9.9 0 01.58-.41c1.25-.35 3.87 1.14 4 1.2a.18.18 0 01.08.18.17.17 0 01-.15.15 13.81 13.81 0 01-2.31.22zm-1.18-1.45a1 1 0 00-.34 0 .52.52 0 00-.36.25c-.12.22-.08.32 0 .39.35.48 2.25.49 3.64.29a7.94 7.94 0 00-2.94-1z"
            style={{
              WebkitTransformOrigin: 332.969,
              MsTransformOrigin: 332.969,
              transformOrigin: 332.969,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M335.17 410.13h-.07c-.91-.41-2.69-2-2.54-2.87 0-.2.17-.45.66-.5a1.33 1.33 0 011 .3c1 .79 1.15 2.8 1.16 2.89a.19.19 0 01-.08.16.19.19 0 01-.13.02zm-1.81-3h-.1c-.32 0-.34.16-.35.2-.09.51 1.12 1.78 2 2.34a4.22 4.22 0 00-1-2.31 1 1 0 00-.63-.26z"
            style={{
              WebkitTransformOrigin: 333.966,
              MsTransformOrigin: 333.966,
              transformOrigin: 333.966,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 339.37,
              MsTransformOrigin: 339.37,
              transformOrigin: 339.37,
            }}
            fill="#CB7A63"
            d="M342.77 392.68L335.3 392.69 335.93 409.95 343.44 409.94 342.77 392.68z"
            className="animable"
          ></path>
          <path
            d="M334.16 409.67c-5.11 3.14-6.68 3.28-12.58 4.58-1.29.28-.48 3.41 2.62 3.4 5.11 0 5.65-.25 10-.25 3.73 0 8 .16 11 .2a1.18 1.18 0 00.27 0 1.1 1.1 0 00.82-1.31l-1.46-6.67a.65.65 0 00-.64-.51H336a3.56 3.56 0 00-1.84.56z"
            style={{
              WebkitTransformOrigin: 333.654,
              MsTransformOrigin: 333.654,
              transformOrigin: 333.654,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            style={{
              isolation: "isolate",
              WebkitTransformOrigin: 339.21,
              MsTransformOrigin: 339.21,
              transformOrigin: 339.21,
            }}
            d="M342.78 392.69L343.12 402.32 335.62 402.33 335.3 392.7 342.78 392.69z"
            className="animable"
            opacity="0.2"
          ></path>
          <path
            d="M398.56 407.26a3.1 3.1 0 01.36-2.34.72.72 0 01.82-.06 1 1 0 01.48.55c.48 1.26-.81 4.11-.87 4.25a.17.17 0 01-.18.11.19.19 0 01-.16-.14 13.76 13.76 0 01-.45-2.37zm1.4-1.36a1.47 1.47 0 000-.36.52.52 0 00-.29-.34c-.24-.1-.35-.05-.41 0-.46.41-.29 2.37.06 3.79a8.18 8.18 0 00.68-3.12z"
            style={{
              WebkitTransformOrigin: 399.399,
              MsTransformOrigin: 399.399,
              transformOrigin: 399.399,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M399 409.58a.17.17 0 010-.07c.33-1 1.83-3 2.73-2.9.21 0 .49.14.59.63a1.33 1.33 0 01-.22 1.06c-.71 1.11-2.8 1.45-2.89 1.46a.15.15 0 01-.17-.06.13.13 0 01-.04-.12zm2.95-2.16v-.11c0-.33-.2-.34-.24-.34-.54 0-1.73 1.33-2.22 2.34a4.31 4.31 0 002.3-1.22 1 1 0 00.21-.67z"
            style={{
              WebkitTransformOrigin: 400.673,
              MsTransformOrigin: 400.673,
              transformOrigin: 400.673,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 392.21,
              MsTransformOrigin: 392.21,
              transformOrigin: 392.21,
            }}
            fill="#CB7A63"
            d="M393.68 392.57L386.39 393.1 390.69 410.5 398.03 409.97 393.68 392.57z"
            className="animable"
          ></path>
          <path
            d="M399.8 408.56c2.28 2.27 3.48 3.43 9.63 4.84 1.36.31.47 3.55-2.76 3.52-5.33-.06-2.41-.28-7-.32-3.89-.05-7.29 0-10.38 0a1.55 1.55 0 01-.31 0 1.16 1.16 0 01-.87-1.37l1.56-6.94a.67.67 0 01.67-.52l7.48.17a2.69 2.69 0 011.98.62z"
            style={{
              WebkitTransformOrigin: 399.064,
              MsTransformOrigin: 399.064,
              transformOrigin: 399.064,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            style={{
              isolation: "isolate",
              WebkitTransformOrigin: 391.15,
              MsTransformOrigin: 391.15,
              transformOrigin: 391.15,
            }}
            d="M393.69 392.58L395.91 401.52 388.61 402.05 386.39 393.1 393.69 392.58z"
            className="animable"
            opacity="0.2"
          ></path>
          <path
            d="M354.15 241a77.78 77.78 0 016.92 13.91c4 10.82 2.58 16.49 3.92 23.81 2.35 12.91 5.88 22.81 8.35 32.6 7.71 30.63 24 85 24 85l-13.17 2.9s-24.42-64.86-31.35-86.77c-6.24-19.68-22.27-71.45-22.27-71.45z"
            style={{
              WebkitTransformOrigin: 363.945,
              MsTransformOrigin: 363.945,
              transformOrigin: 363.945,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M354.15 241a77.78 77.78 0 016.92 13.91c4 10.82 2.58 16.49 3.92 23.81 2.35 12.91 5.88 22.81 8.35 32.6 7.71 30.63 24 85 24 85l-13.17 2.9s-24.42-64.86-31.35-86.77c-6.24-19.68-22.27-71.45-22.27-71.45z"
            style={{
              WebkitTransformOrigin: 363.945,
              MsTransformOrigin: 363.945,
              transformOrigin: 363.945,
            }}
            className="animable"
            opacity="0.4"
          ></path>
          <path
            d="M398.9 393.91l.14 4.69-15.87 1.71s-1.4-5.11-1.34-5.11z"
            style={{
              WebkitTransformOrigin: 390.434,
              MsTransformOrigin: 390.434,
              transformOrigin: 390.434,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M398.9 393.91l.14 4.69-15.87 1.71s-1.4-5.11-1.34-5.11z"
            style={{
              WebkitTransformOrigin: 390.434,
              MsTransformOrigin: 390.434,
              transformOrigin: 390.434,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.8"
          ></path>
          <path
            d="M338 264.83c5.05 16.27 11.45 36.85 14.86 47.64.16.53.34 1.07.52 1.64-1.34-18-4.88-48.27-13.95-49.38a3.29 3.29 0 00-1.43.1z"
            style={{
              isolation: "isolate",
              WebkitTransformOrigin: 345.69,
              MsTransformOrigin: 345.69,
              transformOrigin: 345.69,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M342 240.94s3.85 49.7 4.29 73.19c.4 21.9-1.21 85.7-1.21 85.7h-12.7s-6.06-65.13-8.31-87.37c-2.14-21.05-7.69-71.09-7.69-71.09z"
            style={{
              WebkitTransformOrigin: 331.367,
              MsTransformOrigin: 331.367,
              transformOrigin: 331.367,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M342 240.94s3.85 49.7 4.29 73.19c.4 21.9-1.21 85.7-1.21 85.7h-12.7s-6.06-65.13-8.31-87.37c-2.14-21.05-7.69-71.09-7.69-71.09z"
            style={{
              WebkitTransformOrigin: 331.367,
              MsTransformOrigin: 331.367,
              transformOrigin: 331.367,
            }}
            className="animable"
            opacity="0.4"
          ></path>
          <path
            d="M347 395.38l-.43 4.66h-15.44s-.73-5.22-.67-5.22z"
            style={{
              WebkitTransformOrigin: 338.728,
              MsTransformOrigin: 338.728,
              transformOrigin: 338.728,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M347 395.38l-.43 4.66h-15.44s-.73-5.22-.67-5.22z"
            style={{
              WebkitTransformOrigin: 338.728,
              MsTransformOrigin: 338.728,
              transformOrigin: 338.728,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.8"
          ></path>
          <path
            d="M366.62 256.81l-3.55-1a35 35 0 00-17.48-.52l-4.15-9.48 1.27 10h-1.82a35 35 0 00-15.23 3.09 35.39 35.39 0 01-4.79 2c0-.3-4.47-19.41-4.47-19.41-4.55-31-3.81-46.69-2.44-54.33a10 10 0 018-8.08c1.55-.29 3.28-.56 5.06-.74a138.42 138.42 0 0116-.16l3.54.26c5.59.29 11.32 5 10.31 10.55-3.35 18.32-3.91 31.71-3.68 40.54z"
            style={{
              WebkitTransformOrigin: 339.766,
              MsTransformOrigin: 339.766,
              transformOrigin: 339.766,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M313.11 212.13s-.11-15.27 0-18.1l11.37-7.82a108.56 108.56 0 00-11.37 25.92z"
            style={{
              isolation: "isolate",
              WebkitTransformOrigin: 318.771,
              MsTransformOrigin: 318.771,
              transformOrigin: 318.771,
            }}
            className="animable"
            opacity="0.2"
          ></path>
          <path
            d="M322.76 161.67a10.85 10.85 0 005.45 9 9.43 9.43 0 002.66 1.06.33.33 0 00.13 0 9.77 9.77 0 007.8-1.56 11.78 11.78 0 003.2-3.07c2-2.91 2.83-6.77 4.22-12.19a10.65 10.65 0 00-12.41-13.22c-8.13 1.61-11.35 12.76-11.05 19.98z"
            style={{
              WebkitTransformOrigin: 334.666,
              MsTransformOrigin: 334.666,
              transformOrigin: 334.666,
            }}
            fill="#CB7A63"
            className="animable"
          ></path>
          <path
            d="M328.27 170.67a9.43 9.43 0 002.66 1.06l-.39-4.75s-1.7 3.68-2.27 3.69z"
            style={{
              WebkitTransformOrigin: 329.6,
              MsTransformOrigin: 329.6,
              transformOrigin: 329.6,
            }}
            className="animable"
            opacity="0.2"
          ></path>
          <path
            d="M355.59 156.13c-.86 1.12-2 2.3-3.4 2.42a5.12 5.12 0 01-2.42-.38c-2.5 4.34-7.8 8.93-7.8 8.93a11.69 11.69 0 01-4.16.76 11.83 11.83 0 01-4.34-.83 8.19 8.19 0 00-.82-.31c-.39-.09-2.4-8.55-5.2-9.84l-.63-.3c.19-.89-.09-3.36.78-4.2.47-.46-.82-1.63-.72-2.52a1.43 1.43 0 010-.22c-.89-.56-1-1.77-.62-3 .85-3.22 2.55-2.34 2.55-2.34a3.65 3.65 0 01-.57-2.91c.35-1.84 3.79-2.76 3.79-2.76l1.23.24a4 4 0 012.78-.88 5.06 5.06 0 013.69 2.13 4.18 4.18 0 016.64 2.23 3.5 3.5 0 013.61 1 3.76 3.76 0 011 3.72 2.4 2.4 0 011.74 2.08 13.07 13.07 0 01-.25 3.11 4.79 4.79 0 00.4 3 2.09 2.09 0 002.72.87z"
            style={{
              WebkitTransformOrigin: 340.822,
              MsTransformOrigin: 340.822,
              transformOrigin: 340.822,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M330.45 151.74c-2.23.7-3.33 4-3.39 6.14-.06 2.33 1.52 3.76 3.52 2.16a8.84 8.84 0 003-4.8c.5-2.24-.89-4.24-3.13-3.5z"
            style={{
              WebkitTransformOrigin: 330.37,
              MsTransformOrigin: 330.37,
              transformOrigin: 330.37,
            }}
            fill="#CB7A63"
            className="animable"
          ></path>
        </g>
        <path
          d="M311.33 186.81l-14.1 16c-2 4.53 4.81 9.53 9.59 5.1l16.92-19.49c1.23-2.67 1.39-4.86-.4-6.63-2.34-2.31-6.52-3.79-12.01 5.02z"
          style={{
            WebkitTransformOrigin: 310.773,
            MsTransformOrigin: 310.773,
            transformOrigin: 310.773,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 118.822,
          MsTransformOrigin: 118.822,
          transformOrigin: 118.822,
        }}
        className="animable"
      >
        <path
          d="M118.61 217.33a119 119 0 0012.79 12.3c2.63 2.07 25.14-11.62 27.93-13.18 0 0-2.52-2.48-3.56-2.21-3.93 1-21.46 7-21.46 7-.81-.25-8.9-9.54-8.9-9.54z"
          style={{
            WebkitTransformOrigin: 138.97,
            MsTransformOrigin: 138.97,
            transformOrigin: 138.97,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M162.24 213l-.07-.05.08.08s-2 3.27-4.72 4.09l-2.06-2 .93-6 .06-.37.22-.21 2.81-2.74a1.49 1.49 0 012.07 0l2.11 2a1.46 1.46 0 01.29 1.73z"
          style={{
            WebkitTransformOrigin: 159.797,
            MsTransformOrigin: 159.797,
            transformOrigin: 159.797,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M130.65 214.66c-5.73-8.18-15.82-13.52-18.69-13.56-4.14-.07-5.42.89-3.85 4 2.65 5.34 7 10.78 13.11 17.28z"
          style={{
            WebkitTransformOrigin: 119.056,
            MsTransformOrigin: 119.056,
            transformOrigin: 119.056,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M114.33 201.51a61.36 61.36 0 00-7-2.12A93.21 93.21 0 0094 197.2a77 77 0 00-7.83-.44 3.48 3.48 0 00-.79.09 3.94 3.94 0 00-2.9 4.62c4.39 23.2 4.6 33.5 4 45.45l26.71 1a1.43 1.43 0 001.42-1.2c1.18-6 4.89-19.41 4.89-19.41 3.76-2.67 3.6-5.73 2.62-9.66-.31-1.23-1-2.41-4.06-9-.06-.08-2.35-6.65-3.73-7.14z"
          style={{
            WebkitTransformOrigin: 102.556,
            MsTransformOrigin: 102.556,
            transformOrigin: 102.556,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M97.45 247.31l9.74.35c1.45-11.56.72-20.19.67-33.31.04 12.25-6.98 27-10.41 32.96z"
          style={{
            WebkitTransformOrigin: 102.769,
            MsTransformOrigin: 102.769,
            transformOrigin: 102.769,
          }}
          fill="#FFF"
          className="animable"
        ></path>
        <path
          d="M107.55 252s12 59.41 11.4 84c-.65 25.94-5.74 70.6-5.74 70.6l-6.33-.07s-5.69-33.06-2.43-69.32c-16.6-36.36-20.9-82.9-20.9-82.9z"
          style={{
            WebkitTransformOrigin: 101.261,
            MsTransformOrigin: 101.261,
            transformOrigin: 101.261,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M107.55 252s12 59.41 11.4 84c-.65 25.94-5.74 70.6-5.74 70.6l-6.33-.07s-5.69-33.06-2.43-69.32c-16.6-36.36-20.9-82.9-20.9-82.9z"
          style={{
            WebkitTransformOrigin: 101.261,
            MsTransformOrigin: 101.261,
            transformOrigin: 101.261,
          }}
          fill="#A86955"
          className="animable"
        ></path>
        <path
          d="M102.16 266.62s3.13 46.5-.13 70.91c-3.44 25.72-13.3 69.57-13.3 69.57l-6.3-.75s-2.09-33.48 5-69.18C74.9 299.23 78 266.36 78 266.36z"
          style={{
            WebkitTransformOrigin: 90.5869,
            MsTransformOrigin: 90.5869,
            transformOrigin: 90.5869,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M102.16 266.62s3.13 46.5-.13 70.91c-3.44 25.72-13.3 69.57-13.3 69.57l-6.3-.75s-2.09-33.48 5-69.18C74.9 299.23 78 266.36 78 266.36z"
          style={{
            WebkitTransformOrigin: 90.5869,
            MsTransformOrigin: 90.5869,
            transformOrigin: 90.5869,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M78.26 320.08A140.91 140.91 0 0098 323.52l.6.05c.42.05.85.08 1.31.1 2.68.14 20.64 2.47 21.88-4.93 0 0-3.86-18.32-7.58-38.65-2.26-12.33-.42-29.67-.42-29.67a135.52 135.52 0 00-23.49-.69l-4.55.26s-15.45 15.25-11.62 30.34c5.72 22.58 4.13 39.75 4.13 39.75z"
          style={{
            WebkitTransformOrigin: 97.6549,
            MsTransformOrigin: 97.6549,
            transformOrigin: 97.6549,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <g
          style={{
            WebkitTransformOrigin: 97.6549,
            MsTransformOrigin: 97.6549,
            transformOrigin: 97.6549,
          }}
          className="animable"
          opacity="0.4"
        >
          <path
            d="M78.26 320.08A140.91 140.91 0 0098 323.52l.6.05c.42.05.85.08 1.31.1 2.68.14 20.64 2.47 21.88-4.93 0 0-3.86-18.32-7.58-38.65-2.26-12.33-.42-29.67-.42-29.67a135.52 135.52 0 00-23.49-.69l-4.55.26s-15.45 15.25-11.62 30.34c5.72 22.58 4.13 39.75 4.13 39.75z"
            style={{
              WebkitTransformOrigin: 97.6549,
              MsTransformOrigin: 97.6549,
              transformOrigin: 97.6549,
            }}
            className="animable"
          ></path>
        </g>
        <path
          d="M90.18 196.83a19.83 19.83 0 003.1 6.53c3.87 5.33 9.58 11.12 12.1 11.75.72-1.67 1.84-4.94 1.84-4.94 1.65.81 2.11 3.14 2.66 4.92 2.65-4.3 4.67-11.7 4-14.39 0 0-3.21-.95-4.79-1.34a96.37 96.37 0 00-13.28-2.13 45.29 45.29 0 00-5.63-.4z"
          style={{
            WebkitTransformOrigin: 102.095,
            MsTransformOrigin: 102.095,
            transformOrigin: 102.095,
          }}
          className="animable"
          opacity="0.1"
        ></path>
        <path
          d="M90.1 196.34l.88 1.6c3.41 6.19 11.31 14.15 14.4 14.94a40.43 40.43 0 001.46-4.57c1.65.81 2.87 3.14 3.42 4.92 2.65-4.3 4.22-10.32 3.55-13-1.56-.47-3.22-.95-4.8-1.35a97.77 97.77 0 00-13.27-2.13 47.6 47.6 0 00-5.64-.41z"
          style={{
            WebkitTransformOrigin: 102.032,
            MsTransformOrigin: 102.032,
            transformOrigin: 102.032,
          }}
          fill="#FFF"
          className="animable"
        ></path>
        <path
          d="M86.48 250l13.53.37h1.13l2.93.07h1.12l7.94.21c.25 0 .47-.12.48-.28l.31-2.54c0-.18-.19-.33-.47-.34l-7.85-.31h-.94l-3.88-.15h-.92l-13-.51a.52.52 0 00-.47.2l-.39 2.86c-.07.19.16.42.48.42z"
          style={{
            WebkitTransformOrigin: 99.9537,
            MsTransformOrigin: 99.9537,
            transformOrigin: 99.9537,
          }}
          fill="#1DD21D"
          className="animable"
        ></path>
        <path
          d="M86.48 250l13.53.37h1.13l2.93.07h1.12l7.94.21c.25 0 .47-.12.48-.28l.31-2.54c0-.18-.19-.33-.47-.34l-7.85-.31h-.94l-3.88-.15h-.92l-13-.51a.52.52 0 00-.47.2l-.39 2.86c-.07.19.16.42.48.42z"
          style={{
            WebkitTransformOrigin: 99.9537,
            MsTransformOrigin: 99.9537,
            transformOrigin: 99.9537,
          }}
          fill="#FFF"
          className="animable"
          opacity="0.2"
        ></path>
        <path
          d="M109.39 250.94h-.87a.25.25 0 01-.29-.22l.48-3.61c0-.11.17-.2.35-.2h.87c.17 0 .3.1.29.21l-.48 3.62a.35.35 0 01-.35.2z"
          style={{
            WebkitTransformOrigin: 109.225,
            MsTransformOrigin: 109.225,
            transformOrigin: 109.225,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M94.31 250.72h-.82a.23.23 0 01-.28-.2l.49-3.63c0-.09.17-.19.33-.18h.82c.17 0 .29.09.28.2l-.49 3.61c-.01.12-.16.2-.33.2z"
          style={{
            WebkitTransformOrigin: 94.1703,
            MsTransformOrigin: 94.1703,
            transformOrigin: 94.1703,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 114.015,
            MsTransformOrigin: 114.015,
            transformOrigin: 114.015,
          }}
          fill="#1DD21D"
          d="M123.02 240.16L102.62 240.16 105 212.88 125.41 212.88 123.02 240.16z"
          className="animable"
        ></path>
        <path
          d="M86.59 235.07a3.64 3.64 0 001.1-3s0-1.66.06-4.18c.08-7.39.14-22.12-.63-24.07-1.68-4.23-4.56-6.52-6.07-4.05-1.77 2.89-1.9 24.77-1.9 24.77.42 14.46 5.72 12.55 7.44 10.53z"
          style={{
            WebkitTransformOrigin: 83.4724,
            MsTransformOrigin: 83.4724,
            transformOrigin: 83.4724,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M114.78 232.87v-.07.1s-3.31 1.94-6.1 1.38l-.86-2.75 3.65-4.84.22-.29.29-.09 3.78-1.08a1.49 1.49 0 011.82 1l.9 2.8a1.49 1.49 0 01-.56 1.66z"
          style={{
            WebkitTransformOrigin: 113.184,
            MsTransformOrigin: 113.184,
            transformOrigin: 113.184,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M82.22 235.87c2.39 2.94 26.81-1.41 27-1.5.8-.36.92-2.45 0-3.21-.5-.42-25.47-2.68-27.2-2.82-2.95 4.54-1.02 6.03.2 7.53z"
          style={{
            WebkitTransformOrigin: 95.1647,
            MsTransformOrigin: 95.1647,
            transformOrigin: 95.1647,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M116.83 168c3.31 4 5 7 3 20.47-.38 2.53.72 8.49 1.2 8.55-13-1.52-16.77-3.66-23-7.44-4.03-2.58-7.44-11.72-6.36-16.45 1.17-5.1 6.73-11 12.1-11.56 6.02-.57 9.29 1.83 13.06 6.43z"
          style={{
            WebkitTransformOrigin: 106.249,
            MsTransformOrigin: 106.249,
            transformOrigin: 106.249,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M107.91 193.29c-1.39 2.75-2.23 5.88 1.14 8.66a4 4 0 011.42 2.3c.28 1.46-.33 3-5.52 1-7.5-2.94-7.68-7.63-7.68-7.63 3.41-1.69 4.31-9.63 4.42-14.1z"
          style={{
            WebkitTransformOrigin: 103.898,
            MsTransformOrigin: 103.898,
            transformOrigin: 103.898,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M103.15 190c-.22 1.94 2.21 4.56 3.87 5.49a13 13 0 01.89-2.15l-3.69-5.79a6.64 6.64 0 00-1.07 2.45z"
          style={{
            isolation: "isolate",
            WebkitTransformOrigin: 105.523,
            MsTransformOrigin: 105.523,
            transformOrigin: 105.523,
          }}
          className="animable"
          opacity="0.2"
        ></path>
        <path
          d="M98.28 172.52a9.64 9.64 0 0112.47-5.52c7 2.66 8.3 13.17 7 19.61-1.45 7.18-10.06 9.49-15.61 4.09-3.71-3.59-3.81-6.62-4.43-13.73a9.78 9.78 0 01.57-4.45z"
          style={{
            WebkitTransformOrigin: 107.927,
            MsTransformOrigin: 107.927,
            transformOrigin: 107.927,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M99.14 184.46a10.26 10.26 0 002.45-8.19s3.24-3.32 3.08-6.87c0 0 1 2.83 0 4.85 0 0 8.41-.61 8.22-4.85 0 0 .72 3.68-.55 4.78 0 0 5.81-.45 5.35-4.42-.71-6.13-5.92-6.45-5.92-6.45s-5.58-4.74-13.3 3.64c0 0-9.08 5.67-2.64 15.13z"
          style={{
            WebkitTransformOrigin: 105.619,
            MsTransformOrigin: 105.619,
            transformOrigin: 105.619,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M113.51 184.07a2.49 2.49 0 002-.44 15.27 15.27 0 01-2.11-3.63z"
          style={{
            WebkitTransformOrigin: 114.455,
            MsTransformOrigin: 114.455,
            transformOrigin: 114.455,
          }}
          fill="#B74D3C"
          className="animable"
        ></path>
        <path
          d="M108.11 184.71a.17.17 0 010-.23.16.16 0 01.23 0 4.56 4.56 0 003.78 1.71.17.17 0 01.17.16.15.15 0 01-.15.17 5.76 5.76 0 01-.83 0 4.85 4.85 0 01-3.2-1.81z"
          style={{
            WebkitTransformOrigin: 110.178,
            MsTransformOrigin: 110.178,
            transformOrigin: 110.178,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M95 181.24a2.64 2.64 0 012.54-2.72c2 0 3.33 2.48 3.62 4.21.29 1.92-.87 3.57-2.77 2.78a6 6 0 01-3.13-3.22 2.75 2.75 0 01-.26-1.05z"
          style={{
            WebkitTransformOrigin: 98.1015,
            MsTransformOrigin: 98.1015,
            transformOrigin: 98.1015,
          }}
          fill="#B97964"
          className="animable"
        ></path>
        <path
          d="M108.67 178c0 .71.34 1.3.81 1.32s.86-.55.87-1.27-.34-1.3-.8-1.32-.86.56-.88 1.27z"
          style={{
            WebkitTransformOrigin: 109.51,
            MsTransformOrigin: 109.51,
            transformOrigin: 109.51,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M115.29 178.76c0 .71.34 1.3.81 1.32s.85-.55.87-1.27-.34-1.3-.8-1.32-.87.51-.88 1.27z"
          style={{
            WebkitTransformOrigin: 116.13,
            MsTransformOrigin: 116.13,
            transformOrigin: 116.13,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M116 177.52l1.73-.44s-.96 1.31-1.73.44z"
          style={{
            WebkitTransformOrigin: 116.865,
            MsTransformOrigin: 116.865,
            transformOrigin: 116.865,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M106.67 175.65a.3.3 0 01-.16-.08.35.35 0 010-.5 4 4 0 013.52-1.28.35.35 0 01.27.41.34.34 0 01-.4.27 3.42 3.42 0 00-2.9 1.09.32.32 0 01-.33.09z"
          style={{
            WebkitTransformOrigin: 108.356,
            MsTransformOrigin: 108.356,
            transformOrigin: 108.356,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M117.83 176.52a.41.41 0 01-.14-.12 3.41 3.41 0 00-2.7-1.52.35.35 0 01-.36-.33.35.35 0 01.34-.36 4.16 4.16 0 013.3 1.82.35.35 0 01-.44.52z"
          style={{
            WebkitTransformOrigin: 116.484,
            MsTransformOrigin: 116.484,
            transformOrigin: 116.484,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M109.34 176.77l1.72-.44s-.91 1.31-1.72.44z"
          style={{
            WebkitTransformOrigin: 110.2,
            MsTransformOrigin: 110.2,
            transformOrigin: 110.2,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M84 197.13a5 5 0 014.76 1.33 9.67 9.67 0 012.42 5.32c.38 3-2.88 13.93-2.8 17-3.22-1.76-8-4.18-12.48-4.62.63-7.17 1.45-11.95 2.85-14.32a13 13 0 012.85-3.75 6.62 6.62 0 012.4-.96z"
          style={{
            WebkitTransformOrigin: 83.5554,
            MsTransformOrigin: 83.5554,
            transformOrigin: 83.5554,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M114.86 407.39c2.28 2.26 3.48 3.43 9.63 4.84 1.36.3.48 3.55-2.75 3.52-5.34-.06-2.42-.28-7-.33h-10.39a1.63 1.63 0 01-.31 0 1.16 1.16 0 01-.86-1.37l1.56-6.94a.65.65 0 01.66-.52l7.49.17a2.66 2.66 0 011.97.63z"
          style={{
            WebkitTransformOrigin: 114.13,
            MsTransformOrigin: 114.13,
            transformOrigin: 114.13,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M91 407.88c1.46 2.86 2.23 4.35 7.64 7.61 1.19.71-.66 3.51-3.72 2.48-5-1.73-2.21-1-6.51-2.48-3.69-1.26-6.93-2.27-9.88-3.21a1 1 0 01-.28-.13 1.15 1.15 0 01-.4-1.57l3.65-6.1a.66.66 0 01.8-.29l7 2.5a2.64 2.64 0 011.7 1.19z"
          style={{
            WebkitTransformOrigin: 88.3476,
            MsTransformOrigin: 88.3476,
            transformOrigin: 88.3476,
          }}
          fill="#263238"
          className="animable"
        ></path>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood
            floodColor="#32DFEC"
            floodOpacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood floodColor="red" floodOpacity="0.5" result="PINK"></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
        </filter>
      </defs>
    </svg>
  );
};

export default HeroSvg;
