import React from "react";
import styles from "./MediaAboutUs.module.css";
import {
  bloomberg,
  yahooFinance,
  yahooNews,
  marketWatch,
  streetInsider,
  advfn,
  globeNewsWire,
  asiaone,
  excite,
  blooger,
  acnNewsWire,
  timeTech,
  etNet,
  seaChroncle,
  indonesiaNewsWire,
  thailandLatestNews,
  pressVietname,
  malaysianBuzz,
  philippinesBusinessNews,
  dePresseNow,
} from "../../images/images";

const MediaAboutUs = () => {
  const media = [
    {
      img: "https://i.ibb.co/k0QVZDY/CT.jpg`",
      websiteUrl:
        "https://cointelegraph.com/press-releases/uns-the-right-project-to-participate-in-this-bull-run",
    },
    // {
    //   img: bloomberg,
    //   websiteUrl:
    //     "https://www.bloomberg.com/press-releases/2022-11-23/uns-digital-technologies-introduces-uns-token-to-get-passive-income-for-users",
    // },
    {
      img: yahooFinance,
      websiteUrl:
        "https://finance.yahoo.com/news/uns-digital-technologies-introduces-uns-044400649.html",
    },
    {
      img: yahooNews,
      websiteUrl:
        "https://news.yahoo.com/uns-digital-technologies-introduces-uns-044400649.html",
    },

    // {
    //   img: marketWatch,
    //   websiteUrl:
    //     "https://www.marketwatch.com/press-release/uns-digital-technologies-introduces-uns-token-to-get-passive-income-for-users-2022-11-22?mod=search_headline",
    // },
    {
      img: streetInsider,
      websiteUrl:
        "https://www.streetinsider.com/Globe+Newswire/UNS+DIGITAL+TECHNOLOGIES+Introduces+UNS+Token+to+Get+Passive+Income+for+Users/20889146.html",
    },
    {
      img: advfn,
      websiteUrl:
        "https://www.advfn.com/stock-market/stock-news/89617738/uns-digital-technologies-introduces-uns-token-to-g",
    },
    {
      img: globeNewsWire,
      websiteUrl:
        "https://www.globenewswire.com/news-release/2022/11/23/2561317/0/en/UNS-DIGITAL-TECHNOLOGIES-Introduces-UNS-Token-to-Get-Passive-Income-for-Users.html",
    },
    {
      img: asiaone,
      websiteUrl:
        "https://www.asiaone.com/business/uns-digital-technologies-introduces-uns-token-get-passive-income-users",
    },
    // {
    //   img: excite,
    //   websiteUrl: "https://www.excite.co.jp/news/article/Jcn_76579391/",
    // },
    {
      img: blooger,
      websiteUrl:
        "https://asiapresswire.blogspot.com/2022/11/uns-digital-technologies-introduces-uns.html",
    },
    {
      img: acnNewsWire,
      websiteUrl:
        "https://www.acnnewswire.com/press-release/english/79390/uns-digital-technologies-introduces-uns-token-to-get-passive-income-for-users",
    },
    // {
    //   img: timeTech,
    //   websiteUrl: "https://timestech.in/acn-newswire/?id=6&amp;artId=79390#",
    // },
    {
      img: etNet,
      websiteUrl:
        "http://www.etnet.com.hk/www/tc/news/acnnewswire_news_detail.php?newsid=79390&amp;page=1&amp;lang=en",
    },
    {
      img: seaChroncle,
      websiteUrl:
        "http://www.seachronicle.com/top-story/uns-digital-technologies-introduces-uns-token-to-get-passive-income-for-users/",
    },
    {
      img: indonesiaNewsWire,
      websiteUrl:
        "https://www.indonewswire.com/top-story/uns-digital-technologies-introduces-uns-token-to-get-passive-income-for-users/",
    },
    {
      img: thailandLatestNews,
      websiteUrl:
        "https://www.thailandlatest.com/top-story/uns-digital-technologies-introduces-uns-token-to-get-passive-income-for-users/",
    },
    {
      img: pressVietname,
      websiteUrl:
        "https://www.pressvn.com/top-story/uns-digital-technologies-introduces-uns-token-to-get-passive-income-for-users/",
    },
    {
      img: malaysianBuzz,
      websiteUrl:
        "https://www.malaysianbuzz.com/top-story/uns-digital-technologies-introduces-uns-token-to-get-passive-income-for-users/",
    },
    // {
    //   img: philippinesBusinessNews,
    //   websiteUrl: "https://www.phbiznews.com/top-story",
    // },
    {
      img: dePresseNow,
      websiteUrl:
        "https://depressenow.com/schlagzeilen/uns-digital-technologies-introduces-uns-token-to-get-passive-income-for-users/",
    },
  ];
  return (
    <section
      id="media"
      className={["sectionPadding", styles.mainWrapper].join(" ")}
    >
      <div className={["appContainer", styles.wrapper].join(" ")}>
        <h3 className={["sectionHeading", styles.heading].join(" ")}>
          <span className="highlight">M </span>edia About Us
        </h3>

        <div className={styles.mediaContainer}>
          {media.map((el, i) => (
            <a
              key={i}
              target="_blank"
              rel="noreferrer"
              href={el.websiteUrl}
              className={styles.media}
            >
              <img src={el.img} alt="" />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MediaAboutUs;
