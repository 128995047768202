import React from "react";
import styles from "./HeroSection.module.css";
import {
  statusBottom,
  statusLeft,
  verchainsLogo,
} from "../../../images/images";
import HeroSvg from "../../../images/AnimatingSvg/Hero";

const HeroSection = () => {
  return (
    <section id="home" className={[styles.wrapper].join(" ")}>
      <div className="appContainer ">
        <div className={styles.hero}>
          <div className={styles.textContainer}>
            <h1 data-aos="fade-up">
              <span className="highlight">UNS</span> Token
              <span className={[styles.block, "highlight"].join(" ")}>
                With Multisector Utility
              </span>
            </h1>

            <div
              className={styles.countCard}
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className={styles.counterLeft}>
                <img src={statusLeft} alt="" />
              </div>
              <div className={styles.innerContent}>
                <a
                  href="https://shop.uns.technology/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h3>
                    Our Shop is <span className="highlight">LIVE</span>
                  </h3>
                </a>
              </div>
              <div className={styles.counterBottom}>
                <img src={statusBottom} alt="" />
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className={styles.rightSide}>
            <div className={styles.coinContainer}>
              <a
                href="https://www.probit.com/app/exchange/UNS-USDT"
                target="_blank"
                rel="noreferrer"
                className={styles.coinMarket}
              >
                <img
                  src="https://static-landing.probit.com/assets/images/vertical-logo.svg"
                  alt=""
                />
                Listed in ProBit
              </a>
              <a
                href="Verichains-doc.pdf"
                target="_blank"
                className={styles.coinMarket}
              >
                <img src={verchainsLogo} alt="" />
                Audited by Verichains
              </a>
            </div>
            <figure className={styles.figure}>
              <HeroSvg />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
