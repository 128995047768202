import React, { useState } from "react";
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import {
  course,
  electrical,
  exoticFruit,
  healthCareImg,
} from "../../../images/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./ImageSlider.module.css";
import "./ImageSlider.css";

const ImageSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderData = [course, electrical, exoticFruit, healthCareImg];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow isActive={!currentSlide} />,
    nextArrow: (
      <CustomNextArrow isActive={currentSlide === sliderData.length - 1} />
    ),
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  return (
    <div id="imageSlider" className="sectionPadding">
      <div className={["appContainer", styles.wrapper].join(" ")}>
        <div className={styles.sliderWrapper}>
          <Slider {...settings}>
            {sliderData.map((image, i) => (
              <div key={i} className={styles.imageContainer}>
                <img src={image} alt="" className={styles.image} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
const CustomNextArrow = ({ isActive, onClick }) => {
  // Custom previous arrow component
  return (
    <button
      className={`${styles.nextButton} ${isActive ? styles.activeButton : ""}`}
      onClick={onClick}
    >
      <BiSolidChevronRight className={styles.arrow} />{" "}
    </button>
  );
};

const CustomPrevArrow = ({ isActive, onClick }) => {
  // Custom next arrow component
  return (
    <button
      onClick={onClick}
      className={`${styles.previousButton} ${
        isActive ? styles.activeButton : ""
      }`}
    >
      <BiSolidChevronLeft className={styles.arrow} />
    </button>
  );
};
