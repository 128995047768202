import React from "react";

const AboutSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="animated"
      version="1.1"
      viewBox="0 0 500 500"
      height="100%"
      width="100%"
    >
      <g
        style={{
          WebkitTransformOrigin: 252,
          MsTransformOrigin: 252,
          transformOrigin: 252,
        }}
        className="animable animator-hidden"
      >
        <ellipse
          cx="252"
          cy="351.12"
          fill="#F0F0F0"
          rx="236.06"
          ry="136.29"
          style={{
            WebkitTransformOrigin: 252,
            MsTransformOrigin: 252,
            transformOrigin: 252,
          }}
          className="animable"
        ></ellipse>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 254.485,
          MsTransformOrigin: 254.485,
          transformOrigin: 254.485,
        }}
        className="animable animator-hidden"
      >
        <path
          d="M301.06 471.88L61.67 333.66a1.32 1.32 0 010-2.5l141.9-81.93a4.79 4.79 0 014.33 0l239.4 138.22a1.32 1.32 0 010 2.5l-141.91 81.93a4.79 4.79 0 01-4.33 0z"
          style={{
            WebkitTransformOrigin: 254.485,
            MsTransformOrigin: 254.485,
            transformOrigin: 254.485,
          }}
          className="animable"
          opacity="0.07"
        ></path>
        <ellipse
          cx="154.91"
          cy="440.83"
          fill="#E0E0E0"
          rx="37.43"
          ry="21.61"
          style={{
            WebkitTransformOrigin: 154.91,
            MsTransformOrigin: 154.91,
            transformOrigin: 154.91,
          }}
          className="animable"
        ></ellipse>
        <ellipse
          cx="386.44"
          cy="325.91"
          fill="#E0E0E0"
          rx="35.66"
          ry="20.59"
          style={{
            WebkitTransformOrigin: 386.44,
            MsTransformOrigin: 386.44,
            transformOrigin: 386.44,
          }}
          className="animable"
        ></ellipse>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 390.115,
          MsTransformOrigin: 390.115,
          transformOrigin: 390.115,
        }}
        className="animable"
      >
        <g
          style={{
            WebkitTransformOrigin: 390.115,
            MsTransformOrigin: 390.115,
            transformOrigin: 390.115,
          }}
          className="animable"
        >
          <path
            d="M350.8 82.61l6 3.47a7.31 7.31 0 01-.05-.8v-1.74c0-3.09 2.17-4.35 4.85-2.8a9.43 9.43 0 012.9 2.78c.43-3.89 3.49-5.34 7.2-3.2A16.08 16.08 0 01379 92.9v2.6a6.86 6.86 0 01-.61 3l5 2.88a5.94 5.94 0 012.7 4.66c0 1.72-1.21 2.41-2.7 1.55L350.8 88.83a6 6 0 01-2.69-4.67c0-1.71 1.2-2.41 2.69-1.55z"
            style={{
              WebkitTransformOrigin: 367.1,
              MsTransformOrigin: 367.1,
              transformOrigin: 367.1,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M390.48 66.67l3.31 1.27v-7.29c0-5.78 4.61-7.17 9.62-4.28 5 2.89 9.06 9.92 9.06 15.7v1.06a4.9 4.9 0 014.21.65 14.42 14.42 0 016.52 11.3v.48l4.62 2.67a9.5 9.5 0 014.3 7.44c0 2.74-1.93 3.85-4.3 2.48l-37.34-21.56a9.48 9.48 0 01-4.3-7.44c0-2.74 1.92-3.85 4.3-2.48z"
            style={{
              WebkitTransformOrigin: 409.15,
              MsTransformOrigin: 409.15,
              transformOrigin: 409.15,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
        </g>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 245.635,
          MsTransformOrigin: 245.635,
          transformOrigin: 245.635,
        }}
        className="animable"
      >
        <g
          style={{
            WebkitTransformOrigin: 421.654,
            MsTransformOrigin: 421.654,
            transformOrigin: 421.654,
          }}
          className="animable"
        >
          <path
            d="M406.53 319.13c2.23-3.07 8.61-6.19 16.81-7.38s16.47-2.82 14.93-7.63c-1.66-5.2-9.15-5.95-16.71-3.44-8.25 2.74-14.75 10.48-15.21 15.6z"
            style={{
              WebkitTransformOrigin: 422.403,
              MsTransformOrigin: 422.403,
              transformOrigin: 422.403,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <g
            style={{
              WebkitTransformOrigin: 422.403,
              MsTransformOrigin: 422.403,
              transformOrigin: 422.403,
            }}
            className="animable"
            opacity="0.1"
          >
            <path
              d="M406.53 319.13c2.23-3.07 8.61-6.19 16.81-7.38s16.47-2.82 14.93-7.63c-1.66-5.2-9.15-5.95-16.71-3.44-8.25 2.74-14.75 10.48-15.21 15.6z"
              style={{
                WebkitTransformOrigin: 422.403,
                MsTransformOrigin: 422.403,
                transformOrigin: 422.403,
              }}
              className="animable"
            ></path>
          </g>
          <path
            d="M406 317.63a.63.63 0 00.57-.31c7.32-12.84 22.34-14 27-12.92a.6.6 0 00.26-1.18c-4.87-1.1-20.63.07-28.29 13.5a.61.61 0 00.48.91z"
            style={{
              WebkitTransformOrigin: 419.882,
              MsTransformOrigin: 419.882,
              transformOrigin: 419.882,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M406.57 317.32s1.84-12 15.21-20.78a93.19 93.19 0 008.69-7.05c5-4.22 3.35-9.28-3.1-9.63-9.58-.52-17.13 7.07-20.88 17.41a31 31 0 00.08 20.05z"
            style={{
              WebkitTransformOrigin: 419.13,
              MsTransformOrigin: 419.13,
              transformOrigin: 419.13,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M405.41 313.09a.6.6 0 00.64-.5c3.13-19.34 18.63-28.11 22.74-28.87a.6.6 0 10-.22-1.18c-5 .92-20.51 10.06-23.71 29.85a.61.61 0 00.5.7z"
            style={{
              WebkitTransformOrigin: 417.066,
              MsTransformOrigin: 417.066,
              transformOrigin: 417.066,
            }}
            fill="#FFF"
            className="animable"
          ></path>
        </g>
        <g
          style={{
            WebkitTransformOrigin: 85.2577,
            MsTransformOrigin: 85.2577,
            transformOrigin: 85.2577,
          }}
          className="animable"
        >
          <path
            d="M106.22 335.19l8.15-2.93s2.4-17.81.18-32.44c-3.31-21.9-21.85-43.11-35.06-47.21-24.36-7.56-24.17 15.46-5.83 28.29 15.94 11.15 30.15 27.84 32.56 54.29z"
            style={{
              WebkitTransformOrigin: 87.9923,
              MsTransformOrigin: 87.9923,
              transformOrigin: 87.9923,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M110.22 334.18h-.1a1 1 0 01-.73-1.14c4.37-20-11.16-57.85-33-69.07a1 1 0 01.88-1.71c22.54 11.57 38.54 50.54 34 71.19a1 1 0 01-1.05.73z"
            style={{
              WebkitTransformOrigin: 94.0584,
              MsTransformOrigin: 94.0584,
              transformOrigin: 94.0584,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M116.89 346.62c.4-2.37.64-5.85.71-6.71a22 22 0 00-1-9c-.44-1.26-1.31-2.49-1.47-3.78s.14-2.44-.19-3.72a9.88 9.88 0 00-4.57-6.14c-2.18-1.25-5-1.81-6.23-4-.82-1.48-.63-3.29-.56-5 .1-2.58-.21-5.36-1.87-7.34-2.14-2.56-5.88-3.06-9.19-2.72s-6.59 1.33-9.91 1.06c-5.61-.46-10.4-4.44-16-5.26a17.39 17.39 0 00-5.93.24c-2.88.6-5.83 2-7.17 4.62a8.15 8.15 0 00.6 7.71c1.55 2.57 4 4.18 6.59 5.59s4.58 2.95 3.27 6.29a9.35 9.35 0 00-.87 3 5.31 5.31 0 002.71 4.54 15.63 15.63 0 005.19 1.69l6.37 1.3c1.85.38 4.13 1.22 4.22 3.11.06 1.17-.81 2.15-1.27 3.23a6 6 0 001.68 6.41 11.53 11.53 0 006.37 2.73c2.33.34 4.69.3 7 .56a27 27 0 0110.27 3.35 8.88 8.88 0 002.55 1.16c1.36.26 8.45-1.42 8.7-2.92z"
            style={{
              WebkitTransformOrigin: 85.2577,
              MsTransformOrigin: 85.2577,
              transformOrigin: 85.2577,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <g
            style={{
              WebkitTransformOrigin: 85.2577,
              MsTransformOrigin: 85.2577,
              transformOrigin: 85.2577,
            }}
            className="animable"
            opacity="0.1"
          >
            <path
              d="M116.89 346.62c.4-2.37.64-5.85.71-6.71a22 22 0 00-1-9c-.44-1.26-1.31-2.49-1.47-3.78s.14-2.44-.19-3.72a9.88 9.88 0 00-4.57-6.14c-2.18-1.25-5-1.81-6.23-4-.82-1.48-.63-3.29-.56-5 .1-2.58-.21-5.36-1.87-7.34-2.14-2.56-5.88-3.06-9.19-2.72s-6.59 1.33-9.91 1.06c-5.61-.46-10.4-4.44-16-5.26a17.39 17.39 0 00-5.93.24c-2.88.6-5.83 2-7.17 4.62a8.15 8.15 0 00.6 7.71c1.55 2.57 4 4.18 6.59 5.59s4.58 2.95 3.27 6.29a9.35 9.35 0 00-.87 3 5.31 5.31 0 002.71 4.54 15.63 15.63 0 005.19 1.69l6.37 1.3c1.85.38 4.13 1.22 4.22 3.11.06 1.17-.81 2.15-1.27 3.23a6 6 0 001.68 6.41 11.53 11.53 0 006.37 2.73c2.33.34 4.69.3 7 .56a27 27 0 0110.27 3.35 8.88 8.88 0 002.55 1.16c1.36.26 8.45-1.42 8.7-2.92z"
              style={{
                WebkitTransformOrigin: 85.2577,
                MsTransformOrigin: 85.2577,
                transformOrigin: 85.2577,
              }}
              className="animable"
            ></path>
          </g>
          <path
            d="M113.05 348.83a.87.87 0 01-.75-.56c-8-21.07-26.94-37.92-53.39-47.43a.88.88 0 01.59-1.65c26.94 9.69 46.28 26.91 54.44 48.46a.88.88 0 01-.51 1.13.85.85 0 01-.38.05z"
            style={{
              WebkitTransformOrigin: 86.1991,
              MsTransformOrigin: 86.1991,
              transformOrigin: 86.1991,
            }}
            fill="#FFF"
            className="animable"
          ></path>
        </g>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 274.448,
          MsTransformOrigin: 274.448,
          transformOrigin: 274.448,
        }}
        className="animable"
      >
        <g
          style={{
            WebkitTransformOrigin: 274.448,
            MsTransformOrigin: 274.448,
            transformOrigin: 274.448,
          }}
          className="animable"
        >
          <path
            d="M112.37 358.35l-3.37-1.93a4.21 4.21 0 01-1.9-3.3V105.86a4.2 4.2 0 011.91-3.3L262.43 14a4.24 4.24 0 013.8 0l3.34 1.93a4.19 4.19 0 011.91 3.3v247.25a4.19 4.19 0 01-1.91 3.3l-153.39 88.57a4.26 4.26 0 01-3.81 0z"
            style={{
              WebkitTransformOrigin: 189.29,
              MsTransformOrigin: 189.29,
              transformOrigin: 189.29,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M112.37 358.35l-3.37-1.93a4.21 4.21 0 01-1.9-3.3V105.86a4.2 4.2 0 011.91-3.3L262.43 14a4.24 4.24 0 013.8 0l3.34 1.93a4.19 4.19 0 011.91 3.3v247.25a4.19 4.19 0 01-1.91 3.3l-153.39 88.57a4.26 4.26 0 01-3.81 0z"
            style={{
              WebkitTransformOrigin: 189.29,
              MsTransformOrigin: 189.29,
              transformOrigin: 189.29,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M271.46 18.91c-.14-1-.94-1.35-1.89-.79l-153.39 88.56a3.85 3.85 0 00-1.35 1.42l-7.15-4.1a4 4 0 011.34-1.41L262.42 14a4.26 4.26 0 013.81 0l3.34 1.93a4.29 4.29 0 011.89 2.98z"
            style={{
              WebkitTransformOrigin: 189.57,
              MsTransformOrigin: 189.57,
              transformOrigin: 189.57,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M271.46 18.91c-.14-1-.94-1.35-1.89-.79l-153.39 88.56a3.85 3.85 0 00-1.35 1.42l-7.15-4.1a4 4 0 011.34-1.41L262.42 14a4.26 4.26 0 013.81 0l3.34 1.93a4.29 4.29 0 011.89 2.98z"
            style={{
              WebkitTransformOrigin: 189.57,
              MsTransformOrigin: 189.57,
              transformOrigin: 189.57,
            }}
            className="animable"
            opacity="0.2"
          ></path>
          <path
            d="M115.84 358.5h-.11l-.2.07h-.08l-.35.09a4.24 4.24 0 01-2.27-.19 2.68 2.68 0 01-.42-.19l-3.41-1.86a4.23 4.23 0 01-1.9-3.3V105.86a4 4 0 01.56-1.89l7.15 4.13a3.85 3.85 0 00-.56 1.88v247.27a1.55 1.55 0 00.29 1 1 1 0 001 .33z"
            style={{
              WebkitTransformOrigin: 111.47,
              MsTransformOrigin: 111.47,
              transformOrigin: 111.47,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M115.84 358.5h-.11l-.2.07h-.08l-.35.09a4.24 4.24 0 01-2.27-.19 2.68 2.68 0 01-.42-.19l-3.41-1.86a4.23 4.23 0 01-1.9-3.3V105.86a4 4 0 01.56-1.89l7.15 4.13a3.85 3.85 0 00-.56 1.88v247.27a1.55 1.55 0 00.29 1 1 1 0 001 .33z"
            style={{
              WebkitTransformOrigin: 111.47,
              MsTransformOrigin: 111.47,
              transformOrigin: 111.47,
            }}
            className="animable"
            opacity="0.4"
          ></path>
          <path
            d="M169.62 95.93a1.53 1.53 0 00-.34-.7l-.13-.11a.73.73 0 00-.71-.1 1.12 1.12 0 00-.26.11 3.07 3.07 0 00-1.08 1.31 4.77 4.77 0 00-.44 1.92 1.86 1.86 0 00.43 1.36.83.83 0 001.09.09 2.07 2.07 0 00.59-.53 3.48 3.48 0 00.4-.62 4.56 4.56 0 00.53-2 2.82 2.82 0 00-.08-.73zm24.94-55.74a3.64 3.64 0 00-1.83-.48 7.06 7.06 0 00-3.44 1.11 15.49 15.49 0 00-6.29 7.61c-.2.45-.39.91-.56 1.37s-.35.92-.5 1.4-.32 1-.46 1.44c-.05.16-.1.32-.14.47-.1.33-.19.64-.28 1a.5.5 0 010 .13L180.5 56c-.08.33-.16.64-.25 1l-.24.95c-.34 1.26-.68 2.47-1.07 3.63-.07.24-.15.47-.24.7s-.23.66-.36 1-.2.49-.3.72c-.16.39-.33.76-.5 1.12s-.23.46-.35.68c-2.68 5.16-6 7.18-7.87 9.84-1.66 2.31-2.14 4.17-4.38 7.83-.7 1.17-1.59 2.52-2.77 4.13-3.23 4.46-4.39 6.67-4.79 8a.36.36 0 000 .11c0 .11-.06.2-.08.3a.29.29 0 000 .09 1.64 1.64 0 010 .25 5.77 5.77 0 000 .79V107l3.57 2.06v-9.93a4.63 4.63 0 01.13-1.53c.39-1.34 1.54-3.54 4.78-8 1.17-1.62 2.06-3 2.78-4.14 2.23-3.66 2.71-5.51 4.37-7.81 1.9-2.66 5.18-4.67 7.87-9.85a27 27 0 001.15-2.51c1.72-4.3 2.49-10 4.61-14.8a15.55 15.55 0 016.34-7.59c2.17-1.25 3.77-1.36 5-.78zm15.66 12.69a4.27 4.27 0 00-.95-.37c-1.35-.32-3.36.46-5.38.23 2.83 3.66 6.77 1.31 9 1.83a3.22 3.22 0 01.95.39zm7.55 13.63a2.1 2.1 0 00-.3.22l-.14.15a1.07 1.07 0 00-.14.16 4.43 4.43 0 00-.94 2.69 1.91 1.91 0 00.42 1.35l.12.11a.74.74 0 00.71.1.77.77 0 00.27-.11 3.05 3.05 0 00.32-.22 4 4 0 00.41-.48 4.55 4.55 0 00.79-2.5c0-1.3-.68-1.98-1.52-1.47zm2.34-9a11.46 11.46 0 00-3.69-1.31c-.54-.12-1-.27-1.47-.4a10.33 10.33 0 005 2.46 14.26 14.26 0 013.3 1.07z"
            style={{
              WebkitTransformOrigin: 190.268,
              MsTransformOrigin: 190.268,
              transformOrigin: 190.268,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M169.62 95.93a1.53 1.53 0 00-.34-.7l-.13-.11a.73.73 0 00-.71-.1 1.12 1.12 0 00-.26.11 3.07 3.07 0 00-1.08 1.31 4.77 4.77 0 00-.44 1.92 1.86 1.86 0 00.43 1.36.83.83 0 001.09.09 2.07 2.07 0 00.59-.53 3.48 3.48 0 00.4-.62 4.56 4.56 0 00.53-2 2.82 2.82 0 00-.08-.73zm24.94-55.74a3.64 3.64 0 00-1.83-.48 7.06 7.06 0 00-3.44 1.11 15.49 15.49 0 00-6.29 7.61c-.2.45-.39.91-.56 1.37s-.35.92-.5 1.4-.32 1-.46 1.44c-.05.16-.1.32-.14.47-.1.33-.19.64-.28 1a.5.5 0 010 .13L180.5 56c-.08.33-.16.64-.25 1l-.24.95c-.34 1.26-.68 2.47-1.07 3.63-.07.24-.15.47-.24.7s-.23.66-.36 1-.2.49-.3.72c-.16.39-.33.76-.5 1.12s-.23.46-.35.68c-2.68 5.16-6 7.18-7.87 9.84-1.66 2.31-2.14 4.17-4.38 7.83-.7 1.17-1.59 2.52-2.77 4.13-3.23 4.46-4.39 6.67-4.79 8a.36.36 0 000 .11c0 .11-.06.2-.08.3a.29.29 0 000 .09 1.64 1.64 0 010 .25 5.77 5.77 0 000 .79V107l3.57 2.06v-9.93a4.63 4.63 0 01.13-1.53c.39-1.34 1.54-3.54 4.78-8 1.17-1.62 2.06-3 2.78-4.14 2.23-3.66 2.71-5.51 4.37-7.81 1.9-2.66 5.18-4.67 7.87-9.85a27 27 0 001.15-2.51c1.72-4.3 2.49-10 4.61-14.8a15.55 15.55 0 016.34-7.59c2.17-1.25 3.77-1.36 5-.78zm15.66 12.69a4.27 4.27 0 00-.95-.37c-1.35-.32-3.36.46-5.38.23 2.83 3.66 6.77 1.31 9 1.83a3.22 3.22 0 01.95.39zm7.55 13.63a2.1 2.1 0 00-.3.22l-.14.15a1.07 1.07 0 00-.14.16 4.43 4.43 0 00-.94 2.69 1.91 1.91 0 00.42 1.35l.12.11a.74.74 0 00.71.1.77.77 0 00.27-.11 3.05 3.05 0 00.32-.22 4 4 0 00.41-.48 4.55 4.55 0 00.79-2.5c0-1.3-.68-1.98-1.52-1.47zm2.34-9a11.46 11.46 0 00-3.69-1.31c-.54-.12-1-.27-1.47-.4a10.33 10.33 0 005 2.46 14.26 14.26 0 013.3 1.07z"
            style={{
              WebkitTransformOrigin: 190.268,
              MsTransformOrigin: 190.268,
              transformOrigin: 190.268,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.8"
          ></path>
          <path
            d="M223.56 59.52l-.28-.17a13.79 13.79 0 00-3.3-1.07 10.29 10.29 0 01-5-2.46 6.43 6.43 0 00-1.16-.86 3.33 3.33 0 00-1-.39c-2.16-.52-6.1 1.84-8.93-1.83a.94.94 0 01-.09-.12c-2-2.66-2.7-8.61-5.67-10.36a2.11 2.11 0 00-.3-.16c-1.22-.58-2.81-.47-5 .78-7.86 4.54-8.12 15.31-11 22.39s-6.84 9.31-9 12.36-2.32 5.3-7.16 12-5 8.29-5 9.55v9.92L225 71.93V62a2.5 2.5 0 00-1.44-2.48zm-55.38 40.29c-.84.49-1.52-.16-1.52-1.46a4 4 0 011.52-3.22c.84-.48 1.51.18 1.51 1.47a4 4 0 01-1.51 3.21zm24.68-29.89a3.25 3.25 0 01-5-1.52 9.15 9.15 0 01-.78-4c0-4.87 2.57-10.3 5.74-12.13a4.69 4.69 0 011.38-.54c2.5-.5 4.34 1.91 4.34 6.06.04 4.87-2.54 10.3-5.68 12.13zm24.92 1.25c-.85.49-1.53-.16-1.53-1.45a4 4 0 011.53-3.21c.83-.48 1.51.17 1.51 1.46a4 4 0 01-1.51 3.2z"
            style={{
              WebkitTransformOrigin: 192.84,
              MsTransformOrigin: 192.84,
              transformOrigin: 192.84,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M223.56 59.52l-.28-.17a13.79 13.79 0 00-3.3-1.07 10.29 10.29 0 01-5-2.46 6.43 6.43 0 00-1.16-.86 3.33 3.33 0 00-1-.39c-2.16-.52-6.1 1.84-8.93-1.83a.94.94 0 01-.09-.12c-2-2.66-2.7-8.61-5.67-10.36a2.11 2.11 0 00-.3-.16c-1.22-.58-2.81-.47-5 .78-7.86 4.54-8.12 15.31-11 22.39s-6.84 9.31-9 12.36-2.32 5.3-7.16 12-5 8.29-5 9.55v9.92L225 71.93V62a2.5 2.5 0 00-1.44-2.48zm-55.38 40.29c-.84.49-1.52-.16-1.52-1.46a4 4 0 011.52-3.22c.84-.48 1.51.18 1.51 1.47a4 4 0 01-1.51 3.21zm24.68-29.89a3.25 3.25 0 01-5-1.52 9.15 9.15 0 01-.78-4c0-4.87 2.57-10.3 5.74-12.13a4.69 4.69 0 011.38-.54c2.5-.5 4.34 1.91 4.34 6.06.04 4.87-2.54 10.3-5.68 12.13zm24.92 1.25c-.85.49-1.53-.16-1.53-1.45a4 4 0 011.53-3.21c.83-.48 1.51.17 1.51 1.46a4 4 0 01-1.51 3.2z"
            style={{
              WebkitTransformOrigin: 192.84,
              MsTransformOrigin: 192.84,
              transformOrigin: 192.84,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.4"
          ></path>
          <path
            d="M161 97.6a4.63 4.63 0 00-.23 1.53v9.93l-3.62-2.06v-9.92a5.77 5.77 0 010-.79 1.64 1.64 0 000-.25.29.29 0 010-.09c0-.1.05-.19.08-.3v-.11z"
            style={{
              WebkitTransformOrigin: 159.068,
              MsTransformOrigin: 159.068,
              transformOrigin: 159.068,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M172.88 77.65c-1.66 2.3-2.14 4.15-4.37 7.81l-3.57-2.06c2.24-3.66 2.72-5.52 4.38-7.83z"
            style={{
              WebkitTransformOrigin: 168.91,
              MsTransformOrigin: 168.91,
              transformOrigin: 168.91,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M186.52 50.47c-2.12 4.81-2.89 10.5-4.61 14.8a27 27 0 01-1.15 2.51l-3.57-2c.12-.22.23-.45.35-.68s.34-.73.5-1.12c.1-.23.21-.47.3-.72s.25-.64.36-1 .17-.46.24-.7c.39-1.16.73-2.37 1.07-3.63l.24-.95c.09-.32.17-.63.25-1l.48-1.79a.5.5 0 000-.13c.09-.33.18-.64.28-1 0-.15.09-.31.14-.47.14-.48.3-1 .46-1.44s.32-.94.5-1.4.36-.92.56-1.37z"
            style={{
              WebkitTransformOrigin: 181.855,
              MsTransformOrigin: 181.855,
              transformOrigin: 181.855,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M161 97.6a4.63 4.63 0 00-.23 1.53v9.93l-3.62-2.06v-9.92a5.77 5.77 0 010-.79 1.64 1.64 0 000-.25.29.29 0 010-.09c0-.1.05-.19.08-.3v-.11z"
            style={{
              WebkitTransformOrigin: 159.068,
              MsTransformOrigin: 159.068,
              transformOrigin: 159.068,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.65"
          ></path>
          <path
            d="M172.88 77.65c-1.66 2.3-2.14 4.15-4.37 7.81l-3.57-2.06c2.24-3.66 2.72-5.52 4.38-7.83z"
            style={{
              WebkitTransformOrigin: 168.91,
              MsTransformOrigin: 168.91,
              transformOrigin: 168.91,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.65"
          ></path>
          <path
            d="M186.52 50.47c-2.12 4.81-2.89 10.5-4.61 14.8a27 27 0 01-1.15 2.51l-3.57-2c.12-.22.23-.45.35-.68s.34-.73.5-1.12c.1-.23.21-.47.3-.72s.25-.64.36-1 .17-.46.24-.7c.39-1.16.73-2.37 1.07-3.63l.24-.95c.09-.32.17-.63.25-1l.48-1.79a.5.5 0 000-.13c.09-.33.18-.64.28-1 0-.15.09-.31.14-.47.14-.48.3-1 .46-1.44s.32-.94.5-1.4.36-.92.56-1.37z"
            style={{
              WebkitTransformOrigin: 181.855,
              MsTransformOrigin: 181.855,
              transformOrigin: 181.855,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.65"
          ></path>
          <path
            d="M167.1 96.44a4.77 4.77 0 00-.44 1.92 1.86 1.86 0 00.43 1.36 1.92 1.92 0 01-.43-1.36 4.94 4.94 0 01.44-1.92zm2.6.17a2.82 2.82 0 00-.08-.68 3.47 3.47 0 01.07.68 4.55 4.55 0 01-.52 2 4.56 4.56 0 00.53-2zm47.09-25.42a.74.74 0 00.71.1.8.8 0 01-.71-.1z"
            style={{
              WebkitTransformOrigin: 192.076,
              MsTransformOrigin: 192.076,
              transformOrigin: 192.076,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M198.59 57.79c0 4.87-2.57 10.3-5.73 12.13a3.25 3.25 0 01-5-1.51 4.52 4.52 0 001.39-.55C192.46 66 195 60.6 195 55.72a9.13 9.13 0 00-.78-4c2.51-.49 4.37 1.92 4.37 6.07z"
            style={{
              WebkitTransformOrigin: 193.225,
              MsTransformOrigin: 193.225,
              transformOrigin: 193.225,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M198.59 57.79c0 4.87-2.57 10.3-5.73 12.13a3.25 3.25 0 01-5-1.51 4.52 4.52 0 001.39-.55C192.46 66 195 60.6 195 55.72a9.13 9.13 0 00-.78-4c2.51-.49 4.37 1.92 4.37 6.07z"
            style={{
              WebkitTransformOrigin: 193.225,
              MsTransformOrigin: 193.225,
              transformOrigin: 193.225,
            }}
            className="animable"
            opacity="0.1"
          ></path>
          <path
            d="M169.17 98.66a3.48 3.48 0 01-.4.62 2.07 2.07 0 01-.59.53.83.83 0 01-1.09-.09 1.86 1.86 0 01-.43-1.36 4.77 4.77 0 01.44-1.92 3.07 3.07 0 011.08-1.31 1.12 1.12 0 01.26-.11.79.79 0 01.84.21 1.53 1.53 0 01.34.7 2.82 2.82 0 01.08.68 4.59 4.59 0 01-.53 2.05z"
            style={{
              WebkitTransformOrigin: 168.176,
              MsTransformOrigin: 168.176,
              transformOrigin: 168.176,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M219.29 68a4.55 4.55 0 01-.79 2.5 4 4 0 01-.41.48 3.05 3.05 0 01-.32.22.77.77 0 01-.27.11.75.75 0 01-.83-.21 1.91 1.91 0 01-.42-1.35 4.43 4.43 0 01.94-2.69 1.07 1.07 0 01.14-.16l.14-.15a2.1 2.1 0 01.3-.22c.84-.53 1.52.15 1.52 1.47z"
            style={{
              WebkitTransformOrigin: 217.767,
              MsTransformOrigin: 217.767,
              transformOrigin: 217.767,
            }}
            fill="#37474F"
            className="animable"
          ></path>
          <path
            d="M264.29 55.13c-.14-.47-.58-.62-1.1-.32L129.7 131.88a2.37 2.37 0 00-.81.85l-3.57-2.06a2.33 2.33 0 01.17-.26 2.2 2.2 0 01.2-.23 2 2 0 01.44-.36l133.49-77.07a1.66 1.66 0 01.33-.15 2.58 2.58 0 011.69 0 1.15 1.15 0 01.25.12l1.3.74a1.33 1.33 0 01.19.14.7.7 0 01.16.14 2.23 2.23 0 01.21.22 1.43 1.43 0 01.14.2 1.64 1.64 0 01.14.22 2.55 2.55 0 01.2.48 2.1 2.1 0 01.06.27z"
            style={{
              WebkitTransformOrigin: 194.805,
              MsTransformOrigin: 194.805,
              transformOrigin: 194.805,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M441.79 383l-92.35-49.35-12.58-8.15a147.29 147.29 0 01-28.29-23.89 167.65 167.65 0 01-44.24-113.47v-49l-4.07-2.34-131.7-2.91a2.44 2.44 0 01.33-1.13l-3.57-2.06a2.44 2.44 0 00-.14.28 2.18 2.18 0 00-.19.85v83.33h-.06v49c0 2.33 0 4.67.07 7v.08a138.25 138.25 0 001.37 17.88q.89 5.91 2.2 11.72a167.73 167.73 0 0040.17 76.81A147 147 0 00197 401.56l12.58 8.15 91.46 52.8h.05l4 2.32a4.79 4.79 0 004.33 0L440.9 389a1.5 1.5 0 00.89-1.25z"
            style={{
              WebkitTransformOrigin: 283.36,
              MsTransformOrigin: 283.36,
              transformOrigin: 283.36,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M441.79 383v4.7a1.49 1.49 0 01-.89 1.25l-131.44 75.9a4.41 4.41 0 01-2.17.52v-4.7a4.38 4.38 0 002.17-.52l131.44-75.88a1.52 1.52 0 00.89-1.27z"
            style={{
              WebkitTransformOrigin: 374.54,
              MsTransformOrigin: 374.54,
              transformOrigin: 374.54,
            }}
            className="animable"
            opacity="0.1"
          ></path>
          <path
            d="M440.9 381.77L349.44 329l-12.58-8.15a147.29 147.29 0 01-28.29-23.89 167.65 167.65 0 01-44.24-113.47v-128a1.37 1.37 0 000-.34c-.14-.47-.58-.62-1.1-.32L129.7 131.88a2.37 2.37 0 00-.81.85 2.44 2.44 0 00-.33 1.13v128a167.73 167.73 0 0044.24 113.45 147 147 0 0028.29 23.89l12.58 8.15 91.46 52.8a4.79 4.79 0 004.33 0l131.44-75.88a1.32 1.32 0 000-2.5z"
            style={{
              WebkitTransformOrigin: 285.178,
              MsTransformOrigin: 285.178,
              transformOrigin: 285.178,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M182.17 83l-.19.29a6.85 6.85 0 00-.9 2.26v.12a.18.18 0 000 .05L178.88 98l-.69 3.92a3.89 3.89 0 01-.12.49 2.79 2.79 0 01-.14.47 3 3 0 00-.11.32l3.56 2.06a6.39 6.39 0 00.38-1.27l.69-3.92 2.16-12.26a6.71 6.71 0 011.12-2.74z"
            style={{
              WebkitTransformOrigin: 181.775,
              MsTransformOrigin: 181.775,
              transformOrigin: 181.775,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M157.59 122.59c-.36.56-.71 1.14-1 1.71-.16.28-.3.56-.43.83s-.35.72-.5 1.08-.34.8-.49 1.2-.3.8-.43 1.21-.31.94-.44 1.42c-.09.31-.17.62-.26 1-.2.75-.37 1.52-.53 2.29-.06.27-.12.55-.17.81s-.13.65-.19 1v.05c0 .26 0 .43.13.5a.14.14 0 00.11 0l3-2.41.5-.4.24-.18.27-.21.21-.16a1.16 1.16 0 01.18-.14 27.29 27.29 0 014.41-9l-3.52-2.19c-.39.56-.76 1.07-1.09 1.59z"
            style={{
              WebkitTransformOrigin: 157.675,
              MsTransformOrigin: 157.675,
              transformOrigin: 157.675,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M196.2 56.07c-.16.27-.31.54-.45.82a3.2 3.2 0 00-.26.48c-.13.23-.24.46-.36.69s-.19.37-.27.56c-.18.37-.34.73-.49 1.09s-.34.79-.49 1.18c-.14.35-.27.71-.38 1.06-.19.53-.35 1.07-.5 1.61-.08.28-.15.56-.22.84v.06l-.09.43c-.08.34-.15.67-.2 1s-.11.53-.18.79-.14.55-.23.83-.17.52-.25.79v.07c-.11.28-.22.58-.35.88s-.24.59-.38.89a2.08 2.08 0 00-.1.24l-.33.68c-.18.37-.37.74-.56 1.1s-.26.49-.4.73l3.52 2.11a24.05 24.05 0 002.77-7 30.78 30.78 0 013.76-9.86z"
            style={{
              WebkitTransformOrigin: 194.735,
              MsTransformOrigin: 194.735,
              transformOrigin: 194.735,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M217.44 64a1.29 1.29 0 00-.58-.2 4.36 4.36 0 003.19 2 2.22 2.22 0 01.89.19z"
            style={{
              WebkitTransformOrigin: 218.9,
              MsTransformOrigin: 218.9,
              transformOrigin: 218.9,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M217.44 64a1.29 1.29 0 00-.58-.2 4.36 4.36 0 003.19 2 2.22 2.22 0 01.89.19z"
            style={{
              WebkitTransformOrigin: 218.9,
              MsTransformOrigin: 218.9,
              transformOrigin: 218.9,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.8"
          ></path>
          <path
            d="M230.71 79.53a3.87 3.87 0 00-3.58.1c-4.15 1.84-5.95 2.66-8.09 3.56-.42 2.75 1.21 3 2 2.69 3-1.2 4.55-1.93 9.65-4.17a3.85 3.85 0 013.55-.12z"
            style={{
              WebkitTransformOrigin: 226.606,
              MsTransformOrigin: 226.606,
              transformOrigin: 226.606,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M230.71 79.53a3.87 3.87 0 00-3.58.1c-4.15 1.84-5.95 2.66-8.09 3.56-.42 2.75 1.21 3 2 2.69 3-1.2 4.55-1.93 9.65-4.17a3.85 3.85 0 013.55-.12z"
            style={{
              WebkitTransformOrigin: 226.606,
              MsTransformOrigin: 226.606,
              transformOrigin: 226.606,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.8"
          ></path>
          <path
            d="M181.38 105.28a14.62 14.62 0 01-3.14 4.49l-11.19 8.39a30 30 0 00-4.8 5l-3.57-2.16a29.49 29.49 0 014.8-4.94l11.2-8.4a14.85 14.85 0 003.14-4.48z"
            style={{
              WebkitTransformOrigin: 170.03,
              MsTransformOrigin: 170.03,
              transformOrigin: 170.03,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M181.38 105.28a14.62 14.62 0 01-3.14 4.49l-11.19 8.39a30 30 0 00-4.8 5l-3.57-2.16a29.49 29.49 0 014.8-4.94l11.2-8.4a14.85 14.85 0 003.14-4.48z"
            style={{
              WebkitTransformOrigin: 170.03,
              MsTransformOrigin: 170.03,
              transformOrigin: 170.03,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.8"
          ></path>
          <path
            d="M193.23 75a37.77 37.77 0 01-5.64 7.87 13.34 13.34 0 00-1.86 2.24l-3.56-2a12.35 12.35 0 011.83-2.33 37.57 37.57 0 005.64-7.86z"
            style={{
              WebkitTransformOrigin: 187.7,
              MsTransformOrigin: 187.7,
              transformOrigin: 187.7,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M193.23 75a37.77 37.77 0 01-5.64 7.87 13.34 13.34 0 00-1.86 2.24l-3.56-2a12.35 12.35 0 011.83-2.33 37.57 37.57 0 005.64-7.86z"
            style={{
              WebkitTransformOrigin: 187.7,
              MsTransformOrigin: 187.7,
              transformOrigin: 187.7,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.8"
          ></path>
          <path
            d="M213.4 48.61c-1.32-.69-3.07-.57-5.14.51-3.06 1.6-6.14 5-8.48 9l-3.58-2.06c2.35-4 5.43-7.41 8.5-9a7.14 7.14 0 013.27-.93 4.06 4.06 0 012 .5z"
            style={{
              WebkitTransformOrigin: 204.8,
              MsTransformOrigin: 204.8,
              transformOrigin: 204.8,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M213.4 48.61c-1.32-.69-3.07-.57-5.14.51-3.06 1.6-6.14 5-8.48 9l-3.58-2.06c2.35-4 5.43-7.41 8.5-9a7.14 7.14 0 013.27-.93 4.06 4.06 0 012 .5z"
            style={{
              WebkitTransformOrigin: 204.8,
              MsTransformOrigin: 204.8,
              transformOrigin: 204.8,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.8"
          ></path>
          <path
            d="M162.25 123.11a27.29 27.29 0 00-4.41 9 1.16 1.16 0 00-.18.14l-.21.16-.27.21-.24.18-.5.4-3 2.41a.14.14 0 01-.11 0c-.12-.07-.17-.24-.13-.5v-.05c.06-.33.13-.65.19-1s.11-.54.17-.81c.16-.77.33-1.54.53-2.29.09-.33.17-.64.26-1 .13-.48.28-.95.44-1.42s.28-.81.43-1.21.32-.8.49-1.2.32-.72.5-1.08.27-.55.43-.83c.3-.57.65-1.15 1-1.71s.7-1 1.09-1.55z"
            style={{
              WebkitTransformOrigin: 157.718,
              MsTransformOrigin: 157.718,
              transformOrigin: 157.718,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.65"
          ></path>
          <path
            d="M185.73 85.09a6.71 6.71 0 00-1.12 2.74l-2.16 12.26-.69 3.92a6.39 6.39 0 01-.38 1.27l-3.56-2.06a3 3 0 01.11-.32 2.79 2.79 0 00.14-.47 3.89 3.89 0 00.12-.49l.69-3.92 2.17-12.26a.18.18 0 010-.05v-.12a6.85 6.85 0 01.9-2.26l.19-.29z"
            style={{
              WebkitTransformOrigin: 181.775,
              MsTransformOrigin: 181.775,
              transformOrigin: 181.775,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.65"
          ></path>
          <path
            d="M199.78 58.13A30.78 30.78 0 00196 68a24.05 24.05 0 01-2.79 7l-3.58-2.06c.14-.24.27-.48.4-.73s.38-.73.56-1.1l.33-.68a2.08 2.08 0 01.1-.24c.13-.29.26-.6.38-.89s.24-.6.35-.88v-.07c.08-.27.18-.53.25-.79s.17-.56.23-.83.13-.53.18-.79.12-.66.2-1l.09-.43v-.06c.07-.28.14-.56.22-.84.15-.54.31-1.08.5-1.61.11-.35.24-.71.38-1.06.15-.39.32-.79.49-1.18s.31-.72.49-1.09c.08-.19.18-.37.27-.56s.23-.46.36-.69a3.2 3.2 0 01.26-.48c.14-.28.29-.55.45-.82z"
            style={{
              WebkitTransformOrigin: 194.705,
              MsTransformOrigin: 194.705,
              transformOrigin: 194.705,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.65"
          ></path>
          <path
            d="M153.38 135.62l3-2.41c-1.18.95-2.83 2.24-3 2.41z"
            style={{
              WebkitTransformOrigin: 154.88,
              MsTransformOrigin: 154.88,
              transformOrigin: 154.88,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M234.31 81.62h-.05a3.9 3.9 0 00-3.56.11c-5.09 2.25-6.63 3-9.64 4.18-.81.33-2.44.06-2-2.69a1.48 1.48 0 010-.19l.69-3.92 1.93-10.68s.44-1.68-.68-2.32h-.08a2 2 0 00-.89-.2 4.32 4.32 0 01-3.18-2 8.06 8.06 0 01-1.2-6.11c.81-4.58-.08-7.79-2.11-9l-.16-.1c-1.31-.69-3.07-.58-5.12.5C202.84 52 197.36 60.41 196 68c-.84 4.79-5.65 12.15-8.43 14.86s-3 5-3 5l-2.16 12.27-.69 3.92c-.51 2.93-3.52 5.76-3.52 5.76l-11.19 8.39a27 27 0 00-9.21 13.94s-4.2 3.26-4.46 3.52l1.43-.82 77.26-44.41 1.08-.6c.19-.12 3.3-3.06 3.3-3.06-.07-2.58-.81-4.38-2.1-5.15zM212 59.08c-.83 4.66-4.19 9.86-7.53 11.61-2.31 1.21-4 .46-4.55-1.66a8.89 8.89 0 010-3.62c.83-4.66 4.2-9.86 7.54-11.6a5.61 5.61 0 011-.41c2.7-.84 4.27 1.49 3.54 5.68z"
            style={{
              WebkitTransformOrigin: 194.875,
              MsTransformOrigin: 194.875,
              transformOrigin: 194.875,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M234.31 81.62h-.05a3.9 3.9 0 00-3.56.11c-5.09 2.25-6.63 3-9.64 4.18-.81.33-2.44.06-2-2.69a1.48 1.48 0 010-.19l.69-3.92 1.93-10.68s.44-1.68-.68-2.32h-.08a2 2 0 00-.89-.2 4.32 4.32 0 01-3.18-2 8.06 8.06 0 01-1.2-6.11c.81-4.58-.08-7.79-2.11-9l-.16-.1c-1.31-.69-3.07-.58-5.12.5C202.84 52 197.36 60.41 196 68c-.84 4.79-5.65 12.15-8.43 14.86s-3 5-3 5l-2.16 12.27-.69 3.92c-.51 2.93-3.52 5.76-3.52 5.76l-11.19 8.39a27 27 0 00-9.21 13.94s-4.2 3.26-4.46 3.52l1.43-.82 77.26-44.41 1.08-.6c.19-.12 3.3-3.06 3.3-3.06-.07-2.58-.81-4.38-2.1-5.15zM212 59.08c-.83 4.66-4.19 9.86-7.53 11.61-2.31 1.21-4 .46-4.55-1.66a8.89 8.89 0 010-3.62c.83-4.66 4.2-9.86 7.54-11.6a5.61 5.61 0 011-.41c2.7-.84 4.27 1.49 3.54 5.68z"
            style={{
              WebkitTransformOrigin: 194.875,
              MsTransformOrigin: 194.875,
              transformOrigin: 194.875,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.55"
          ></path>
          <path
            d="M153.39 135.63l78.69-45.24 1.09-.6c.19-.12 3.3-3.07 3.3-3.07l-78.63 45.38s-4.76 3.69-4.45 3.53z"
            style={{
              WebkitTransformOrigin: 194.923,
              MsTransformOrigin: 194.923,
              transformOrigin: 194.923,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M208.41 53.4a5.61 5.61 0 00-1 .41c-3.34 1.74-6.71 6.94-7.54 11.6a8.89 8.89 0 000 3.62c.56 2.12 2.24 2.87 4.55 1.66 3.34-1.75 6.7-6.95 7.53-11.61.78-4.19-.79-6.52-3.54-5.68zm-8.3 15.54a8.69 8.69 0 010-3.49c.81-4.6 4.15-9.74 7.42-11.44a5.65 5.65 0 01.91-.39 9 9 0 010 3.39c-.82 4.67-4.19 9.86-7.53 11.61a6.74 6.74 0 01-.76.33z"
            style={{
              WebkitTransformOrigin: 205.917,
              MsTransformOrigin: 205.917,
              transformOrigin: 205.917,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M208.41 53.4a5.61 5.61 0 00-1 .41c-3.34 1.74-6.71 6.94-7.54 11.6a8.89 8.89 0 000 3.62c.56 2.12 2.24 2.87 4.55 1.66 3.34-1.75 6.7-6.95 7.53-11.61.78-4.19-.79-6.52-3.54-5.68zm-8.3 15.54a8.69 8.69 0 010-3.49c.81-4.6 4.15-9.74 7.42-11.44a5.65 5.65 0 01.91-.39 9 9 0 010 3.39c-.82 4.67-4.19 9.86-7.53 11.61a6.74 6.74 0 01-.76.33z"
            style={{
              WebkitTransformOrigin: 205.917,
              MsTransformOrigin: 205.917,
              transformOrigin: 205.917,
            }}
            className="animable"
            opacity="0.1"
          ></path>
          <path
            d="M136.71 179.08v50.81c0 .91.63 1.28 1.42.82l75.56-43.52a3.16 3.16 0 001.43-2.47v-50.81c0-.92-.64-1.28-1.43-.83l-75.56 43.52a3.16 3.16 0 00-1.42 2.48z"
            style={{
              WebkitTransformOrigin: 175.915,
              MsTransformOrigin: 175.915,
              transformOrigin: 175.915,
            }}
            fill="#1DD21D"
            className="animable"
            opacity="0.1"
          ></path>
          <path
            d="M140 225.85s6.83-9 8.82-12.82c2.95-5.71 2.27-16.76 7.92-20s4.76 11.34 12.07 7.16 7.3-9.13 11-11.55 6.93 2.42 11.29 0c5-2.78 7.29-19.79 12.45-22.62 5.78-3.17 8.07 12.32 8.07 12.32v6.19z"
            style={{
              WebkitTransformOrigin: 175.81,
              MsTransformOrigin: 175.81,
              transformOrigin: 175.81,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M140 225.85s3.21-17.3 6.7-19.33c5-2.9 3.24 10 10 5.8 5.55-3.39 4.52-18.11 11.72-22.16 5.41-3 5.06 8.53 11.71 4.77s5-22.33 11.71-26.08 5.78 16.13 10.92 13c6.07-3.65 4-8.56 8.83-12.85v15.5z"
            style={{
              WebkitTransformOrigin: 175.795,
              MsTransformOrigin: 175.795,
              transformOrigin: 175.795,
            }}
            fill="#1DD21D"
            className="animable"
            opacity="0.6"
          ></path>
          <path
            d="M140 225.85s3.21-17.3 6.7-19.33c5-2.9 3.24 10 10 5.8 5.55-3.39 4.52-18.11 11.72-22.16 5.41-3 5.06 8.53 11.71 4.77s5-22.33 11.71-26.08 5.78 16.13 10.92 13c6.07-3.65 4-8.56 8.83-12.85v15.5z"
            style={{
              WebkitTransformOrigin: 175.795,
              MsTransformOrigin: 175.795,
              transformOrigin: 175.795,
            }}
            className="animable"
            opacity="0.25"
          ></path>
          <path
            d="M143.39 181.94c0 1.07.75 1.5 1.67 1a3.69 3.69 0 001.67-2.9c0-1.07-.74-1.5-1.67-1a3.71 3.71 0 00-1.67 2.9z"
            style={{
              WebkitTransformOrigin: 145.06,
              MsTransformOrigin: 145.06,
              transformOrigin: 145.06,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M143.39 181.94c0 1.07.75 1.5 1.67 1a3.69 3.69 0 001.67-2.9c0-1.07-.74-1.5-1.67-1a3.71 3.71 0 00-1.67 2.9z"
            style={{
              WebkitTransformOrigin: 145.06,
              MsTransformOrigin: 145.06,
              transformOrigin: 145.06,
            }}
            className="animable"
            opacity="0.25"
          ></path>
          <path
            d="M151 176.8l28.39-16.39c.48-.27.87-.07.87.47a1.87 1.87 0 01-.87 1.46L151 178.74c-.48.27-.87.06-.87-.47a1.9 1.9 0 01.87-1.47z"
            style={{
              WebkitTransformOrigin: 165.195,
              MsTransformOrigin: 165.195,
              transformOrigin: 165.195,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M143.39 189.67c0 1.07.75 1.5 1.67 1a3.69 3.69 0 001.67-2.9c0-1.07-.74-1.5-1.67-1a3.71 3.71 0 00-1.67 2.9z"
            style={{
              WebkitTransformOrigin: 145.06,
              MsTransformOrigin: 145.06,
              transformOrigin: 145.06,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M151 184.53l28.39-16.39c.48-.27.87-.07.87.47a1.87 1.87 0 01-.87 1.46L151 186.47c-.48.27-.87.06-.87-.47a1.9 1.9 0 01.87-1.47z"
            style={{
              WebkitTransformOrigin: 165.195,
              MsTransformOrigin: 165.195,
              transformOrigin: 165.195,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M136.69 239.07v33.6c0 .6.43.85.95.55L177.81 250a2.08 2.08 0 00.94-1.63v-33.59c0-.6-.42-.84-.94-.54l-40.17 23.19a2.09 2.09 0 00-.95 1.64z"
            style={{
              WebkitTransformOrigin: 157.72,
              MsTransformOrigin: 157.72,
              transformOrigin: 157.72,
            }}
            fill="#1DD21D"
            className="animable"
            opacity="0.1"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 141.885,
              MsTransformOrigin: 141.885,
              transformOrigin: 141.885,
            }}
            fill="#1DD21D"
            d="M141.12 266.1L141.12 240.54 142.65 239.66 142.65 265.22 141.12 266.1z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 141.885,
              MsTransformOrigin: 141.885,
              transformOrigin: 141.885,
            }}
            fill="#455A64"
            d="M140.78 268.07L142.99 266.79 142.99 267.58 140.78 268.85 140.78 268.07z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 144.785,
              MsTransformOrigin: 144.785,
              transformOrigin: 144.785,
            }}
            fill="#1DD21D"
            d="M144.02 264.42L144.02 243.97 145.55 243.09 145.55 263.54 144.02 264.42z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 144.785,
              MsTransformOrigin: 144.785,
              transformOrigin: 144.785,
            }}
            fill="#455A64"
            d="M143.68 266.39L145.89 265.12 145.89 265.9 143.68 267.18 143.68 266.39z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 149.215,
              MsTransformOrigin: 149.215,
              transformOrigin: 149.215,
            }}
            fill="#1DD21D"
            d="M148.45 261.87L148.45 239.65 149.98 238.78 149.98 260.99 148.45 261.87z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 149.22,
              MsTransformOrigin: 149.22,
              transformOrigin: 149.22,
            }}
            fill="#455A64"
            d="M148.11 263.83L150.33 262.55 150.33 263.33 148.11 264.61 148.11 263.83z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 152.115,
              MsTransformOrigin: 152.115,
              transformOrigin: 152.115,
            }}
            fill="#1DD21D"
            d="M151.35 260.19L151.35 241.81 152.88 240.93 152.88 259.31 151.35 260.19z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 152.125,
              MsTransformOrigin: 152.125,
              transformOrigin: 152.125,
            }}
            fill="#455A64"
            d="M151.02 262.15L153.23 260.87 153.23 261.66 151.02 262.94 151.02 262.15z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 156.545,
              MsTransformOrigin: 156.545,
              transformOrigin: 156.545,
            }}
            fill="#1DD21D"
            d="M155.78 257.64L155.78 247.39 157.31 246.51 157.31 256.76 155.78 257.64z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 156.54,
              MsTransformOrigin: 156.54,
              transformOrigin: 156.54,
            }}
            fill="#455A64"
            d="M155.43 259.61L157.65 258.33 157.65 259.11 155.43 260.39 155.43 259.61z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 159.45,
              MsTransformOrigin: 159.45,
              transformOrigin: 159.45,
            }}
            fill="#1DD21D"
            d="M158.69 255.96L158.69 244.33 160.21 243.45 160.21 255.08 158.69 255.96z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 159.445,
              MsTransformOrigin: 159.445,
              transformOrigin: 159.445,
            }}
            fill="#455A64"
            d="M158.34 257.93L160.55 256.65 160.55 257.43 158.34 258.71 158.34 257.93z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 163.875,
              MsTransformOrigin: 163.875,
              transformOrigin: 163.875,
            }}
            fill="#1DD21D"
            d="M163.11 253.4L163.11 239.38 164.64 238.5 164.64 252.52 163.11 253.4z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 163.895,
              MsTransformOrigin: 163.895,
              transformOrigin: 163.895,
            }}
            fill="#455A64"
            d="M162.79 255.37L165 254.09 165 254.87 162.79 256.15 162.79 255.37z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 166.78,
              MsTransformOrigin: 166.78,
              transformOrigin: 166.78,
            }}
            fill="#1DD21D"
            d="M166.02 251.72L166.02 242.6 167.54 241.72 167.54 250.85 166.02 251.72z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 166.8,
              MsTransformOrigin: 166.8,
              transformOrigin: 166.8,
            }}
            fill="#455A64"
            d="M165.69 253.69L167.91 252.41 167.91 253.19 165.69 254.47 165.69 253.69z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 171.205,
              MsTransformOrigin: 171.205,
              transformOrigin: 171.205,
            }}
            fill="#1DD21D"
            d="M170.44 249.17L170.44 245.18 171.97 244.31 171.97 248.29 170.44 249.17z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 141.885,
              MsTransformOrigin: 141.885,
              transformOrigin: 141.885,
            }}
            fill="#1DD21D"
            d="M141.12 266.1L141.12 240.54 142.65 239.66 142.65 265.22 141.12 266.1z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 149.215,
              MsTransformOrigin: 149.215,
              transformOrigin: 149.215,
            }}
            fill="#1DD21D"
            d="M148.45 261.87L148.45 239.65 149.98 238.78 149.98 260.99 148.45 261.87z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 156.545,
              MsTransformOrigin: 156.545,
              transformOrigin: 156.545,
            }}
            fill="#1DD21D"
            d="M155.78 257.64L155.78 247.39 157.31 246.51 157.31 256.76 155.78 257.64z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 163.875,
              MsTransformOrigin: 163.875,
              transformOrigin: 163.875,
            }}
            fill="#1DD21D"
            d="M163.11 253.4L163.11 239.38 164.64 238.5 164.64 252.52 163.11 253.4z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 171.205,
              MsTransformOrigin: 171.205,
              transformOrigin: 171.205,
            }}
            fill="#1DD21D"
            d="M170.44 249.17L170.44 245.18 171.97 244.31 171.97 248.29 170.44 249.17z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 171.23,
              MsTransformOrigin: 171.23,
              transformOrigin: 171.23,
            }}
            fill="#455A64"
            d="M170.12 251.13L172.34 249.85 172.34 250.63 170.12 251.91 170.12 251.13z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 174.11,
              MsTransformOrigin: 174.11,
              transformOrigin: 174.11,
            }}
            fill="#1DD21D"
            d="M173.35 247.49L173.35 245.51 174.87 244.63 174.87 246.62 173.35 247.49z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 174.13,
              MsTransformOrigin: 174.13,
              transformOrigin: 174.13,
            }}
            fill="#455A64"
            d="M173.03 249.45L175.24 248.17 175.24 248.96 173.02 250.23 173.03 249.45z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 162.43,
              MsTransformOrigin: 162.43,
              transformOrigin: 162.43,
            }}
            fill="#1DD21D"
            d="M163.26 226.85L161.6 227.81 161.6 229.73 163.26 228.77 163.26 226.85z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 162.43,
              MsTransformOrigin: 162.43,
              transformOrigin: 162.43,
            }}
            d="M163.26 226.85L161.6 227.81 161.6 229.73 163.26 228.77 163.26 226.85z"
            className="animable"
            opacity="0.25"
          ></path>
          <path
            d="M176.11 219.71l-11.87 6.84a1 1 0 00-.43.75v.38c0 .27.19.39.43.25l11.87-6.85a1 1 0 00.43-.75V220c0-.32-.19-.43-.43-.29z"
            style={{
              WebkitTransformOrigin: 170.175,
              MsTransformOrigin: 170.175,
              transformOrigin: 170.175,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 162.43,
              MsTransformOrigin: 162.43,
              transformOrigin: 162.43,
            }}
            fill="#1DD21D"
            d="M163.26 230.05L161.6 231.01 161.6 232.93 163.26 231.97 163.26 230.05z"
            className="animable"
          ></path>
          <path
            d="M176.11 222.9l-11.87 6.85a1 1 0 00-.43.75v.37c0 .28.19.39.43.25l11.87-6.85a.93.93 0 00.43-.75v-.37c0-.27-.19-.39-.43-.25z"
            style={{
              WebkitTransformOrigin: 170.175,
              MsTransformOrigin: 170.175,
              transformOrigin: 170.175,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 141.885,
              MsTransformOrigin: 141.885,
              transformOrigin: 141.885,
            }}
            fill="#1DD21D"
            d="M141.12 266.1L141.12 240.54 142.65 239.66 142.65 265.22 141.12 266.1z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 149.215,
              MsTransformOrigin: 149.215,
              transformOrigin: 149.215,
            }}
            fill="#1DD21D"
            d="M148.45 261.87L148.45 239.65 149.98 238.78 149.98 260.99 148.45 261.87z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 156.545,
              MsTransformOrigin: 156.545,
              transformOrigin: 156.545,
            }}
            fill="#1DD21D"
            d="M155.78 257.64L155.78 247.39 157.31 246.51 157.31 256.76 155.78 257.64z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 163.875,
              MsTransformOrigin: 163.875,
              transformOrigin: 163.875,
            }}
            fill="#1DD21D"
            d="M163.11 253.4L163.11 239.38 164.64 238.5 164.64 252.52 163.11 253.4z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 141.885,
              MsTransformOrigin: 141.885,
              transformOrigin: 141.885,
            }}
            fill="#1DD21D"
            d="M141.12 266.1L141.12 240.54 142.65 239.66 142.65 265.22 141.12 266.1z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 149.215,
              MsTransformOrigin: 149.215,
              transformOrigin: 149.215,
            }}
            fill="#1DD21D"
            d="M148.45 261.87L148.45 239.65 149.98 238.78 149.98 260.99 148.45 261.87z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 156.545,
              MsTransformOrigin: 156.545,
              transformOrigin: 156.545,
            }}
            fill="#1DD21D"
            d="M155.78 257.64L155.78 247.39 157.31 246.51 157.31 256.76 155.78 257.64z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 163.875,
              MsTransformOrigin: 163.875,
              transformOrigin: 163.875,
            }}
            fill="#1DD21D"
            d="M163.11 253.4L163.11 239.38 164.64 238.5 164.64 252.52 163.11 253.4z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 171.205,
              MsTransformOrigin: 171.205,
              transformOrigin: 171.205,
            }}
            fill="#1DD21D"
            d="M170.44 249.17L170.44 245.18 171.97 244.31 171.97 248.29 170.44 249.17z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 141.885,
              MsTransformOrigin: 141.885,
              transformOrigin: 141.885,
            }}
            d="M141.12 266.1L141.12 240.54 142.65 239.66 142.65 265.22 141.12 266.1z"
            className="animable"
            opacity="0.25"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 149.215,
              MsTransformOrigin: 149.215,
              transformOrigin: 149.215,
            }}
            d="M148.45 261.87L148.45 239.65 149.98 238.78 149.98 260.99 148.45 261.87z"
            className="animable"
            opacity="0.25"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 156.545,
              MsTransformOrigin: 156.545,
              transformOrigin: 156.545,
            }}
            d="M155.78 257.64L155.78 247.39 157.31 246.51 157.31 256.76 155.78 257.64z"
            className="animable"
            opacity="0.25"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 163.875,
              MsTransformOrigin: 163.875,
              transformOrigin: 163.875,
            }}
            d="M163.11 253.4L163.11 239.38 164.64 238.5 164.64 252.52 163.11 253.4z"
            className="animable"
            opacity="0.25"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 171.205,
              MsTransformOrigin: 171.205,
              transformOrigin: 171.205,
            }}
            d="M170.44 249.17L170.44 245.18 171.97 244.31 171.97 248.29 170.44 249.17z"
            className="animable"
            opacity="0.25"
          ></path>
          <path
            d="M183.18 212.26v33.61c0 .6.42.84.95.54l30-17.35a2.09 2.09 0 001-1.63v-33.61c0-.6-.43-.84-1-.54l-30 17.35a2.06 2.06 0 00-.95 1.63z"
            style={{
              WebkitTransformOrigin: 199.155,
              MsTransformOrigin: 199.155,
              transformOrigin: 199.155,
            }}
            fill="#1DD21D"
            className="animable"
            opacity="0.1"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 186.23,
              MsTransformOrigin: 186.23,
              transformOrigin: 186.23,
            }}
            fill="#1DD21D"
            d="M187.06 211.49L185.4 212.45 185.4 214.37 187.06 213.41 187.06 211.49z"
            className="animable"
          ></path>
          <path
            d="M193.18 208.45l-5.48 3.16a.57.57 0 00-.26.45v.52c0 .17.12.23.26.15l5.48-3.16a.59.59 0 00.26-.45v-.52c0-.17-.12-.23-.26-.15z"
            style={{
              WebkitTransformOrigin: 190.44,
              MsTransformOrigin: 190.44,
              transformOrigin: 190.44,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 195.63,
              MsTransformOrigin: 195.63,
              transformOrigin: 195.63,
            }}
            fill="#1DD21D"
            d="M196.46 206.06L194.8 207.02 194.81 208.93 196.46 207.97 196.46 206.06z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 195.63,
              MsTransformOrigin: 195.63,
              transformOrigin: 195.63,
            }}
            d="M196.46 206.06L194.8 207.02 194.81 208.93 196.46 207.97 196.46 206.06z"
            className="animable"
            opacity="0.25"
          ></path>
          <path
            d="M202.62 203.06l-5.48 3.16a.61.61 0 00-.26.45v.53a.16.16 0 00.26.15l5.48-3.16a.58.58 0 00.26-.46v-.52c0-.16-.11-.21-.26-.15z"
            style={{
              WebkitTransformOrigin: 199.879,
              MsTransformOrigin: 199.879,
              transformOrigin: 199.879,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 205.04,
              MsTransformOrigin: 205.04,
              transformOrigin: 205.04,
            }}
            fill="#1DD21D"
            d="M205.87 200.62L204.21 201.58 204.21 203.5 205.87 202.54 205.87 200.62z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 205.04,
              MsTransformOrigin: 205.04,
              transformOrigin: 205.04,
            }}
            d="M205.87 200.62L204.21 201.58 204.21 203.5 205.87 202.54 205.87 200.62z"
            className="animable"
            opacity="0.4"
          ></path>
          <path
            d="M212.06 197.68l-5.48 3.16a.54.54 0 00-.25.45v.52c0 .17.11.23.26.15l5.47-3.16a.55.55 0 00.26-.45v-.52c0-.17-.11-.23-.26-.15z"
            style={{
              WebkitTransformOrigin: 209.325,
              MsTransformOrigin: 209.325,
              transformOrigin: 209.325,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M199.68 212c-4.89 2.82-8.86 9.69-8.86 15.33s4 7.94 8.86 5.12 8.85-9.69 8.85-15.34-3.96-7.97-8.85-5.11zm-1.37 18.09a5.32 5.32 0 01-2.59.84c-1.89 0-2.17-2.25-2.17-3.6 0-4.69 3.43-10.63 7.49-13a5.31 5.31 0 012.59-.85c1.89 0 2.18 2.26 2.18 3.6 0 4.69-3.43 10.63-7.5 12.98z"
            style={{
              WebkitTransformOrigin: 199.675,
              MsTransformOrigin: 199.675,
              transformOrigin: 199.675,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M201 214.33a5.31 5.31 0 012.59-.85c1.18 0 1.73.87 2 1.86l2-2.29c-1.45-2.51-4.45-3.08-7.91-1.08v3.3a9.19 9.19 0 011.32-.94z"
            style={{
              WebkitTransformOrigin: 203.635,
              MsTransformOrigin: 203.635,
              transformOrigin: 203.635,
            }}
            className="animable"
            opacity="0.25"
          ></path>
          <path
            d="M207.59 213.05l-2 2.29a6.84 6.84 0 01.2 1.74 10.5 10.5 0 01-.14 1.65l2.86-1.65a8 8 0 00-.94-4z"
            style={{
              WebkitTransformOrigin: 207.052,
              MsTransformOrigin: 207.052,
              transformOrigin: 207.052,
            }}
            className="animable"
            opacity="0.4"
          ></path>
          <path
            d="M169.65 135.21c.17-.1.3-.1.39 0a.8.8 0 01.19.32l2.77 10.74a1.14 1.14 0 01.05.26.85.85 0 01-.08.35.49.49 0 01-.2.24l-1.6.93c-.17.1-.29.11-.37 0a.47.47 0 01-.13-.24l-.36-1.42-3.38 1.95-.36 1.83a1.23 1.23 0 01-.13.4.87.87 0 01-.36.38l-1.61.92a.14.14 0 01-.19 0 .33.33 0 01-.09-.26 2.12 2.12 0 01.05-.32l2.76-13.91a2.14 2.14 0 01.18-.54.94.94 0 01.39-.44zm-2.06 9.79l2.06-1.19-1-4.11z"
            style={{
              WebkitTransformOrigin: 168.619,
              MsTransformOrigin: 168.619,
              transformOrigin: 168.619,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M179.87 143a.29.29 0 01-.35 0 .55.55 0 01-.17-.18l-2.91-5v6.62a1 1 0 01-.11.43.62.62 0 01-.24.3l-1.66 1a.19.19 0 01-.25 0 .4.4 0 01-.1-.31v-12.68a1 1 0 01.1-.43.69.69 0 01.25-.3l1.32-.76a.33.33 0 01.35 0 .67.67 0 01.17.18l2.91 5v-6.63a1 1 0 01.11-.43.55.55 0 01.24-.29l1.66-1a.21.21 0 01.25 0 .46.46 0 01.1.31v12.66a1.08 1.08 0 01-.1.43.59.59 0 01-.25.3z"
            style={{
              WebkitTransformOrigin: 177.809,
              MsTransformOrigin: 177.809,
              transformOrigin: 177.809,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M188 124.6c.17-.1.3-.1.39 0a.67.67 0 01.18.33l2.78 10.74a1.14 1.14 0 01.05.26.73.73 0 01-.09.35.48.48 0 01-.19.24l-1.61.93c-.17.1-.29.11-.36 0a.41.41 0 01-.13-.24l-.36-1.42-3.38 2-.28 1.81a1.54 1.54 0 01-.13.4.9.9 0 01-.37.38l-1.6.92a.15.15 0 01-.2 0 .36.36 0 01-.08-.25 2 2 0 01.05-.33l2.77-13.94a2.7 2.7 0 01.19-.54.94.94 0 01.39-.44zm-2 9.75l2.06-1.19-1-4.11z"
            style={{
              WebkitTransformOrigin: 187.01,
              MsTransformOrigin: 187.01,
              transformOrigin: 187.01,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M198.69 129c.1-.05.18-.05.24 0a.42.42 0 01.11.31v2.08a1 1 0 01-.11.43.57.57 0 01-.24.3l-5.89 3.4a.18.18 0 01-.24 0 .4.4 0 01-.1-.31v-12.64a1 1 0 01.1-.43.76.76 0 01.24-.3l1.67-1a.18.18 0 01.24 0 .4.4 0 01.1.31v10z"
            style={{
              WebkitTransformOrigin: 195.749,
              MsTransformOrigin: 195.749,
              transformOrigin: 195.749,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M203.54 128.83a1 1 0 01-.1.43.76.76 0 01-.24.3l-1.67 1a.18.18 0 01-.24 0 .4.4 0 01-.1-.31V126l-2.94-6.62a.48.48 0 01-.05-.23.74.74 0 01.08-.35.45.45 0 01.2-.24l1.74-1c.17-.1.3-.11.38 0a.44.44 0 01.15.2l1.61 3.9 1.61-5.76a2.46 2.46 0 01.16-.38.91.91 0 01.38-.42l1.74-1a.15.15 0 01.2 0 .37.37 0 01.08.26.79.79 0 01-.05.28l-2.94 10z"
            style={{
              WebkitTransformOrigin: 202.365,
              MsTransformOrigin: 202.365,
              transformOrigin: 202.365,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M210.59 111.38a3.24 3.24 0 011.5-.5 2.11 2.11 0 011.17.25 1.88 1.88 0 01.78.8 2.48 2.48 0 01.29 1.15.77.77 0 01-.08.35.57.57 0 01-.18.23l-1.67 1a.5.5 0 01-.29.09.5.5 0 01-.22-.11.66.66 0 01-.12-.12.7.7 0 00-.24-.15.77.77 0 00-.38 0 1.35 1.35 0 00-.56.21 3.46 3.46 0 00-.46.32 3.64 3.64 0 00-.4.38 2 2 0 00-.28.47 1.42 1.42 0 00-.11.55.54.54 0 00.13.42.51.51 0 00.4.13 3.23 3.23 0 00.76-.12l1.17-.3a4.09 4.09 0 011.22-.16 1.54 1.54 0 01.87.33 1.78 1.78 0 01.53.88 5.53 5.53 0 01.17 1.5 6.5 6.5 0 01-.3 2 7.36 7.36 0 01-.82 1.81 8.23 8.23 0 01-1.24 1.58 7.94 7.94 0 01-1.58 1.2 3.71 3.71 0 01-1.57.55 2.11 2.11 0 01-1.23-.22 2 2 0 01-.85-.9 2.93 2.93 0 01-.31-1.3.79.79 0 01.09-.35.56.56 0 01.18-.23l1.67-1a.59.59 0 01.28-.1.4.4 0 01.2.09 1.42 1.42 0 01.16.17.83.83 0 00.27.2.81.81 0 00.43.08 1.46 1.46 0 00.64-.24 4.89 4.89 0 001.13-.87 1.58 1.58 0 00.45-1.08.41.41 0 00-.16-.39.89.89 0 00-.5-.08 4.43 4.43 0 00-.84.17l-1.22.31a1.87 1.87 0 01-1.92-.34 3.5 3.5 0 01-.6-2.34 6.43 6.43 0 01.25-1.75 7.48 7.48 0 01.72-1.77 8 8 0 011.14-1.59 6.1 6.1 0 011.53-1.21z"
            style={{
              WebkitTransformOrigin: 210.641,
              MsTransformOrigin: 210.641,
              transformOrigin: 210.641,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M216.2 122.05a.18.18 0 01-.24 0 .4.4 0 01-.1-.31v-12.68a1 1 0 01.1-.43.69.69 0 01.24-.3l1.67-1a.18.18 0 01.24 0 .4.4 0 01.1.31v12.66a1 1 0 01-.1.43.65.65 0 01-.24.29z"
            style={{
              WebkitTransformOrigin: 217.035,
              MsTransformOrigin: 217.035,
              transformOrigin: 217.035,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M223.31 104a3.24 3.24 0 011.49-.5 2.14 2.14 0 011.18.25 1.86 1.86 0 01.77.8 2.49 2.49 0 01.3 1.15.79.79 0 01-.09.35.44.44 0 01-.18.23l-1.67 1a.47.47 0 01-.28.09.49.49 0 01-.23-.11l-.12-.12a.57.57 0 00-.23-.15.81.81 0 00-.39 0 1.27 1.27 0 00-.55.21 3 3 0 00-.47.32 2.82 2.82 0 00-.39.38 1.72 1.72 0 00-.28.47 1.26 1.26 0 00-.11.55.59.59 0 00.12.42.53.53 0 00.41.13 3.16 3.16 0 00.75-.12q.48-.1 1.17-.3a4.13 4.13 0 011.22-.16 1.6 1.6 0 01.88.33 1.84 1.84 0 01.52.88 5.53 5.53 0 01.17 1.5 6.82 6.82 0 01-.29 2 8.19 8.19 0 01-.82 1.81 8.69 8.69 0 01-1.25 1.58 7.88 7.88 0 01-1.57 1.2 3.74 3.74 0 01-1.58.55 2.14 2.14 0 01-1.23-.22 2 2 0 01-.81-.83 2.93 2.93 0 01-.3-1.3.93.93 0 01.08-.35.57.57 0 01.19-.23l1.66-1a.59.59 0 01.28-.1.48.48 0 01.21.09l.15.17a1 1 0 00.27.2.85.85 0 00.44.08 1.55 1.55 0 00.64-.24 4.83 4.83 0 001.12-.87A1.59 1.59 0 00225 113a.42.42 0 00-.17-.39.89.89 0 00-.5-.08 4.43 4.43 0 00-.84.17l-1.22.31a1.85 1.85 0 01-1.91-.34 3.44 3.44 0 01-.61-2.34 6.84 6.84 0 01.25-1.75 8.43 8.43 0 01.72-1.77 8 8 0 011.14-1.59 6.19 6.19 0 011.45-1.22z"
            style={{
              WebkitTransformOrigin: 223.376,
              MsTransformOrigin: 223.376,
              transformOrigin: 223.376,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M157.24 271.29l82.7-47.74a1.65 1.65 0 012.61 1.21l.19 1.06a3.8 3.8 0 01-1.71 3.71l-82.58 47.68a1.7 1.7 0 01-2.67-1.2l-.2-1a3.69 3.69 0 011.66-3.72z"
            style={{
              WebkitTransformOrigin: 199.152,
              MsTransformOrigin: 199.152,
              transformOrigin: 199.152,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M165.28 317.58L232.07 279a1.67 1.67 0 012.39.35 2 2 0 01-1.08 2.35l-66.89 38.62c-1 .55-2 .39-2.34-.38a2.05 2.05 0 011.13-2.36z"
            style={{
              WebkitTransformOrigin: 199.311,
              MsTransformOrigin: 199.311,
              transformOrigin: 199.311,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M165.28 317.58L232.07 279a1.67 1.67 0 012.39.35 2 2 0 01-1.08 2.35l-66.89 38.62c-1 .55-2 .39-2.34-.38a2.05 2.05 0 011.13-2.36z"
            style={{
              WebkitTransformOrigin: 199.311,
              MsTransformOrigin: 199.311,
              transformOrigin: 199.311,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.7"
          ></path>
          <path
            d="M168 323l40.91-23.59a1.64 1.64 0 012.38.35 2 2 0 01-1.07 2.35l-41 23.65c-1 .55-2 .38-2.34-.38A2.06 2.06 0 01168 323z"
            style={{
              WebkitTransformOrigin: 189.09,
              MsTransformOrigin: 189.09,
              transformOrigin: 189.09,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M179.82 343.93L247.2 305a2 2 0 012.6.23c.49.68.1 1.68-.86 2.23l-67.43 38.93a2 2 0 01-2.58-.25c-.46-.65-.07-1.65.89-2.21z"
            style={{
              WebkitTransformOrigin: 214.375,
              MsTransformOrigin: 214.375,
              transformOrigin: 214.375,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M179.82 343.93L247.2 305a2 2 0 012.6.23c.49.68.1 1.68-.86 2.23l-67.43 38.93a2 2 0 01-2.58-.25c-.46-.65-.07-1.65.89-2.21z"
            style={{
              WebkitTransformOrigin: 214.375,
              MsTransformOrigin: 214.375,
              transformOrigin: 214.375,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.7"
          ></path>
          <path
            d="M183.25 348.88l26.47-15.28a2 2 0 012.61.22c.48.67.1 1.66-.86 2.22L185 351.3a2.06 2.06 0 01-2.62-.21c-.46-.67-.09-1.66.87-2.21z"
            style={{
              WebkitTransformOrigin: 197.36,
              MsTransformOrigin: 197.36,
              transformOrigin: 197.36,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M188.73 356.06l67.52-39a2.21 2.21 0 012.7.18c.53.65.19 1.63-.76 2.18l-67.53 39a2.21 2.21 0 01-2.7-.17c-.53-.67-.19-1.64.77-2.19z"
            style={{
              WebkitTransformOrigin: 223.455,
              MsTransformOrigin: 223.455,
              transformOrigin: 223.455,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M188.73 356.06l67.52-39a2.21 2.21 0 012.7.18c.53.65.19 1.63-.76 2.18l-67.53 39a2.21 2.21 0 01-2.7-.17c-.53-.67-.19-1.64.77-2.19z"
            style={{
              WebkitTransformOrigin: 223.455,
              MsTransformOrigin: 223.455,
              transformOrigin: 223.455,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.7"
          ></path>
          <path
            d="M192.6 360.68l26.32-15.2a2.3 2.3 0 012.75.17c.56.65.24 1.62-.71 2.17L194.67 363a2.33 2.33 0 01-2.77-.16c-.57-.64-.25-1.61.7-2.16z"
            style={{
              WebkitTransformOrigin: 206.781,
              MsTransformOrigin: 206.781,
              transformOrigin: 206.781,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M172 331.05l52.84-30.51a1.84 1.84 0 012.5.29 1.78 1.78 0 01-1 2.29l-52.92 30.56a1.77 1.77 0 01-2.46-.31 1.85 1.85 0 011.04-2.32z"
            style={{
              WebkitTransformOrigin: 199.157,
              MsTransformOrigin: 199.157,
              transformOrigin: 199.157,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M172 331.05l52.84-30.51a1.84 1.84 0 012.5.29 1.78 1.78 0 01-1 2.29l-52.92 30.56a1.77 1.77 0 01-2.46-.31 1.85 1.85 0 011.04-2.32z"
            style={{
              WebkitTransformOrigin: 199.157,
              MsTransformOrigin: 199.157,
              transformOrigin: 199.157,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.7"
          ></path>
          <path
            d="M175 336.3l48.18-27.82a1.87 1.87 0 012.53.28c.45.7 0 1.72-.93 2.28l-48.23 27.82a1.85 1.85 0 01-2.51-.28 1.76 1.76 0 01.96-2.28z"
            style={{
              WebkitTransformOrigin: 199.9,
              MsTransformOrigin: 199.9,
              transformOrigin: 199.9,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M252.81 278.32a2.11 2.11 0 012.86.95 2.19 2.19 0 01-.66 3 2.12 2.12 0 01-2.87-.95 2.19 2.19 0 01.67-3z"
            style={{
              WebkitTransformOrigin: 253.906,
              MsTransformOrigin: 253.906,
              transformOrigin: 253.906,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M265.67 298.68a2.48 2.48 0 013.11.8 1.79 1.79 0 01-.46 2.77 2.54 2.54 0 01-3.11-.8 1.84 1.84 0 01.46-2.77z"
            style={{
              WebkitTransformOrigin: 267.009,
              MsTransformOrigin: 267.009,
              transformOrigin: 267.009,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M265.67 298.68a2.48 2.48 0 013.11.8 1.79 1.79 0 01-.46 2.77 2.54 2.54 0 01-3.11-.8 1.84 1.84 0 01.46-2.77z"
            style={{
              WebkitTransformOrigin: 267.009,
              MsTransformOrigin: 267.009,
              transformOrigin: 267.009,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.7"
          ></path>
          <path
            d="M216.58 388L317 330a6.12 6.12 0 015.5 0l53.14 30.68c1.51.88 1.51 2.3 0 3.17l-100.42 58a6.06 6.06 0 01-5.5 0l-53.13-30.67c-1.53-.89-1.53-2.31-.01-3.18z"
            style={{
              WebkitTransformOrigin: 296.107,
              MsTransformOrigin: 296.107,
              transformOrigin: 296.107,
            }}
            fill="#1DD21D"
            className="animable"
            opacity="0.1"
          ></path>
          <path
            d="M284.55 406.27a.83.83 0 01.18.13.53.53 0 01.12.14.44.44 0 01.06.16.67.67 0 010 .2l-.05.46.75-.44H285.78l.37.21v.1l-1.59.92h-.09a.32.32 0 01-.09 0l-.29-.17a.24.24 0 01-.09-.08.08.08 0 010-.09l.16-.94a.24.24 0 000-.18.52.52 0 00-.11-.1h-.35a.58.58 0 00-.17.06.24.24 0 00-.1.1.14.14 0 000 .09l.05.07v.06a.1.1 0 01-.06.05l-.37.22H282.93a.78.78 0 01-.24-.2.43.43 0 010-.5.88.88 0 01.3-.25 1.59 1.59 0 01.41-.16 1.64 1.64 0 01.44 0 1.4 1.4 0 01.41.06 1.42 1.42 0 01.3.08z"
            style={{
              WebkitTransformOrigin: 284.38,
              MsTransformOrigin: 284.38,
              transformOrigin: 284.38,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M285 405.1a2 2 0 01.46-.18 1.94 1.94 0 01.48 0 1.48 1.48 0 01.46.08 2.17 2.17 0 01.42.17 2.48 2.48 0 01.31.18l.3.18a1.22 1.22 0 01.3.24.48.48 0 01.14.26.41.41 0 01-.06.28.83.83 0 01-.31.26 1.43 1.43 0 01-.47.18 1.5 1.5 0 01-.47 0 1.77 1.77 0 01-.46-.08 2.63 2.63 0 01-.42-.17l-.31-.17-.31-.19a1 1 0 01-.29-.24.61.61 0 01-.15-.27.39.39 0 01.07-.27 1 1 0 01.31-.26zm1.86.71l-.58-.34-.18-.08h-.18a.54.54 0 00-.18 0l-.19.07a.33.33 0 00-.12.11.17.17 0 000 .1.38.38 0 00.08.11.56.56 0 00.14.1 6.33 6.33 0 00.58.34l.18.08h.18a.55.55 0 00.18 0 .45.45 0 00.18-.07.32.32 0 00.12-.1.14.14 0 000-.11.2.2 0 00-.07-.1.85.85 0 00-.19-.11z"
            style={{
              WebkitTransformOrigin: 286.246,
              MsTransformOrigin: 286.246,
              transformOrigin: 286.246,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M286.86 404.94a.1.1 0 01-.08 0v-.06l.07-.7v-.06c0-.06 0 0 .05-.06l.37-.21a.11.11 0 01.08 0h.09l2.24 1.3a.05.05 0 010 .05v.05l-.4.22a.11.11 0 01-.08 0h-.09l-1.52-.88v.39a.16.16 0 01-.1 0z"
            style={{
              WebkitTransformOrigin: 288.233,
              MsTransformOrigin: 288.233,
              transformOrigin: 288.233,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M291 403.48a.92.92 0 01.29.25.41.41 0 01.08.27.48.48 0 01-.12.26.84.84 0 01-.28.24 1.59 1.59 0 01-.4.16 2.32 2.32 0 01-.46.07 1.88 1.88 0 01-.47-.05 1.49 1.49 0 01-.44-.17.83.83 0 01-.17-.12l-.11-.13a.75.75 0 01-.07-.12l-.05-.12-.32-.85v-.05l.48-.28h.14l.22.58a1.71 1.71 0 01.38-.13 3 3 0 01.43 0l.42.06a1.52 1.52 0 01.45.13zm-1.24.71a.44.44 0 00.16.06h.35l.15-.06a.43.43 0 00.1-.09.2.2 0 000-.09.22.22 0 000-.11.35.35 0 00-.11-.09.52.52 0 00-.16-.06h-.35l-.14.06a.33.33 0 00-.11.09.41.41 0 00-.05.09.22.22 0 000 .11.35.35 0 00.18.09z"
            style={{
              WebkitTransformOrigin: 289.925,
              MsTransformOrigin: 289.925,
              transformOrigin: 289.925,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M310.68 391.4l.17.12a.43.43 0 01.13.14l.06.17a.67.67 0 010 .2l-.06.45.76-.43H311.91l.37.22a.05.05 0 010 .05.05.05 0 010 .05l-1.58.91h-.18l-.3-.18-.09-.07a.1.1 0 010-.09l.15-.95a.22.22 0 000-.17.4.4 0 00-.12-.1H309.82a.37.37 0 00-.17 0c-.06 0-.09.07-.1.1a.1.1 0 000 .09.3.3 0 000 .08v.07c0 .02 0 0-.06.05l-.37.21h-.14a1 1 0 01-.24-.2.52.52 0 01-.11-.24.43.43 0 01.07-.26.82.82 0 01.29-.25 1.38 1.38 0 01.42-.17 2.18 2.18 0 01.44 0 1.84 1.84 0 01.41.06 1.94 1.94 0 01.42.14z"
            style={{
              WebkitTransformOrigin: 310.458,
              MsTransformOrigin: 310.458,
              transformOrigin: 310.458,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M311.08 390.23a1.38 1.38 0 01.46-.18 1.52 1.52 0 01.47 0 2.33 2.33 0 01.46.08 4.08 4.08 0 01.42.17l.32.18.3.18a2 2 0 01.3.24.59.59 0 01.14.27.39.39 0 01-.07.27.85.85 0 01-.31.27 1.38 1.38 0 01-.46.18 2.56 2.56 0 01-.47 0 1.79 1.79 0 01-.46-.09 1.94 1.94 0 01-.42-.17l-.31-.17-.31-.18a1.53 1.53 0 01-.3-.25.51.51 0 01-.14-.26.39.39 0 01.07-.27.7.7 0 01.31-.27zm1.86.7l-.58-.34-.19-.08h-.36a.45.45 0 00-.18.07.32.32 0 00-.12.1.14.14 0 000 .11.3.3 0 00.07.1l.14.11.59.33.17.08a.58.58 0 00.18 0h.19a.67.67 0 00.18-.07.33.33 0 00.12-.11.12.12 0 000-.1.34.34 0 00-.07-.11z"
            style={{
              WebkitTransformOrigin: 312.325,
              MsTransformOrigin: 312.325,
              transformOrigin: 312.325,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M313 390.07h-.09l.08-.7a.2.2 0 010-.07l.05-.06.37-.21H313.58l2.24 1.29v.05l-.39.23h-.18l-1.51-.87-.05.38a.05.05 0 010 .05h-.09z"
            style={{
              WebkitTransformOrigin: 314.365,
              MsTransformOrigin: 314.365,
              transformOrigin: 314.365,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M316.4 389.9l-1.1-1.38-.89.51h-.18l-.36-.22a.05.05 0 010-.05l1.44-.83H315.48l.33.19a.38.38 0 01.12.13l1.1 1.38v.05a.05.05 0 010 .05l-.42.24a.11.11 0 01-.08 0z"
            style={{
              WebkitTransformOrigin: 315.45,
              MsTransformOrigin: 315.45,
              transformOrigin: 315.45,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M338.09 375.5l.18.12a.53.53 0 01.12.14.49.49 0 01.06.17.67.67 0 010 .2l-.05.45.75-.43h.09a.16.16 0 01.09 0l.36.21a.08.08 0 01.05.05.05.05 0 010 .05l-1.59.91a.11.11 0 01-.08 0h-.09l-.3-.18a.22.22 0 01-.09-.07.09.09 0 010-.09l.16-1a.18.18 0 000-.17.52.52 0 00-.11-.1h-.35a.41.41 0 00-.17.05.46.46 0 00-.1.1.34.34 0 000 .09.35.35 0 000 .08v.07c0 .02 0 0-.06.05l-.37.21h-.14a1.59 1.59 0 01-.24-.2.5.5 0 01-.1-.24.37.37 0 01.07-.26.74.74 0 01.29-.25 1.54 1.54 0 01.42-.17 2.09 2.09 0 01.43 0 1.4 1.4 0 01.41.06 1.5 1.5 0 01.36.15z"
            style={{
              WebkitTransformOrigin: 337.927,
              MsTransformOrigin: 337.927,
              transformOrigin: 337.927,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M338.49 374.33a1.43 1.43 0 01.47-.18 1.83 1.83 0 01.47 0 2.33 2.33 0 01.46.08 4.08 4.08 0 01.42.17l.32.18.3.18a2.47 2.47 0 01.29.24.5.5 0 01.14.27.34.34 0 01-.06.27 1 1 0 01-.31.27 1.43 1.43 0 01-.47.18 2.42 2.42 0 01-.47 0 1.79 1.79 0 01-.46-.09 1.94 1.94 0 01-.42-.17l-.31-.17-.31-.18a1.8 1.8 0 01-.29-.25.45.45 0 01-.14-.26.34.34 0 01.06-.27.77.77 0 01.31-.27zm1.86.7l-.58-.34-.18-.07a.61.61 0 00-.18-.05h-.18a.4.4 0 00-.18.07.32.32 0 00-.12.1.11.11 0 000 .11.2.2 0 00.07.1.57.57 0 00.14.11l.58.33.18.08a.58.58 0 00.18 0h.18a.67.67 0 00.18-.07.33.33 0 00.12-.11.18.18 0 000-.1.38.38 0 00-.08-.11z"
            style={{
              WebkitTransformOrigin: 339.74,
              MsTransformOrigin: 339.74,
              transformOrigin: 339.74,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M340.4 374.17h-.08a.05.05 0 010-.05l.07-.7v-.07a.13.13 0 01.05-.06l.37-.21h.08a.12.12 0 01.09 0l2.24 1.29.05.05a.05.05 0 010 .05l-.4.23H342.7l-1.52-.87v.38a.07.07 0 010 .05H341z"
            style={{
              WebkitTransformOrigin: 341.795,
              MsTransformOrigin: 341.795,
              transformOrigin: 341.795,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M342 373.19a.65.65 0 01-.24-.22.36.36 0 01-.06-.24.48.48 0 01.12-.25 1 1 0 01.26-.22 2.15 2.15 0 01.38-.15 2 2 0 01.43-.07 2 2 0 01.42 0 1.15 1.15 0 01.38.14.74.74 0 01.21.17.37.37 0 01.09.19 1.58 1.58 0 01.38 0 1.4 1.4 0 01.34.13.74.74 0 01.24.22.38.38 0 01.05.25.5.5 0 01-.13.25 1 1 0 01-.29.24 2.4 2.4 0 01-.4.17 2.24 2.24 0 01-.44.07 1.53 1.53 0 01-.43 0 1.42 1.42 0 01-.38-.14.66.66 0 01-.23-.2.35.35 0 01-.07-.21l-.33-.05a1.14 1.14 0 01-.3-.08zm.55-.32a.49.49 0 00.25.05.6.6 0 00.28-.08.25.25 0 00.14-.16.14.14 0 00-.09-.15.62.62 0 00-.25 0 .74.74 0 00-.28.08c-.09.06-.13.11-.14.17a.17.17 0 00.07.09zm.94.59a.4.4 0 00.25.05.7.7 0 00.32-.1c.11-.07.17-.13.18-.19s0-.1-.08-.14a.4.4 0 00-.25-.05.7.7 0 00-.32.1c-.11.07-.17.13-.18.19s-.01.1.06.14z"
            style={{
              WebkitTransformOrigin: 343.351,
              MsTransformOrigin: 343.351,
              transformOrigin: 343.351,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M360.74 362.43a.52.52 0 01.17.12.43.43 0 01.13.14.44.44 0 01.06.16.67.67 0 010 .2l-.06.46.76-.44H361.97l.37.21v.06c0 .06 0 0 0 0l-1.58.92h-.18l-.3-.17-.09-.08a.09.09 0 010-.09l.15-.94a.25.25 0 000-.18.27.27 0 00-.12-.09H359.88a.58.58 0 00-.17.06.19.19 0 00-.1.1.1.1 0 000 .09.16.16 0 000 .07v.11l-.06.06-.37.21h-.07a.08.08 0 01-.07 0 .68.68 0 01-.24-.2.44.44 0 01-.11-.24.4.4 0 01.07-.25.83.83 0 01.29-.26 1.81 1.81 0 01.42-.16 1.64 1.64 0 01.44 0 1.4 1.4 0 01.41.06 1.14 1.14 0 01.42.07z"
            style={{
              WebkitTransformOrigin: 360.515,
              MsTransformOrigin: 360.515,
              transformOrigin: 360.515,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M361.14 361.25a2 2 0 01.46-.18 1.89 1.89 0 01.47 0 1.77 1.77 0 01.46.08 1.94 1.94 0 01.42.17 2.58 2.58 0 01.32.18l.3.18a1.22 1.22 0 01.3.24.56.56 0 01.14.26.42.42 0 01-.07.28.85.85 0 01-.31.27 2 2 0 01-.46.18 1.89 1.89 0 01-.47 0 1.77 1.77 0 01-.46-.08 2.63 2.63 0 01-.42-.17l-.31-.17-.31-.19a1.22 1.22 0 01-.3-.24.56.56 0 01-.14-.26.41.41 0 01.07-.28.85.85 0 01.31-.27zm1.86.75l-.58-.34-.19-.08h-.17a.4.4 0 00-.19 0l-.18.06a.33.33 0 00-.12.11.12.12 0 000 .1.34.34 0 00.07.11l.14.1c.18.12.38.23.58.34l.18.08h.19a.37.37 0 00.18 0 .64.64 0 00.18-.06.44.44 0 00.12-.11.12.12 0 000-.1.19.19 0 00-.07-.11z"
            style={{
              WebkitTransformOrigin: 362.385,
              MsTransformOrigin: 362.385,
              transformOrigin: 362.385,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M363.05 361.09a.12.12 0 01-.09 0 .09.09 0 010-.05l.08-.71a.15.15 0 010-.06l.05-.06.37-.21a.11.11 0 01.08 0h.09l2.24 1.3a.05.05 0 010 .05v.05l-.39.22h-.18l-1.51-.88v.39h-.09z"
            style={{
              WebkitTransformOrigin: 364.417,
              MsTransformOrigin: 364.417,
              transformOrigin: 364.417,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M364.63 360.23a.81.81 0 01-.29-.25.39.39 0 01-.07-.27.4.4 0 01.11-.26.86.86 0 01.27-.22 1.45 1.45 0 01.38-.16 1.76 1.76 0 01.46-.06 1.79 1.79 0 01.46 0 1.55 1.55 0 01.44.17l.17.12a.53.53 0 01.1.12l.08.12a.61.61 0 00.05.12l.34.86-.48.28h-.14l-.24-.58a2 2 0 01-.37.11 1.84 1.84 0 01-.41 0 2 2 0 01-.42-.05 1.34 1.34 0 01-.44-.05zm1.2-.69a.34.34 0 00-.15-.06h-.33a.34.34 0 00-.15.06.39.39 0 00-.1.08.3.3 0 000 .1.16.16 0 000 .09.18.18 0 00.1.09.34.34 0 00.15.06h.33a.34.34 0 00.15-.06.39.39 0 00.1-.08.18.18 0 000-.1.16.16 0 000-.09.22.22 0 00-.1-.09z"
            style={{
              WebkitTransformOrigin: 365.699,
              MsTransformOrigin: 365.699,
              transformOrigin: 365.699,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M263.09 408.1c2.46-1.48-16.81-13-8.35-17.7s22.14 6.53 30.64 1.61c8.08-4.68-16.7-16.13-8.36-20.91s24.69 7.93 33.44 3.22c7.6-4.1-1.53-8.66 2.07-12.14s13.22.8 19.45-2.61-17.3-11-4.41-18.49l30.09 17.38-90 52.29z"
            style={{
              WebkitTransformOrigin: 305.119,
              MsTransformOrigin: 305.119,
              transformOrigin: 305.119,
            }}
            fill="#1DD21D"
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M264.13 408.29l-2.07-.39c.59-.38-1.64-2.52-3.44-4.24-4.5-4.32-10.66-10.24-4.9-13.46a11.63 11.63 0 015.13-1.36c3.9-.21 7.95 1 11.86 2.1 3.53 1 7.18 2.09 10 1.93a7.87 7.87 0 003.62-1.06c3.75-2.17-.34-6.15-4.28-10-4.2-4.1-8.55-8.34-4.07-10.9a10.85 10.85 0 014.89-1.27c4.25-.2 8.73 1.24 13.48 2.76 4.2 1.34 8.53 2.73 11.77 2.59a7.48 7.48 0 003.3-.87c4.14-2.23 3-4.64 2-7-.83-1.81-1.62-3.53 0-5.1 2.12-2.05 6.49-1.8 10-1.6 3.67.21 6.84.4 9.56-1.09 2.24-1.22-.11-3.26-2.83-5.62-4.24-3.69-9.52-8.28-1.6-12.85l2.06.39c-7.36 4.25-2.3 8.64 1.76 12.18 2.94 2.55 5.48 4.76 2.64 6.32-3.55 1.94-8.23 1.67-12 1.45s-5.9-.33-7.36 1.08-.69 3 .1 4.72c1.09 2.35 2.32 5-2.29 7.49a11.5 11.5 0 01-5 1.24c-4.36.19-8.9-1.26-13.71-2.8-4.14-1.33-8.41-2.69-11.51-2.55a7 7 0 00-3.17.89c-3.93 2.25.24 6.31 4.26 10.25 4.31 4.2 8.38 8.18 4.1 10.66a11.75 11.75 0 01-5.29 1.44c-3.94.22-8-1-12-2.11-3.5-1-7.12-2.07-9.92-1.92a7.88 7.88 0 00-3.47 1c-5.19 2.91 1 8.86 5.11 12.8 2.97 2.85 4.35 4.25 3.27 4.9z"
            style={{
              WebkitTransformOrigin: 292.817,
              MsTransformOrigin: 292.817,
              transformOrigin: 292.817,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M279.3 372.41a1.07 1.07 0 01-.63-.05l-.46-.27c-.12-.07 0-.16.17-.21a1.14 1.14 0 01.63.06l.46.26c.12.08.05.17-.17.21z"
            style={{
              WebkitTransformOrigin: 278.845,
              MsTransformOrigin: 278.845,
              transformOrigin: 278.845,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M305.55 387.57a1.14 1.14 0 01-.63-.06L304 387c-.12-.07-.05-.17.17-.21a1 1 0 01.62.06l.94.54c.11.04.03.13-.18.18zm-1.88-1.09a1.07 1.07 0 01-.63-.05l-.93-.54c-.13-.08-.05-.17.16-.21a1.15 1.15 0 01.63.05l.94.55c.16.07.05.16-.17.2zm-1.87-1.08a1.15 1.15 0 01-.63 0l-.94-.55c-.12-.07-.05-.16.17-.2a1.07 1.07 0 01.63 0l.93.54c.13.08.04.17-.16.21zm-1.88-1.08a1.06 1.06 0 01-.63-.06l-.93-.54c-.13-.07-.05-.16.17-.21a1.12 1.12 0 01.62.06l.94.54c.12.07.05.17-.17.21zm-1.87-1.08a1.14 1.14 0 01-.63-.06l-.94-.54c-.12-.07-.05-.17.17-.21a1.06 1.06 0 01.63.06l.93.54c.13.07.05.16-.16.21zm-1.88-1.09a1 1 0 01-.62 0l-.94-.54c-.13-.08-.05-.17.17-.21a1.13 1.13 0 01.62 0l.94.55c.13.05.05.16-.17.2zm-1.87-1.08a1.15 1.15 0 01-.63-.05l-.94-.54c-.12-.08-.05-.17.17-.21a1.07 1.07 0 01.63.05l.93.54c.13.08.06.14-.16.21zm-1.88-1.07a1 1 0 01-.62-.06l-.94-.54c-.13-.07-.05-.16.17-.21a1.1 1.1 0 01.62.06l.94.54c.13.06.05.21-.17.21zm-1.87-1.08a1.14 1.14 0 01-.63-.06l-.94-.54c-.12-.07-.05-.17.17-.21a1.06 1.06 0 01.63.06l.93.54c.13.06.06.15-.16.2zm-1.88-1.09a1 1 0 01-.62-.05l-.94-.54c-.13-.08-.05-.17.17-.21a1 1 0 01.62.06l.94.54c.16.06.05.15-.17.19zm-1.87-1.08a1.15 1.15 0 01-.63-.05l-.94-.54c-.12-.08-.05-.17.17-.21a1.07 1.07 0 01.63.05l.94.54c.12.07.03.16-.17.2zm-1.88-1.08a1 1 0 01-.62-.06l-.94-.54c-.13-.07-.05-.16.17-.21a1.1 1.1 0 01.62.06l.94.54c.13.06.05.16-.17.2zm-1.87-1.08a1.14 1.14 0 01-.63-.06l-.93-.54c-.13-.07-.06-.17.16-.21a1.14 1.14 0 01.63.06l.94.54c.12.06.05.16-.17.2zm-1.88-1.08a1.1 1.1 0 01-.62-.06l-.94-.54c-.13-.07-.05-.17.17-.21a1 1 0 01.62.06l.94.54c.13.06.05.15-.17.2z"
            style={{
              WebkitTransformOrigin: 292.663,
              MsTransformOrigin: 292.663,
              transformOrigin: 292.663,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M306.94 388.37a1.15 1.15 0 01-.63-.05l-.45-.27c-.13-.07-.05-.16.16-.21a1.14 1.14 0 01.63.06l.46.26c.12.08.05.17-.17.21z"
            style={{
              WebkitTransformOrigin: 306.483,
              MsTransformOrigin: 306.483,
              transformOrigin: 306.483,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M277.81 403.46a1.14 1.14 0 01-.63-.06l-.94-.54c-.12-.07-.05-.17.17-.21a1.06 1.06 0 01.63.06l.94.54c.12.07.02.16-.17.21zm-1.88-1.09a1 1 0 01-.62-.05l-.94-.54c-.13-.08-.05-.17.17-.21a1.11 1.11 0 01.62.05l.94.55c.13.07.05.16-.17.2zm-1.87-1.08a1.15 1.15 0 01-.63-.05l-.93-.54c-.13-.08-.06-.17.16-.21a1.07 1.07 0 01.63.05l.94.54c.12.08.05.17-.17.21zm-1.88-1.08a1 1 0 01-.62-.06l-.94-.54c-.13-.07-.05-.16.17-.21a1.1 1.1 0 01.62.06l.94.54c.13.07.05.17-.17.21zm-1.87-1.08a1.14 1.14 0 01-.63-.06l-.93-.54c-.13-.07-.06-.17.16-.21a1.06 1.06 0 01.63.06l.94.54c.12.08.05.16-.17.21zm-1.88-1.08a1.12 1.12 0 01-.62-.06l-.94-.54c-.13-.08-.05-.17.17-.21a1 1 0 01.62.06l.94.54c.13.07.05.16-.17.21zm-1.87-1.05a1.15 1.15 0 01-.63 0l-.93-.54c-.13-.08-.05-.17.16-.21a1.07 1.07 0 01.63 0l.94.54c.12.04.05.13-.17.21zm-1.88-1.08a1 1 0 01-.62-.06l-.94-.54c-.12-.07-.05-.16.17-.21a1.14 1.14 0 01.63.06l.93.54c.15.03.05.13-.17.17zm-1.87-1.08a1.14 1.14 0 01-.63-.06l-.93-.54c-.13-.07-.05-.17.16-.21a1.14 1.14 0 01.63.06l.94.54c.12.03.02.13-.17.17zm-1.87-1.08a1.14 1.14 0 01-.63-.06l-.94-.54c-.12-.07-.05-.17.17-.21a1.06 1.06 0 01.63.06l.93.54c.13.03.05.12-.16.17zm-1.88-1.09a1 1 0 01-.62-.05l-.94-.54c-.13-.08-.05-.17.17-.21a1.13 1.13 0 01.62.05l.94.54c.12.04.05.13-.17.17zm-1.87-1.08a1.06 1.06 0 01-.63-.06l-.94-.54c-.12-.07-.05-.16.17-.2a1.07 1.07 0 01.63.05l.93.54c.13.04.05.13-.16.17z"
            style={{
              WebkitTransformOrigin: 266.797,
              MsTransformOrigin: 266.797,
              transformOrigin: 266.797,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M279.2 404.26a1.11 1.11 0 01-.62-.05l-.46-.27c-.13-.07-.05-.16.17-.21a1.1 1.1 0 01.62.06l.46.26c.13.08.05.17-.17.21z"
            style={{
              WebkitTransformOrigin: 278.745,
              MsTransformOrigin: 278.745,
              transformOrigin: 278.745,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M315.7 362.1a1 1 0 01-.62-.06l-.46-.26c-.13-.08-.05-.17.16-.21a1.15 1.15 0 01.63.05l.46.27c.13.11.05.17-.17.21z"
            style={{
              WebkitTransformOrigin: 315.245,
              MsTransformOrigin: 315.245,
              transformOrigin: 315.245,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M332.45 371.77a1.14 1.14 0 01-.63-.06l-.93-.54c-.12-.07-.05-.16.17-.2a1.07 1.07 0 01.63 0l.93.54c.12.12.05.22-.17.26zm-1.86-1.08a1.07 1.07 0 01-.63-.05l-.93-.54c-.13-.07 0-.17.17-.21a1 1 0 01.62.06l.94.53c.12.08.05.17-.17.21zm-1.86-1.07a1.06 1.06 0 01-.63-.06l-.93-.53c-.13-.08-.05-.17.17-.21a1 1 0 01.62.05l.93.54c.11.07.11.17-.16.21zm-1.86-1.08a1.07 1.07 0 01-.63-.05l-.93-.54c-.13-.07-.05-.17.17-.21a1.1 1.1 0 01.62.06l.93.54c.13.07.06.16-.16.2zm-1.87-1.07a1 1 0 01-.62-.06l-.93-.53c-.13-.08-.05-.17.17-.21a1.11 1.11 0 01.62.05l.93.54c.13.07.05.17-.17.21zm-1.86-1.07a1.1 1.1 0 01-.62-.06l-.93-.54c-.13-.07 0-.16.17-.21a1.1 1.1 0 01.62.06l.93.54c.13.07.05.16-.17.21zm-1.86-1.08a1.11 1.11 0 01-.62-.05l-.93-.54c-.13-.08-.05-.17.16-.21a1.06 1.06 0 01.63.06l.93.53c.13.08.05.17-.17.21zm-1.86-1.07a1.1 1.1 0 01-.62-.06l-.93-.54c-.13-.07-.06-.16.16-.21a1.14 1.14 0 01.63.06l.93.54c.13.07.05.16-.17.21zm-1.86-1.08a1.11 1.11 0 01-.62-.05l-.93-.54c-.13-.07-.06-.17.16-.21a1.06 1.06 0 01.63.06l.93.53c.13.04.05.17-.17.21z"
            style={{
              WebkitTransformOrigin: 324.312,
              MsTransformOrigin: 324.312,
              transformOrigin: 324.312,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M333.84 372.57a1.06 1.06 0 01-.63-.06l-.46-.26c-.12-.07-.05-.17.17-.21a1.06 1.06 0 01.63.06l.46.26c.12.07.05.17-.17.21z"
            style={{
              WebkitTransformOrigin: 333.38,
              MsTransformOrigin: 333.38,
              transformOrigin: 333.38,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 270.455,
              MsTransformOrigin: 270.455,
              transformOrigin: 270.455,
            }}
            fill="#455A64"
            d="M275.44 366.58L271.14 364.1 265.47 367.38 269.77 369.87 272.13 368.5 274.83 369.43 273.62 367.63 273.62 367.63 275.44 366.58z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 247.93,
              MsTransformOrigin: 247.93,
              transformOrigin: 247.93,
            }}
            fill="#455A64"
            d="M252.91 386.34L248.61 383.86 242.95 387.14 247.25 389.62 249.6 388.26 252.31 389.19 251.1 387.39 251.1 387.39 252.91 386.34z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 308.21,
              MsTransformOrigin: 308.21,
              transformOrigin: 308.21,
            }}
            fill="#455A64"
            d="M313.19 357.37L308.89 354.89 303.23 358.18 307.53 360.66 309.88 359.29 312.59 360.23 311.38 358.42 311.38 358.42 313.19 357.37z"
            className="animable"
          ></path>
          <path
            d="M300.82 422.2h.12a.18.18 0 01.13 0l.54.31a.12.12 0 01.07.07.31.31 0 010 .08l.09.66a2.75 2.75 0 01.9-.12 1.81 1.81 0 01.85.24c.39.22.56.46.52.7s-.26.48-.67.72a2.73 2.73 0 01-.64.27 2.53 2.53 0 01-.64.11 2.18 2.18 0 01-.58 0 1.3 1.3 0 01-.47-.16l-.05-.06a.07.07 0 010-.06l.55-.32a.45.45 0 01.19-.05h.53a1 1 0 00.32-.13.6.6 0 00.27-.25c0-.08 0-.16-.12-.23a.6.6 0 00-.4-.07 1.5 1.5 0 00-.43.16l-.37.21a.16.16 0 01-.12 0 .28.28 0 01-.13 0l-.44-.25s-.06-.05-.07-.08 0-.06 0-.08l-.08-.59-1.14.67a.35.35 0 01-.26 0l-.54-.31a.16.16 0 01-.06-.08.08.08 0 010-.07z"
            style={{
              WebkitTransformOrigin: 301.364,
              MsTransformOrigin: 301.364,
              transformOrigin: 301.364,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M302.74 421a2.27 2.27 0 01.68-.26 2.39 2.39 0 01.7 0 3 3 0 01.67.11 3.38 3.38 0 01.62.26l.46.25c.16.1.31.18.44.27a2.71 2.71 0 01.44.35.81.81 0 01.2.39.59.59 0 01-.09.4 1.23 1.23 0 01-.46.39 2.2 2.2 0 01-.68.27 2.68 2.68 0 01-.69 0 2.55 2.55 0 01-.67-.12 3.82 3.82 0 01-.62-.25l-.45-.25-.45-.27a2 2 0 01-.44-.36.73.73 0 01-.2-.39.6.6 0 01.09-.4 1.28 1.28 0 01.45-.39zm2.73 1c-.28-.17-.56-.34-.85-.49l-.27-.12a1.22 1.22 0 00-.26-.06.82.82 0 00-.27 0 .93.93 0 00-.26.1.47.47 0 00-.18.15.18.18 0 000 .16.33.33 0 00.1.15 1.27 1.27 0 00.21.15c.26.17.55.34.85.5l.26.11a.9.9 0 00.27.07.76.76 0 00.26 0 .74.74 0 00.27-.1.43.43 0 00.17-.15.16.16 0 000-.15.31.31 0 00-.1-.16z"
            style={{
              WebkitTransformOrigin: 304.575,
              MsTransformOrigin: 304.575,
              transformOrigin: 304.575,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M306.54 419.71a2.71 2.71 0 01.38.22.75.75 0 01.23.19.41.41 0 01.1.21.29.29 0 01-.06.21.55.55 0 01-.24.2 1 1 0 01-.35.14 1.61 1.61 0 01-.37 0l-.36-.06a2.26 2.26 0 01-.33-.13l-.2-.11-.19-.12a.84.84 0 01-.22-.19.39.39 0 01-.11-.21.29.29 0 01.06-.21.71.71 0 01.24-.2 1.34 1.34 0 01.36-.14 1.14 1.14 0 01.37 0 1.34 1.34 0 01.35.05 2 2 0 01.34.15zm-.52.71a1.29 1.29 0 00.25.09.34.34 0 00.2 0c.06 0 .09-.08.07-.12a.41.41 0 00-.15-.14 3.79 3.79 0 00-.34-.2 1 1 0 00-.24-.08.28.28 0 00-.2 0c-.07 0-.09.07-.07.11a.41.41 0 00.15.14 2.63 2.63 0 00.31.2zm1-1.7a.09.09 0 010-.07l.07-.07.36-.21a.18.18 0 01.2 0l1.13 3.16a.12.12 0 010 .08.22.22 0 01-.07.07l-.36.21h-.11a.18.18 0 01-.1 0zm3.31.83a3.36 3.36 0 01.39.23.9.9 0 01.23.19.38.38 0 01.1.2.3.3 0 01-.06.22.55.55 0 01-.24.2 1.17 1.17 0 01-.35.14 1.18 1.18 0 01-.37 0 1.49 1.49 0 01-.36-.05 2.33 2.33 0 01-.33-.14 1.86 1.86 0 01-.2-.1l-.19-.12a1.55 1.55 0 01-.23-.19.5.5 0 01-.1-.21.29.29 0 01.06-.21.71.71 0 01.24-.2 1.07 1.07 0 01.36-.14 1.47 1.47 0 01.36 0 2.25 2.25 0 01.36.06 3.24 3.24 0 01.35.12zm-.51.71a.85.85 0 00.25.09.29.29 0 00.2 0c.06 0 .09-.07.07-.11a.38.38 0 00-.16-.14 2.63 2.63 0 00-.33-.2l-.24-.08a.28.28 0 00-.2 0c-.07 0-.09.08-.07.12a.41.41 0 00.15.14 1.77 1.77 0 00.35.18z"
            style={{
              WebkitTransformOrigin: 307.935,
              MsTransformOrigin: 307.935,
              transformOrigin: 307.935,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M295 419.29l-3.72-1.1a5.56 5.56 0 00-1.28 4.7l4.11-.38a4 4 0 01.89-3.22z"
            style={{
              WebkitTransformOrigin: 292.451,
              MsTransformOrigin: 292.451,
              transformOrigin: 292.451,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M291.75 417.66l3.71 1.09a8 8 0 011.72-1.31 12.69 12.69 0 012.28-1l-1.89-2.14a17.25 17.25 0 00-3.33 1.44 11.28 11.28 0 00-2.49 1.92z"
            style={{
              WebkitTransformOrigin: 295.605,
              MsTransformOrigin: 295.605,
              transformOrigin: 295.605,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M306 415.66l.65-2.37a24.75 24.75 0 00-8.13.74l1.89 2.14a18 18 0 015.59-.51z"
            style={{
              WebkitTransformOrigin: 302.585,
              MsTransformOrigin: 302.585,
              transformOrigin: 302.585,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M294.27 423.11l-4.12.37a8.4 8.4 0 003.74 4.23l2.94-1.69a6.09 6.09 0 01-2.56-2.91z"
            style={{
              WebkitTransformOrigin: 293.49,
              MsTransformOrigin: 293.49,
              transformOrigin: 293.49,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M297.57 426.44l-2.95 1.7a20.47 20.47 0 007.38 2.16l.65-2.38a14.51 14.51 0 01-5.08-1.48z"
            style={{
              WebkitTransformOrigin: 298.635,
              MsTransformOrigin: 298.635,
              transformOrigin: 298.635,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M303.63 428l-.65 2.37a25 25 0 008.13-.74l-1.89-2.14a18.2 18.2 0 01-5.59.51z"
            style={{
              WebkitTransformOrigin: 307.045,
              MsTransformOrigin: 307.045,
              transformOrigin: 307.045,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M312.42 426.24a11.63 11.63 0 01-2.27 1l1.89 2.15a16.59 16.59 0 003.32-1.45 11.33 11.33 0 002.5-1.91l-3.71-1.1a8.37 8.37 0 01-1.73 1.31z"
            style={{
              WebkitTransformOrigin: 314.005,
              MsTransformOrigin: 314.005,
              transformOrigin: 314.005,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M314.62 424.39l3.71 1.09a5.5 5.5 0 001.28-4.69l-4.11.37a4 4 0 01-.88 3.23z"
            style={{
              WebkitTransformOrigin: 317.165,
              MsTransformOrigin: 317.165,
              transformOrigin: 317.165,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M312 417.24l2.94-1.7a20.35 20.35 0 00-7.33-2.16l-.65 2.37a14.73 14.73 0 015.04 1.49z"
            style={{
              WebkitTransformOrigin: 310.95,
              MsTransformOrigin: 310.95,
              transformOrigin: 310.95,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M315.34 420.57l4.12-.38a8.36 8.36 0 00-3.74-4.23l-3 1.7a6 6 0 012.62 2.91z"
            style={{
              WebkitTransformOrigin: 316.09,
              MsTransformOrigin: 316.09,
              transformOrigin: 316.09,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M291.75 417.66l3.71 1.09a8 8 0 011.72-1.31 12.69 12.69 0 012.28-1l-1.89-2.14a17.25 17.25 0 00-3.33 1.44 11.28 11.28 0 00-2.49 1.92z"
            style={{
              WebkitTransformOrigin: 295.605,
              MsTransformOrigin: 295.605,
              transformOrigin: 295.605,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M306 415.66l.65-2.37a24.75 24.75 0 00-8.13.74l1.89 2.14a18 18 0 015.59-.51z"
            style={{
              WebkitTransformOrigin: 302.585,
              MsTransformOrigin: 302.585,
              transformOrigin: 302.585,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <g
            style={{
              WebkitTransformOrigin: 310.95,
              MsTransformOrigin: 310.95,
              transformOrigin: 310.95,
            }}
            className="animable"
            opacity="0.3"
          >
            <path
              d="M312 417.24l2.94-1.7a20.35 20.35 0 00-7.33-2.16l-.65 2.37a14.73 14.73 0 015.04 1.49z"
              style={{
                WebkitTransformOrigin: 310.95,
                MsTransformOrigin: 310.95,
                transformOrigin: 310.95,
              }}
              className="animable"
            ></path>
          </g>
          <path
            d="M338.16 403.52h.55l.21-.09a.44.44 0 00.15-.12.31.31 0 00.06-.13.18.18 0 000-.14.36.36 0 00-.16-.13.74.74 0 00-.4-.1.85.85 0 00-.43.12.36.36 0 00-.09.08l-.06.09v.07h-.05l-.68.4a.19.19 0 01-.13 0 .2.2 0 01-.13 0l-1.68-1.12a.16.16 0 01-.06-.08.09.09 0 010-.07l1.88-1.08a.28.28 0 01.13 0h.13l.54.31a.09.09 0 01.06.08.09.09 0 010 .07l-1.28.74.42.27a.44.44 0 01.1-.15.79.79 0 01.19-.15 2.45 2.45 0 01.55-.23 2.57 2.57 0 01.62-.08 2.68 2.68 0 01.62.07 2.45 2.45 0 01.61.25 1.24 1.24 0 01.43.37.52.52 0 01.13.38.66.66 0 01-.17.37 1.33 1.33 0 01-.42.34 2.56 2.56 0 01-.62.26 3.65 3.65 0 01-.64.09 2.35 2.35 0 01-.6-.06 2.17 2.17 0 01-.49-.17s-.05 0-.05-.07a.05.05 0 010-.05l.56-.33a.43.43 0 01.14 0z"
            style={{
              WebkitTransformOrigin: 337.822,
              MsTransformOrigin: 337.822,
              transformOrigin: 337.822,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M339 400.07a2.27 2.27 0 01.68-.26 2.8 2.8 0 01.7-.06 2.55 2.55 0 01.67.12 3.71 3.71 0 01.61.25l.47.26c.16.09.31.18.44.27a1.93 1.93 0 01.43.35.75.75 0 01.21.39.6.6 0 01-.09.4 1.44 1.44 0 01-.46.39 2 2 0 01-.68.26 2.69 2.69 0 01-.69.06 2.48 2.48 0 01-.67-.12 3.82 3.82 0 01-.62-.25l-.46-.25-.44-.27a2 2 0 01-.44-.36.79.79 0 01-.21-.39.56.56 0 01.1-.4 1.28 1.28 0 01.45-.39zm2.73 1c-.28-.17-.56-.34-.85-.49a1.59 1.59 0 00-.27-.12l-.26-.06a.83.83 0 00-.53.11.47.47 0 00-.18.15.18.18 0 000 .16.33.33 0 00.1.15 1.27 1.27 0 00.21.15 8.61 8.61 0 00.85.49 1.51 1.51 0 00.26.12 1.31 1.31 0 00.27.06.77.77 0 00.26 0 .74.74 0 00.27-.1.43.43 0 00.17-.15.18.18 0 000-.16.36.36 0 00-.11-.15 1.15 1.15 0 00-.16-.13z"
            style={{
              WebkitTransformOrigin: 340.83,
              MsTransformOrigin: 340.83,
              transformOrigin: 340.83,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M342.82 398.74a3.36 3.36 0 01.39.23.9.9 0 01.23.19.35.35 0 01.1.2.3.3 0 01-.06.22.62.62 0 01-.24.2 1.17 1.17 0 01-.35.14 1.61 1.61 0 01-.37 0 2.25 2.25 0 01-.36-.06 2.33 2.33 0 01-.33-.14l-.2-.1-.19-.12a1.11 1.11 0 01-.23-.19.5.5 0 01-.1-.21.29.29 0 01.06-.21.71.71 0 01.24-.2 1.07 1.07 0 01.36-.14 1.47 1.47 0 01.36 0 2.08 2.08 0 01.36.06 1.89 1.89 0 01.33.13zm-.51.72a1.17 1.17 0 00.25.08.29.29 0 00.2 0c.06 0 .09-.07.07-.11a.38.38 0 00-.16-.14 2.63 2.63 0 00-.33-.2l-.24-.08a.24.24 0 00-.2 0c-.07 0-.09.07-.07.11a.41.41 0 00.15.14 2.63 2.63 0 00.33.2zm1-1.7a.11.11 0 010-.08l.07-.06.36-.21a.18.18 0 01.2 0l1.13 3.16a.22.22 0 010 .08.22.22 0 01-.07.07l-.36.2a.2.2 0 01-.11 0h-.1zm3.32.83l.39.22a1.57 1.57 0 01.23.2.35.35 0 01.1.2.32.32 0 01-.06.22.84.84 0 01-.24.2 1.22 1.22 0 01-.36.14 1.1 1.1 0 01-.36 0 1.13 1.13 0 01-.36-.06 1.51 1.51 0 01-.33-.13l-.2-.1-.19-.12a1.55 1.55 0 01-.23-.19.5.5 0 01-.1-.21.29.29 0 01.06-.21.73.73 0 01.24-.21 1.58 1.58 0 01.36-.14 1.46 1.46 0 01.36 0l.36.06a1.89 1.89 0 01.34.13zm-.51.71a.85.85 0 00.25.09.29.29 0 00.2 0c.06 0 .08-.07.06-.11a.51.51 0 00-.15-.15 2.59 2.59 0 00-.33-.19.85.85 0 00-.25-.09.38.38 0 00-.2 0q-.09.06-.06.12s.07.08.15.14a2.59 2.59 0 00.34.19z"
            style={{
              WebkitTransformOrigin: 344.23,
              MsTransformOrigin: 344.23,
              transformOrigin: 344.23,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M331.32 398.3l-3.71-1.1a5.53 5.53 0 00-1.28 4.7l4.11-.38a4 4 0 01.88-3.22z"
            style={{
              WebkitTransformOrigin: 328.775,
              MsTransformOrigin: 328.775,
              transformOrigin: 328.775,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M328.08 396.67l3.71 1.09a8.34 8.34 0 011.73-1.31 12.28 12.28 0 012.27-1l-1.89-2.14a17.15 17.15 0 00-3.32 1.44 11.35 11.35 0 00-2.5 1.92z"
            style={{
              WebkitTransformOrigin: 331.935,
              MsTransformOrigin: 331.935,
              transformOrigin: 331.935,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M342.31 394.67l.65-2.37a24.73 24.73 0 00-8.13.74l1.89 2.14a18 18 0 015.59-.51z"
            style={{
              WebkitTransformOrigin: 338.895,
              MsTransformOrigin: 338.895,
              transformOrigin: 338.895,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M330.6 402.12l-4.12.37a8.42 8.42 0 003.75 4.23l2.94-1.69a6.12 6.12 0 01-2.57-2.91z"
            style={{
              WebkitTransformOrigin: 329.825,
              MsTransformOrigin: 329.825,
              transformOrigin: 329.825,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M333.91 405.45l-3 1.7a20.35 20.35 0 007.33 2.16l.65-2.38a14.39 14.39 0 01-4.98-1.48z"
            style={{
              WebkitTransformOrigin: 334.9,
              MsTransformOrigin: 334.9,
              transformOrigin: 334.9,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M340 407l-.65 2.37a25 25 0 008.13-.74l-1.89-2.15a18.26 18.26 0 01-5.59.52z"
            style={{
              WebkitTransformOrigin: 343.415,
              MsTransformOrigin: 343.415,
              transformOrigin: 343.415,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M348.76 405.25a12.36 12.36 0 01-2.28 1l1.89 2.15a16.43 16.43 0 003.33-1.45 11.33 11.33 0 002.5-1.91l-3.72-1.1a8 8 0 01-1.72 1.31z"
            style={{
              WebkitTransformOrigin: 350.34,
              MsTransformOrigin: 350.34,
              transformOrigin: 350.34,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M351 403.4l3.72 1.09a5.53 5.53 0 001.28-4.69l-4.11.37a4 4 0 01-.89 3.23z"
            style={{
              WebkitTransformOrigin: 353.549,
              MsTransformOrigin: 353.549,
              transformOrigin: 353.549,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M348.37 396.25l2.95-1.7a20.47 20.47 0 00-7.33-2.16l-.66 2.37a14.67 14.67 0 015.04 1.49z"
            style={{
              WebkitTransformOrigin: 347.325,
              MsTransformOrigin: 347.325,
              transformOrigin: 347.325,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M351.68 399.58l4.11-.38a8.27 8.27 0 00-3.74-4.23l-2.94 1.7a6.06 6.06 0 012.57 2.91z"
            style={{
              WebkitTransformOrigin: 352.45,
              MsTransformOrigin: 352.45,
              transformOrigin: 352.45,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M328.08 396.67l3.71 1.09a8.34 8.34 0 011.73-1.31 12.28 12.28 0 012.27-1l-1.89-2.14a17.15 17.15 0 00-3.32 1.44 11.35 11.35 0 00-2.5 1.92z"
            style={{
              WebkitTransformOrigin: 331.935,
              MsTransformOrigin: 331.935,
              transformOrigin: 331.935,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M342.31 394.67l.65-2.37a24.73 24.73 0 00-8.13.74l1.89 2.14a18 18 0 015.59-.51z"
            style={{
              WebkitTransformOrigin: 338.895,
              MsTransformOrigin: 338.895,
              transformOrigin: 338.895,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M351 403.4l3.72 1.09a5.53 5.53 0 001.28-4.69l-4.11.37a4 4 0 01-.89 3.23z"
            style={{
              WebkitTransformOrigin: 353.549,
              MsTransformOrigin: 353.549,
              transformOrigin: 353.549,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <g
            style={{
              WebkitTransformOrigin: 347.325,
              MsTransformOrigin: 347.325,
              transformOrigin: 347.325,
            }}
            className="animable"
            opacity="0.3"
          >
            <path
              d="M348.37 396.25l2.95-1.7a20.47 20.47 0 00-7.33-2.16l-.66 2.37a14.67 14.67 0 015.04 1.49z"
              style={{
                WebkitTransformOrigin: 347.325,
                MsTransformOrigin: 347.325,
                transformOrigin: 347.325,
              }}
              className="animable"
            ></path>
          </g>
          <path
            d="M351.68 399.58l4.11-.38a8.27 8.27 0 00-3.74-4.23l-2.94 1.7a6.06 6.06 0 012.57 2.91z"
            style={{
              WebkitTransformOrigin: 352.45,
              MsTransformOrigin: 352.45,
              transformOrigin: 352.45,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M375.2 383.2h-.05l-1.61-2-1.31.76a.16.16 0 01-.12 0 .32.32 0 01-.14 0l-.53-.31a.13.13 0 01-.07-.08s0-.05.05-.07l2.1-1.21a.21.21 0 01.12 0 .33.33 0 01.14 0l.47.27a.82.82 0 01.19.19l1.61 2v.08a.08.08 0 010 .07l-.61.35a.14.14 0 01-.11 0 .21.21 0 01-.13-.05z"
            style={{
              WebkitTransformOrigin: 373.714,
              MsTransformOrigin: 373.714,
              transformOrigin: 373.714,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M375.22 379.16a2.57 2.57 0 01.68-.26 2.39 2.39 0 01.7 0 2.55 2.55 0 01.67.12 3.35 3.35 0 01.62.25l.46.26.44.26a2.25 2.25 0 01.43.35.75.75 0 01.21.39.58.58 0 01-.09.4 1.33 1.33 0 01-.46.4 2.45 2.45 0 01-.68.26 2.68 2.68 0 01-.69 0 2.48 2.48 0 01-.67-.12 3.09 3.09 0 01-.62-.25l-.45-.25c-.17-.09-.31-.18-.45-.27a2 2 0 01-.44-.36.74.74 0 01-.2-.38.53.53 0 01.09-.4 1.2 1.2 0 01.45-.4zm2.73 1l-.85-.49-.27-.11a.92.92 0 00-.26-.07 1.22 1.22 0 00-.27 0 .93.93 0 00-.26.1.41.41 0 00-.18.16.16.16 0 000 .15.33.33 0 00.1.15l.21.16c.26.17.55.33.85.49l.26.12.27.06a1.15 1.15 0 00.26 0 1 1 0 00.27-.1.44.44 0 00.17-.16.16.16 0 000-.15.29.29 0 00-.11-.15 1.16 1.16 0 00-.14-.13z"
            style={{
              WebkitTransformOrigin: 377.054,
              MsTransformOrigin: 377.054,
              transformOrigin: 377.054,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M379 377.84a2.71 2.71 0 01.38.22 1.16 1.16 0 01.23.19.41.41 0 01.1.21.29.29 0 01-.06.21.64.64 0 01-.24.21 1.51 1.51 0 01-.35.14h-.37l-.36-.06a2.26 2.26 0 01-.33-.13l-.2-.11-.19-.11a1.57 1.57 0 01-.23-.2.42.42 0 01-.1-.2.32.32 0 01.06-.22.84.84 0 01.24-.2 1.34 1.34 0 01.36-.14 1.47 1.47 0 01.72 0 1.4 1.4 0 01.34.19zm-.52.71a.85.85 0 00.25.09.29.29 0 00.2 0c.06 0 .09-.08.07-.12a.38.38 0 00-.16-.14 2.59 2.59 0 00-.33-.19.76.76 0 00-.25-.09.27.27 0 00-.19 0c-.07 0-.09.07-.07.11a.51.51 0 00.15.15 2.59 2.59 0 00.35.19zm1-1.69a.11.11 0 010-.08l.07-.07.36-.21a.18.18 0 01.2 0l1.13 3.16a.25.25 0 010 .08.15.15 0 01-.07.07l-.36.21a.2.2 0 01-.11 0 .19.19 0 01-.1 0zm3.32.83a2.36 2.36 0 01.39.22 1.11 1.11 0 01.23.19.37.37 0 01.1.21.29.29 0 01-.06.21.71.71 0 01-.24.2 1 1 0 01-.35.14 1.61 1.61 0 01-.37 0 2.25 2.25 0 01-.36-.06 3.24 3.24 0 01-.33-.13l-.2-.11-.19-.12a1.11 1.11 0 01-.23-.19.5.5 0 01-.1-.21.29.29 0 01.06-.21.71.71 0 01.24-.2 1 1 0 01.36-.14 1.1 1.1 0 01.36 0 1.49 1.49 0 01.36.05 1.92 1.92 0 01.36.15zm-.51.71a1.17 1.17 0 00.25.08.28.28 0 00.2 0c.06 0 .08-.08.07-.12a.38.38 0 00-.16-.14 1.77 1.77 0 00-.33-.19.85.85 0 00-.25-.09.3.3 0 00-.2 0c-.06 0-.08.07-.06.11s.07.09.15.14a2.63 2.63 0 00.36.21z"
            style={{
              WebkitTransformOrigin: 380.4,
              MsTransformOrigin: 380.4,
              transformOrigin: 380.4,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M367.66 377.31l-3.72-1.1a5.56 5.56 0 00-1.28 4.7l4.11-.38a4 4 0 01.89-3.22z"
            style={{
              WebkitTransformOrigin: 365.111,
              MsTransformOrigin: 365.111,
              transformOrigin: 365.111,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M364.42 375.68l3.71 1.09a8.08 8.08 0 011.73-1.31 12.28 12.28 0 012.27-1l-1.89-2.14a17.15 17.15 0 00-3.32 1.44 11.35 11.35 0 00-2.5 1.92z"
            style={{
              WebkitTransformOrigin: 368.275,
              MsTransformOrigin: 368.275,
              transformOrigin: 368.275,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M378.64 373.68l.65-2.37a24.75 24.75 0 00-8.13.74l1.9 2.14a17.86 17.86 0 015.58-.51z"
            style={{
              WebkitTransformOrigin: 375.225,
              MsTransformOrigin: 375.225,
              transformOrigin: 375.225,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M366.94 381.13l-4.12.37a8.44 8.44 0 003.74 4.23l2.94-1.73a6.09 6.09 0 01-2.56-2.87z"
            style={{
              WebkitTransformOrigin: 366.16,
              MsTransformOrigin: 366.16,
              transformOrigin: 366.16,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M370.24 384.46l-2.95 1.7a20.47 20.47 0 007.34 2.16l.65-2.38a14.51 14.51 0 01-5.04-1.48z"
            style={{
              WebkitTransformOrigin: 371.285,
              MsTransformOrigin: 371.285,
              transformOrigin: 371.285,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M376.31 386l-.65 2.37a25 25 0 008.13-.74l-1.9-2.15a18.17 18.17 0 01-5.58.52z"
            style={{
              WebkitTransformOrigin: 379.725,
              MsTransformOrigin: 379.725,
              transformOrigin: 379.725,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M385.09 384.26a12.26 12.26 0 01-2.27 1l1.89 2.15A16.59 16.59 0 00388 386a11.33 11.33 0 002.5-1.91l-3.71-1.1a8.11 8.11 0 01-1.7 1.27z"
            style={{
              WebkitTransformOrigin: 386.66,
              MsTransformOrigin: 386.66,
              transformOrigin: 386.66,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M387.29 382.41l3.71 1.09a5.49 5.49 0 001.27-4.69l-4.1.37a4 4 0 01-.88 3.23z"
            style={{
              WebkitTransformOrigin: 389.832,
              MsTransformOrigin: 389.832,
              transformOrigin: 389.832,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M384.71 375.26l3-1.7a20.47 20.47 0 00-7.34-2.16l-.65 2.37a14.73 14.73 0 014.99 1.49z"
            style={{
              WebkitTransformOrigin: 383.715,
              MsTransformOrigin: 383.715,
              transformOrigin: 383.715,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <path
            d="M388 378.59l4.12-.38a8.31 8.31 0 00-3.74-4.23l-2.95 1.7a5.92 5.92 0 012.57 2.91z"
            style={{
              WebkitTransformOrigin: 388.775,
              MsTransformOrigin: 388.775,
              transformOrigin: 388.775,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <g
            style={{
              WebkitTransformOrigin: 378.397,
              MsTransformOrigin: 378.397,
              transformOrigin: 378.397,
            }}
            className="animable"
            opacity="0.3"
          >
            <path
              d="M364.42 375.68l3.71 1.09a8.08 8.08 0 011.73-1.31 12.28 12.28 0 012.27-1l-1.89-2.14a17.15 17.15 0 00-3.32 1.44 11.35 11.35 0 00-2.5 1.92z"
              style={{
                WebkitTransformOrigin: 368.275,
                MsTransformOrigin: 368.275,
                transformOrigin: 368.275,
              }}
              className="animable"
            ></path>
            <path
              d="M378.64 373.68l.65-2.37a24.75 24.75 0 00-8.13.74l1.9 2.14a17.86 17.86 0 015.58-.51z"
              style={{
                WebkitTransformOrigin: 375.225,
                MsTransformOrigin: 375.225,
                transformOrigin: 375.225,
              }}
              className="animable"
            ></path>
            <path
              d="M376.31 386l-.65 2.37a25 25 0 008.13-.74l-1.9-2.15a18.17 18.17 0 01-5.58.52z"
              style={{
                WebkitTransformOrigin: 379.725,
                MsTransformOrigin: 379.725,
                transformOrigin: 379.725,
              }}
              className="animable"
            ></path>
            <path
              d="M385.09 384.26a12.26 12.26 0 01-2.27 1l1.89 2.15A16.59 16.59 0 00388 386a11.33 11.33 0 002.5-1.91l-3.71-1.1a8.11 8.11 0 01-1.7 1.27z"
              style={{
                WebkitTransformOrigin: 386.66,
                MsTransformOrigin: 386.66,
                transformOrigin: 386.66,
              }}
              className="animable"
            ></path>
            <path
              d="M387.29 382.41l3.71 1.09a5.49 5.49 0 001.27-4.69l-4.1.37a4 4 0 01-.88 3.23z"
              style={{
                WebkitTransformOrigin: 389.832,
                MsTransformOrigin: 389.832,
                transformOrigin: 389.832,
              }}
              className="animable"
            ></path>
            <path
              d="M384.71 375.26l3-1.7a20.47 20.47 0 00-7.34-2.16l-.65 2.37a14.73 14.73 0 014.99 1.49z"
              style={{
                WebkitTransformOrigin: 383.715,
                MsTransformOrigin: 383.715,
                transformOrigin: 383.715,
              }}
              className="animable"
            ></path>
            <path
              d="M388 378.59l4.12-.38a8.31 8.31 0 00-3.74-4.23l-2.95 1.7a5.92 5.92 0 012.57 2.91z"
              style={{
                WebkitTransformOrigin: 388.775,
                MsTransformOrigin: 388.775,
                transformOrigin: 388.775,
              }}
              className="animable"
            ></path>
          </g>
          <g
            style={{
              WebkitTransformOrigin: 280.224,
              MsTransformOrigin: 280.224,
              transformOrigin: 280.224,
            }}
            className="animable"
            opacity="0.3"
          >
            <path
              d="M222 192.2a.51.51 0 01-.44-.25.5.5 0 01.19-.68l32.49-18.76a.5.5 0 11.5.86l-32.49 18.77a.57.57 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 238.265,
                MsTransformOrigin: 238.265,
                transformOrigin: 238.265,
              }}
              className="animable"
            ></path>
            <path
              d="M222 196.89a.51.51 0 01-.44-.25.5.5 0 01.19-.68l32.49-18.76a.5.5 0 11.5.86l-32.49 18.77a.57.57 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 238.265,
                MsTransformOrigin: 238.265,
                transformOrigin: 238.265,
              }}
              className="animable"
            ></path>
            <path
              d="M222 201.58a.51.51 0 01-.44-.25.5.5 0 01.19-.68l32.49-18.76a.5.5 0 11.5.86l-32.49 18.77a.57.57 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 238.265,
                MsTransformOrigin: 238.265,
                transformOrigin: 238.265,
              }}
              className="animable"
            ></path>
            <path
              d="M222 206.27a.51.51 0 01-.44-.25.5.5 0 01.19-.68l32.49-18.76a.5.5 0 11.5.86l-32.49 18.77a.57.57 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 238.265,
                MsTransformOrigin: 238.265,
                transformOrigin: 238.265,
              }}
              className="animable"
            ></path>
            <path
              d="M222 211a.51.51 0 01-.44-.25.5.5 0 01.19-.68l32.49-18.76a.49.49 0 01.68.18.51.51 0 01-.18.69l-32.5 18.72a.57.57 0 01-.24.1z"
              style={{
                WebkitTransformOrigin: 238.242,
                MsTransformOrigin: 238.242,
                transformOrigin: 238.242,
              }}
              className="animable"
            ></path>
            <path
              d="M222 215.65a.51.51 0 01-.44-.25.5.5 0 01.19-.68L254.23 196a.49.49 0 01.68.18.51.51 0 01-.18.69l-32.49 18.76a.57.57 0 01-.24.02z"
              style={{
                WebkitTransformOrigin: 238.237,
                MsTransformOrigin: 238.237,
                transformOrigin: 238.237,
              }}
              className="animable"
            ></path>
            <path
              d="M222 220.34a.49.49 0 01-.44-.25.5.5 0 01.19-.68l32.49-18.76a.49.49 0 01.68.18.51.51 0 01-.18.69l-32.49 18.76a.57.57 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 238.242,
                MsTransformOrigin: 238.242,
                transformOrigin: 238.242,
              }}
              className="animable"
            ></path>
            <path
              d="M222 135.91a.51.51 0 01-.44-.25.5.5 0 01.19-.68l32.49-18.76a.49.49 0 01.68.18.51.51 0 01-.18.69l-32.49 18.76a.57.57 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 238.242,
                MsTransformOrigin: 238.242,
                transformOrigin: 238.242,
              }}
              className="animable"
            ></path>
            <path
              d="M222 140.6a.49.49 0 01-.44-.25.5.5 0 01.19-.68l32.49-18.76a.49.49 0 01.68.18.51.51 0 01-.18.69l-32.49 18.76a.57.57 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 238.242,
                MsTransformOrigin: 238.242,
                transformOrigin: 238.242,
              }}
              className="animable"
            ></path>
            <path
              d="M222 145.3a.5.5 0 01-.25-.94l32.49-18.76a.49.49 0 01.68.18.51.51 0 01-.18.69l-32.49 18.76a.59.59 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 238.237,
                MsTransformOrigin: 238.237,
                transformOrigin: 238.237,
              }}
              className="animable"
            ></path>
            <path
              d="M222 150a.5.5 0 01-.25-.94l32.49-18.76a.49.49 0 01.68.18.51.51 0 01-.18.69l-32.49 18.76a.59.59 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 238.237,
                MsTransformOrigin: 238.237,
                transformOrigin: 238.237,
              }}
              className="animable"
            ></path>
            <path
              d="M222 154.68a.5.5 0 01-.25-.94L254.23 135a.5.5 0 01.5.87l-32.49 18.76a.47.47 0 01-.24.05z"
              style={{
                WebkitTransformOrigin: 238.234,
                MsTransformOrigin: 238.234,
                transformOrigin: 238.234,
              }}
              className="animable"
            ></path>
            <path
              d="M222 159.37a.5.5 0 01-.25-.94l32.49-18.76a.49.49 0 01.68.19.5.5 0 01-.18.68l-32.5 18.76a.47.47 0 01-.24.07z"
              style={{
                WebkitTransformOrigin: 238.237,
                MsTransformOrigin: 238.237,
                transformOrigin: 238.237,
              }}
              className="animable"
            ></path>
            <path
              d="M222 164.06a.51.51 0 01-.44-.25.49.49 0 01.19-.68l32.49-18.77a.5.5 0 01.68.19.49.49 0 01-.18.68L222.24 164a.47.47 0 01-.24.06z"
              style={{
                WebkitTransformOrigin: 238.241,
                MsTransformOrigin: 238.241,
                transformOrigin: 238.241,
              }}
              className="animable"
            ></path>
            <path
              d="M222 168.75a.51.51 0 01-.44-.25.49.49 0 01.19-.68l32.49-18.77a.5.5 0 01.68.19.49.49 0 01-.18.68l-32.49 18.76a.47.47 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 238.241,
                MsTransformOrigin: 238.241,
                transformOrigin: 238.241,
              }}
              className="animable"
            ></path>
            <path
              d="M222 173.44a.51.51 0 01-.44-.25.49.49 0 01.19-.68l32.49-18.77a.5.5 0 01.68.19.49.49 0 01-.18.68l-32.49 18.76a.47.47 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 238.241,
                MsTransformOrigin: 238.241,
                transformOrigin: 238.241,
              }}
              className="animable"
            ></path>
            <path
              d="M222 178.13a.51.51 0 01-.44-.25.5.5 0 01.19-.68l32.49-18.76a.5.5 0 11.5.86l-32.49 18.76a.47.47 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 238.265,
                MsTransformOrigin: 238.265,
                transformOrigin: 238.265,
              }}
              className="animable"
            ></path>
            <path
              d="M222 182.82a.51.51 0 01-.44-.25.5.5 0 01.19-.68L238 172.5a.52.52 0 01.69.19.5.5 0 01-.19.68l-16.24 9.38a.47.47 0 01-.26.07z"
              style={{
                WebkitTransformOrigin: 230.125,
                MsTransformOrigin: 230.125,
                transformOrigin: 230.125,
              }}
              className="animable"
            ></path>
            <path
              d="M147.7 290.58a.5.5 0 01-.43-.25.5.5 0 01.18-.68l106.38-61.41a.5.5 0 11.5.86L148 290.52a.51.51 0 01-.3.06z"
              style={{
                WebkitTransformOrigin: 200.914,
                MsTransformOrigin: 200.914,
                transformOrigin: 200.914,
              }}
              className="animable"
            ></path>
            <path
              d="M149.21 296.49a.5.5 0 01-.25-.93l106.38-61.42a.49.49 0 01.68.18.51.51 0 01-.18.69l-106.38 61.41a.45.45 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 202.401,
                MsTransformOrigin: 202.401,
                transformOrigin: 202.401,
              }}
              className="animable"
            ></path>
            <path
              d="M151 302.28a.51.51 0 01-.44-.25.49.49 0 01.19-.68l106.35-61.42a.51.51 0 01.69.18.52.52 0 01-.19.69l-106.37 61.41a.47.47 0 01-.23.07z"
              style={{
                WebkitTransformOrigin: 204.172,
                MsTransformOrigin: 204.172,
                transformOrigin: 204.172,
              }}
              className="animable"
            ></path>
            <path
              d="M152.85 308.05a.5.5 0 01-.44-.25.51.51 0 01.19-.69L259 245.7a.5.5 0 01.5.86L153.1 308a.47.47 0 01-.25.05z"
              style={{
                WebkitTransformOrigin: 206.026,
                MsTransformOrigin: 206.026,
                transformOrigin: 206.026,
              }}
              className="animable"
            ></path>
            <path
              d="M155 313.68a.5.5 0 01-.43-.25.51.51 0 01.18-.69l106.38-61.41a.5.5 0 01.5.86l-106.37 61.42a.55.55 0 01-.26.07z"
              style={{
                WebkitTransformOrigin: 208.169,
                MsTransformOrigin: 208.169,
                transformOrigin: 208.169,
              }}
              className="animable"
            ></path>
            <path
              d="M190 369.53a.5.5 0 01-.37-.17c-3.65-4.15-11.53-13.4-15.24-18.75-7.94-11.85-13.69-22.21-17.58-31.67a.51.51 0 01.28-.66.5.5 0 01.65.28c3.86 9.4 9.58 19.7 17.47 31.49 3.69 5.3 11.53 14.52 15.17 18.65a.49.49 0 01-.05.7.46.46 0 01-.33.13z"
              style={{
                WebkitTransformOrigin: 173.639,
                MsTransformOrigin: 173.639,
                transformOrigin: 173.639,
              }}
              className="animable"
            ></path>
            <path
              d="M258.51 277.94a.52.52 0 01-.44-.25.52.52 0 01.19-.69l11.55-6.67a.5.5 0 01.5.87l-11.55 6.67a.47.47 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 264.285,
                MsTransformOrigin: 264.285,
                transformOrigin: 264.285,
              }}
              className="animable"
            ></path>
            <path
              d="M254.52 287.3a.5.5 0 01-.25-.93l18.79-10.86a.51.51 0 01.69.19.5.5 0 01-.19.68l-18.79 10.86a.51.51 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 263.92,
                MsTransformOrigin: 263.92,
                transformOrigin: 263.92,
              }}
              className="animable"
            ></path>
            <path
              d="M271.86 298.24a.51.51 0 01-.44-.25.52.52 0 01.19-.69l11.5-6.62a.5.5 0 11.5.86l-11.5 6.63a.47.47 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 277.631,
                MsTransformOrigin: 277.631,
                transformOrigin: 277.631,
              }}
              className="animable"
            ></path>
            <path
              d="M268.41 307.27a.5.5 0 01-.26-.93l18.54-11a.51.51 0 01.69.18.49.49 0 01-.18.68l-18.54 11a.45.45 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 277.678,
                MsTransformOrigin: 277.678,
                transformOrigin: 277.678,
              }}
              className="animable"
            ></path>
            <path
              d="M194.22 373.85a.5.5 0 01-.25-.93l107-61.78a.51.51 0 01.68.19.49.49 0 01-.18.68l-107 61.77a.47.47 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 247.723,
                MsTransformOrigin: 247.723,
                transformOrigin: 247.723,
              }}
              className="animable"
            ></path>
            <path
              d="M198.7 378a.5.5 0 01-.25-.93l107-61.77a.5.5 0 11.5.86L199 377.93a.57.57 0 01-.3.07z"
              style={{
                WebkitTransformOrigin: 252.225,
                MsTransformOrigin: 252.225,
                transformOrigin: 252.225,
              }}
              className="animable"
            ></path>
            <path
              d="M203 381.77a.51.51 0 01-.44-.25.52.52 0 01.19-.69l107-61.77a.5.5 0 11.5.87l-107 61.77a.47.47 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 256.5,
                MsTransformOrigin: 256.5,
                transformOrigin: 256.5,
              }}
              className="animable"
            ></path>
            <path
              d="M208.12 385.9a.5.5 0 01-.25-.93l107-61.77a.5.5 0 01.68.18.5.5 0 01-.18.68l-107 61.78a.57.57 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 261.621,
                MsTransformOrigin: 261.621,
                transformOrigin: 261.621,
              }}
              className="animable"
            ></path>
            <path
              d="M311.15 436.22a.5.5 0 01-.25-.93l20.31-11.73a.5.5 0 01.5.87l-20.31 11.72a.47.47 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 321.308,
                MsTransformOrigin: 321.308,
                transformOrigin: 321.308,
              }}
              className="animable"
            ></path>
            <path
              d="M315.21 438.56a.5.5 0 01-.25-.93l20.31-11.72a.5.5 0 01.5.86l-20.31 11.73a.54.54 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 325.346,
                MsTransformOrigin: 325.346,
                transformOrigin: 325.346,
              }}
              className="animable"
            ></path>
            <path
              d="M319.27 440.91a.5.5 0 01-.27-.91l20.31-11.73a.5.5 0 11.5.87l-20.31 11.72a.45.45 0 01-.23.05z"
              style={{
                WebkitTransformOrigin: 329.424,
                MsTransformOrigin: 329.424,
                transformOrigin: 329.424,
              }}
              className="animable"
            ></path>
            <path
              d="M347.71 415.11a.5.5 0 01-.25-.93l20.31-11.72a.5.5 0 11.5.86L348 415.05a.57.57 0 01-.29.06z"
              style={{
                WebkitTransformOrigin: 357.89,
                MsTransformOrigin: 357.89,
                transformOrigin: 357.89,
              }}
              className="animable"
            ></path>
            <path
              d="M351.77 417.46a.5.5 0 01-.25-.93l20.31-11.73a.49.49 0 01.68.18.51.51 0 01-.18.69L352 417.39a.47.47 0 01-.23.07z"
              style={{
                WebkitTransformOrigin: 361.926,
                MsTransformOrigin: 361.926,
                transformOrigin: 361.926,
              }}
              className="animable"
            ></path>
            <path
              d="M355.83 419.8a.5.5 0 01-.25-.93l20.31-11.73a.5.5 0 01.5.87l-20.31 11.73a.54.54 0 01-.25.06z"
              style={{
                WebkitTransformOrigin: 365.988,
                MsTransformOrigin: 365.988,
                transformOrigin: 365.988,
              }}
              className="animable"
            ></path>
            <path
              d="M384.26 394a.5.5 0 01-.25-.93l20.31-11.73a.51.51 0 01.69.18.49.49 0 01-.19.68l-20.31 11.73a.45.45 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 394.421,
                MsTransformOrigin: 394.421,
                transformOrigin: 394.421,
              }}
              className="animable"
            ></path>
            <path
              d="M388.33 396.35a.5.5 0 01-.25-.93l20.31-11.73a.5.5 0 01.5.87l-20.31 11.72a.47.47 0 01-.25.07z"
              style={{
                WebkitTransformOrigin: 398.488,
                MsTransformOrigin: 398.488,
                transformOrigin: 398.488,
              }}
              className="animable"
            ></path>
            <path
              d="M392.39 398.7a.5.5 0 01-.43-.25.51.51 0 01.18-.69L412.45 386a.5.5 0 11.5.86l-20.31 11.73a.47.47 0 01-.25.11z"
              style={{
                WebkitTransformOrigin: 402.568,
                MsTransformOrigin: 402.568,
                transformOrigin: 402.568,
              }}
              className="animable"
            ></path>
          </g>
        </g>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 377.467,
          MsTransformOrigin: 377.467,
          transformOrigin: 377.467,
        }}
        className="animable"
      >
        <g
          style={{
            WebkitTransformOrigin: 377.467,
            MsTransformOrigin: 377.467,
            transformOrigin: 377.467,
          }}
          className="animable"
        >
          <path
            d="M373.25 220c-1.8 3.2-13.35 13.36-14.4 14.52s-2.28 2.22-3.32 3.19c-1.24 1.16-2.22 2.48-3.86 3.14a1.78 1.78 0 01-1.46.06c-.23-.12-.4-.32-.62-.44s-.8-.19-1.11-.43-.47-1-.92-1.35a3.87 3.87 0 00-.67-.26c-.41-.18-.67-.85-.84-1.24-.09-.21-.17-.68-.49-.64a19.52 19.52 0 00-2.46.77c-1.87.61-3.08.74-3.35-.17-.39-1.27 1.08-1.59 3.34-2.55s7-2.94 6.79-3.46-1.78-1.43-2.41-2.59-.42-2.63.43-2c.48.38 2.34 1.32 3.16 1.79s2.3 1.36 3.31 1 11.18-13 11.18-13 8.12-17.39 10.14-20.95 3.64-5.59 7-5.71l.42 6.42-.87 7.24s-7.54 14.06-8.99 16.66z"
            style={{
              WebkitTransformOrigin: 361.399,
              MsTransformOrigin: 361.399,
              transformOrigin: 361.399,
            }}
            fill="#FFA8A7"
            className="animable"
          ></path>
          <path
            d="M383.76 189.55s-3.29-.83-5.4 1.47-3 4.07-4.78 7.76-6.65 14.22-6.65 14.22a9.33 9.33 0 008.42 3.82l8.89-16.82z"
            style={{
              WebkitTransformOrigin: 375.585,
              MsTransformOrigin: 375.585,
              transformOrigin: 375.585,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M377.32 205.72a31 31 0 01.61 7.81c-.23 3.44-2.68 9.75-2.68 9.75s1.93 1.75 7.17.93c4.27-.67 2.76-13.54.31-16a8.09 8.09 0 00-5.41-2.49z"
            style={{
              WebkitTransformOrigin: 380.182,
              MsTransformOrigin: 380.182,
              transformOrigin: 380.182,
            }}
            fill="#37474F"
            className="animable"
          ></path>
          <path
            d="M406.86 315.56a30.82 30.82 0 01.49 5.15c.06 3.12.64 4.23.71 6.79.09 2.93-.42 4-1.61 5.68s-4.3 3.14-5.62.35-1.15-4.6-.73-7.48.49-4.61.74-6.87c.23-2 .49-3.52.49-3.52z"
            style={{
              WebkitTransformOrigin: 403.933,
              MsTransformOrigin: 403.933,
              transformOrigin: 403.933,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M398 291l10.29-.19c-.16 6.87-1.25 18.31-1.43 24.75 0 1.21 0 9.43-.42 11.92-.69 3.75-5.14 3.25-5.38 1s.29-11.83.27-12.82c0-4.13-1.9-14.92-2.91-22.34-.05-.32-.42-1.97-.42-2.32z"
            style={{
              WebkitTransformOrigin: 403.145,
              MsTransformOrigin: 403.145,
              transformOrigin: 403.145,
            }}
            fill="#FFA8A7"
            className="animable"
          ></path>
          <path
            d="M382.87 310.22s.53 0 .62.46.12 1.33.33 2.18a12.19 12.19 0 01.37 4.07c-.18 1-2.37 2.39-3.86 2.64a11.61 11.61 0 00-4.79 2.84 11.47 11.47 0 01-6.65 2.61c-2.5 0-4.24-.83-5.07-2.17s.75-1.5 3.61-3.35l2.22-1.44c4.52-3 6.18-4.54 7.46-7.5z"
            style={{
              WebkitTransformOrigin: 373.913,
              MsTransformOrigin: 373.913,
              transformOrigin: 373.913,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M377.08 308.61c.07-2.13-1-17.8-1-17.8l8.85 1c-2.09 12.49-2.26 16.74-2 18.56a1.66 1.66 0 01.06.52c0 2.43-2.75 3.74-4.89 5.36a19.49 19.49 0 01-5.55 3.47c-1.65.56-4.69.77-5.08-.23a67.81 67.81 0 008.21-6.84c.95-1.08 1.32-1.92 1.4-4.04z"
            style={{
              WebkitTransformOrigin: 376.2,
              MsTransformOrigin: 376.2,
              transformOrigin: 376.2,
            }}
            fill="#FFA8A7"
            className="animable"
          ></path>
          <path
            d="M400.92 216.85a37.35 37.35 0 016.47 13.34c2.92 11.8-1.67 37-1.67 37 .15 1.71 2.11 5.08 2.84 12.61.86 8.9-1.59 32.73-1.59 32.73a5.7 5.7 0 01-5.77.12s-6.23-35-7.34-43.86c-1-7.72-3-23.76-3-23.76l-4.46 23.72s.62 4.33.94 7.62c.51 5.21-4.56 31.33-4.56 31.33a7.17 7.17 0 01-5.74-.11s-2.25-33.75-2.72-40c-.48-6.45 1.19-21.49 2.43-33.47.37-3.6 2.2-14.74 2.84-17.83z"
            style={{
              WebkitTransformOrigin: 391.489,
              MsTransformOrigin: 391.489,
              transformOrigin: 391.489,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M390.9 245.07l-1.06-7.31a18.17 18.17 0 01-8.6-2.78 11 11 0 007.2 4l.67 6.54-2.11 20.4z"
            style={{
              WebkitTransformOrigin: 386.07,
              MsTransformOrigin: 386.07,
              transformOrigin: 386.07,
            }}
            fill="#263238"
            className="animable"
            opacity="0.6"
          ></path>
          <path
            d="M379.1 209.05c.11 2.15.53 7.26.53 7.26 8.76 5.93 21.29.54 21.29.54l1.82-12.46s-4.57-6.4-.28-13.15l-6.85-1.35-8.17-.67-4.75.43c-2.68 2-4.46 6-5.32 8.16a10.35 10.35 0 001.73 11.24z"
            style={{
              WebkitTransformOrigin: 389.586,
              MsTransformOrigin: 389.586,
              transformOrigin: 389.586,
            }}
            fill="#1DD21D"
            className="animable"
          ></path>
          <g
            style={{
              WebkitTransformOrigin: 389.586,
              MsTransformOrigin: 389.586,
              transformOrigin: 389.586,
            }}
            className="animable"
            opacity="0.6"
          >
            <path
              d="M379.1 209.05c.11 2.15.53 7.26.53 7.26 8.76 5.93 21.29.54 21.29.54l1.82-12.46s-4.57-6.4-.28-13.15l-6.85-1.35-8.17-.67-4.75.43c-2.68 2-4.46 6-5.32 8.16a10.35 10.35 0 001.73 11.24z"
              style={{
                WebkitTransformOrigin: 389.586,
                MsTransformOrigin: 389.586,
                transformOrigin: 389.586,
              }}
              fill="#FFF"
              className="animable"
            ></path>
          </g>
          <path
            d="M396.3 189.67s7.51.65 9 1.35 3.92 4.94 1.73 11.11-4.19 13.39-4 14.85 5.46 9 5.46 9a14.26 14.26 0 01-4.82 2.31l-2.67-5.88.63 6.23a31.93 31.93 0 01-8.28.87s-2.53-23.89 2.95-39.84z"
            style={{
              WebkitTransformOrigin: 400.628,
              MsTransformOrigin: 400.628,
              transformOrigin: 400.628,
            }}
            fill="#37474F"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 377.525,
              MsTransformOrigin: 377.525,
              transformOrigin: 377.525,
            }}
            fill="#1DD21D"
            d="M360.79 215.07L382.98 216.57 394.26 241.58 373.06 239.62 360.79 215.07z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 377.525,
              MsTransformOrigin: 377.525,
              transformOrigin: 377.525,
            }}
            d="M360.79 215.07L382.98 216.57 394.26 241.58 373.06 239.62 360.79 215.07z"
            className="animable"
            opacity="0.1"
          ></path>
          <path
            d="M381.33 232.38a5 5 0 004 3.17 11.15 11.15 0 005.77-1.08l-.95-2.09z"
            style={{
              WebkitTransformOrigin: 386.215,
              MsTransformOrigin: 386.215,
              transformOrigin: 386.215,
            }}
            className="animable"
            opacity="0.15"
          ></path>
          <path
            d="M367.71 217.68l-.33-.77c-.19-.43 1.05-.69 2.76-.58l3.26.21c1.71.11 3.24.54 3.42 1l.33.77z"
            style={{
              WebkitTransformOrigin: 372.255,
              MsTransformOrigin: 372.255,
              transformOrigin: 372.255,
            }}
            className="animable"
            opacity="0.15"
          ></path>
          <path
            d="M365.66 214.92c-.12-.26.37-.43 1.09-.38l1.29.11s-.7-1.67 2.15-1.59A4.36 4.36 0 01374 215l1.39.12a2.52 2.52 0 011.64.6l.25.47-11.43-.77z"
            style={{
              WebkitTransformOrigin: 371.461,
              MsTransformOrigin: 371.461,
              transformOrigin: 371.461,
            }}
            fill="#F0F0F0"
            className="animable"
          ></path>
          <path
            d="M367.32 215.5c1.05.05 1.49.11 1.77.76s.5 1.31.5 1.31l5.06.35s-.43-.95-.58-1.32 0-.64.78-.58v-.32l-7.92-.47z"
            style={{
              WebkitTransformOrigin: 370.89,
              MsTransformOrigin: 370.89,
              transformOrigin: 370.89,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M367.59 217.78l-.33-.77c-.18-.43 1.05-.69 2.76-.58l3.27.21c1.7.11 3.23.54 3.42 1l.32.77z"
            style={{
              WebkitTransformOrigin: 372.136,
              MsTransformOrigin: 372.136,
              transformOrigin: 372.136,
            }}
            fill="#F5F5F5"
            className="animable"
          ></path>
          <path
            d="M395.55 188.5h-18.42v-16.42a13.45 13.45 0 0113.45-13.45A13.45 13.45 0 01404 172.08V180a8.48 8.48 0 01-8.45 8.5z"
            style={{
              WebkitTransformOrigin: 390.565,
              MsTransformOrigin: 390.565,
              transformOrigin: 390.565,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M380.79 166.51c-.78.32-2.4 1.7-2.43 9.71 0 6.78 2.12 8.52 3.21 9s3.23.28 5.31 0l-.05 3.34a14.82 14.82 0 00-1.65 10.2c4.26-3.29 10.65-8.88 10.65-8.88l.2-9.41s1.19 1.22 3.3-.53c1.75-1.44 2.39-3.94 1.1-5.33a3 3 0 00-4.75.81c-4.44 0-6.35-4.95-6.64-8-1.57 1.13-5.85 2.07-8.25-.91z"
            style={{
              WebkitTransformOrigin: 389.736,
              MsTransformOrigin: 389.736,
              transformOrigin: 389.736,
            }}
            fill="#FFA8A7"
            className="animable"
          ></path>
          <path
            d="M383.22 172.43c4.48-.66 5.82-5 5.82-5s2.63-7-4.37-7.41c-10.76-.68-9.69 12.44-9.69 12.44z"
            style={{
              WebkitTransformOrigin: 382.237,
              MsTransformOrigin: 382.237,
              transformOrigin: 382.237,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M386.06 174.51a.84.84 0 10.9-.8.85.85 0 00-.9.8z"
            style={{
              WebkitTransformOrigin: 386.899,
              MsTransformOrigin: 386.899,
              transformOrigin: 386.899,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M386 180.54l-2.31.67a1.19 1.19 0 001.47.86 1.27 1.27 0 00.84-1.53z"
            style={{
              WebkitTransformOrigin: 384.866,
              MsTransformOrigin: 384.866,
              transformOrigin: 384.866,
            }}
            fill="#F28F8F"
            className="animable"
          ></path>
          <path
            d="M379.22 172.43l1.78-1.13a1 1 0 00-1.4-.33 1.08 1.08 0 00-.38 1.46z"
            style={{
              WebkitTransformOrigin: 380.039,
              MsTransformOrigin: 380.039,
              transformOrigin: 380.039,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M386.9 171.53l1.81 1a1 1 0 00-.37-1.39 1.09 1.09 0 00-1.44.39z"
            style={{
              WebkitTransformOrigin: 387.877,
              MsTransformOrigin: 387.877,
              transformOrigin: 387.877,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M379.62 174.35a.85.85 0 00.77.93.86.86 0 00.9-.81.85.85 0 00-.77-.93.86.86 0 00-.9.81z"
            style={{
              WebkitTransformOrigin: 380.455,
              MsTransformOrigin: 380.455,
              transformOrigin: 380.455,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 382.39,
              MsTransformOrigin: 382.39,
              transformOrigin: 382.39,
            }}
            fill="#F28F8F"
            d="M383.76 173.88L383.66 179.35 381.02 178.46 383.76 173.88z"
            className="animable"
          ></path>
          <path
            d="M386.92 185.47c2.15-.22 6.6-1.37 7.33-3.1a4.55 4.55 0 01-1.61 2.19c-1.34 1.09-5.74 2.25-5.74 2.25z"
            style={{
              WebkitTransformOrigin: 390.575,
              MsTransformOrigin: 390.575,
              transformOrigin: 390.575,
            }}
            fill="#F28F8F"
            className="animable"
          ></path>
          <path
            d="M383.84 228.93a14 14 0 004.29-1.25 10.55 10.55 0 01-2.41-.65 1.5 1.5 0 01-.92-1.82c.12-.3.85.06 2.17 0a15.82 15.82 0 014.61 0c.67.14 1.58.6 2.17.26a147.22 147.22 0 0012.81-8.89l-3.9-11.66a15.48 15.48 0 011.06-13.9c2.65.46 4.7 1 6.68 8 2.08 7.4 5 17 4.84 20.06s-8.13 6.2-14.12 9.18c-2.53 1.26-6.71 3.66-8.35 4.51-5.67 2.95-8.42 2.67-10.39.75-3.15-3.05-1.95-4.16 1.46-4.59z"
            style={{
              WebkitTransformOrigin: 397.899,
              MsTransformOrigin: 397.899,
              transformOrigin: 397.899,
            }}
            fill="#FFA8A7"
            className="animable"
          ></path>
          <path
            d="M403.37 190.54c5.37.33 6 4.47 7.53 8.57 1.45 3.82 3.33 13.89 3.33 13.89s-3.93 3.95-8.14 2.92l-3.73-11.69s-4.44-9.03 1.01-13.69z"
            style={{
              WebkitTransformOrigin: 407.443,
              MsTransformOrigin: 407.443,
              transformOrigin: 407.443,
            }}
            fill="#263238"
            className="animable"
          ></path>
        </g>
      </g>
      <g
        style={{
          WebkitTransformOrigin: 223.619,
          MsTransformOrigin: 223.619,
          transformOrigin: 223.619,
        }}
        className="animable animator-active"
      >
        <g
          style={{
            WebkitTransformOrigin: 223.619,
            MsTransformOrigin: 223.619,
            transformOrigin: 223.619,
          }}
          className="animable"
        >
          <g
            style={{
              WebkitTransformOrigin: 236.189,
              MsTransformOrigin: 236.189,
              transformOrigin: 236.189,
            }}
            className="animable"
          >
            <g
              style={{
                WebkitTransformOrigin: 236.189,
                MsTransformOrigin: 236.189,
                transformOrigin: 236.189,
              }}
              className="animable"
            >
              <path
                d="M195.87 305.65c.53-1.25 1.9-2.16 4-2.41a14.47 14.47 0 0112 5.12c1.25 1.67 1.54 3.28 1 4.54l-34.32 89.92c-.67 1.89-2.61 3.27-5.64 3.64-5.79.7-13.31-2.51-16.81-7.16-1.84-2.45-2.19-4.8-1.29-6.59z"
                style={{
                  WebkitTransformOrigin: 183.732,
                  MsTransformOrigin: 183.732,
                  transformOrigin: 183.732,
                }}
                fill="#455A64"
                className="animable"
              ></path>
              <path
                d="M211.87 308.36c2.5 3.34 1.18 6.45-3 6.95a14.46 14.46 0 01-12-5.12c-2.51-3.34-1.18-6.45 3-6.95a14.47 14.47 0 0112 5.12z"
                style={{
                  WebkitTransformOrigin: 204.368,
                  MsTransformOrigin: 204.368,
                  transformOrigin: 204.368,
                }}
                fill="#37474F"
                className="animable"
              ></path>
              <path
                d="M212.76 279.51a8.7 8.7 0 017.27 3.1 2.75 2.75 0 01.61 2.74l-11.13 26.12a2.78 2.78 0 01-2.4 1.45 8.74 8.74 0 01-7.28-3.09 2.79 2.79 0 01-.61-2.75L210.36 281a2.76 2.76 0 012.4-1.49z"
                style={{
                  WebkitTransformOrigin: 209.931,
                  MsTransformOrigin: 209.931,
                  transformOrigin: 209.931,
                }}
                fill="#1DD21D"
                className="animable"
              ></path>
              <path
                d="M212.76 279.51a8.7 8.7 0 017.27 3.1 2.75 2.75 0 01.61 2.74l-11.13 26.12a2.78 2.78 0 01-2.4 1.45 8.74 8.74 0 01-7.28-3.09 2.79 2.79 0 01-.61-2.75L210.36 281a2.76 2.76 0 012.4-1.49z"
                style={{
                  WebkitTransformOrigin: 209.931,
                  MsTransformOrigin: 209.931,
                  transformOrigin: 209.931,
                }}
                className="animable"
                opacity="0.2"
              ></path>
              <path
                d="M205.23 293l.77.45.12.07.15.08.16.09a16.82 16.82 0 001.52.79c.63.32 1.28.6 1.92.85a18.57 18.57 0 001.76.65 296 296 0 002 .58c.35.1.68.19 1 .26a7 7 0 00.77.16l.25.05 5-11.69a2.75 2.75 0 00-.61-2.74 6.24 6.24 0 00-1.05-1.07 37.54 37.54 0 01-9.57 1.64z"
                style={{
                  WebkitTransformOrigin: 213.008,
                  MsTransformOrigin: 213.008,
                  transformOrigin: 213.008,
                }}
                className="animable"
                opacity="0.2"
              ></path>
              <path
                d="M204.85 315l-36.21 91.3a15.79 15.79 0 004.27.13c3-.37 5-1.74 5.64-3.65l34.32-89.9c-.54 1.26-1.91 2.15-4 2.4a11.81 11.81 0 01-4.02-.28z"
                style={{
                  WebkitTransformOrigin: 190.755,
                  MsTransformOrigin: 190.755,
                  transformOrigin: 190.755,
                }}
                className="animable"
                opacity="0.15"
              ></path>
              <path
                d="M318 171.4c0-.75-.05-1.49-.11-2.23s-.09-1.46-.17-2.18c0-.49-.09-1-.15-1.45-.12-1.25-.3-2.49-.51-3.67-.09-.54-.19-1.09-.3-1.63s-.21-1-.31-1.52c-.32-1.37-.65-2.7-1-4-.2-.61-.39-1.21-.59-1.81a1 1 0 01-.09-.28c-.22-.59-.43-1.16-.65-1.72s-.52-1.3-.81-1.91-.41-.9-.65-1.34a7.17 7.17 0 00-.48-1c-.19-.39-.39-.79-.61-1.16a1.53 1.53 0 00-.16-.25c-.18-.33-.38-.67-.58-1s-.28-.42-.41-.63a32.67 32.67 0 00-3.18-4.23c-.2-.23-.41-.45-.59-.67s-.33-.35-.5-.52-.43-.42-.65-.65a20.41 20.41 0 00-1.92-1.72c-.32-.27-.65-.53-1-.77s-.67-.5-1-.74a18.66 18.66 0 00-1.65-1.08l-.44-.27-.09-.08-10.4-6.02-.15-.09a33 33 0 00-16.46-4.16c-8.59 0-18.28 2.81-28.54 8.73C208.56 151.72 180 201.23 180 242c0 20.31 7.1 34.6 18.59 41.29l.1.06 10.31 6.04.12.08.15.08.16.09a16.82 16.82 0 001.52.79 20.4 20.4 0 001.92.85 17.61 17.61 0 001.76.65c.31.11.62.2.94.28a6.88 6.88 0 001 .29 9.88 9.88 0 001 .26 5.19 5.19 0 00.76.17c.45.09.92.19 1.39.24a.86.86 0 00.32 0c.55.09 1.11.17 1.65.23 9.52 1 20.58-1.69 32.41-8.51 35.28-20.37 63.88-69.91 63.88-110.62.09-.94.07-1.9.02-2.87zm-116.35 96.1a62.3 62.3 0 01-2.16-16.71c0-38.6 28.24-87.51 61.66-106.81a59.76 59.76 0 0115.64-6.47 34.34 34.34 0 018-1 25.19 25.19 0 014.08.32h.11c6.84 8.09 7.85 21.06 7.85 28 0 38.59-28.21 87.5-61.65 106.79-8.5 4.91-16.68 7.5-23.69 7.5a16.38 16.38 0 01-4.31-.55 30.42 30.42 0 01-5.53-11.07z"
                style={{
                  WebkitTransformOrigin: 249.021,
                  MsTransformOrigin: 249.021,
                  transformOrigin: 249.021,
                }}
                fill="#37474F"
                className="animable"
              ></path>
              <path
                d="M308.89 171.57c0 20.51-8 43.91-20.45 64.26-.59 1-1.19 1.91-1.81 2.86s-1.05 1.64-1.6 2.45c-1.17 1.74-2.36 3.46-3.6 5.14-.59.8-1.18 1.59-1.79 2.37s-1.32 1.72-2 2.57q-1.93 2.43-4 4.73c-.89 1-1.8 2-2.71 3a103.78 103.78 0 01-23.74 19.4c-1.6.92-3.18 1.77-4.75 2.52a47.39 47.39 0 01-16.25 4.86c-.92.09-1.82.12-2.69.12h-1.35a20.58 20.58 0 01-2.54-.27c-.41-.06-.81-.12-1.2-.21s-.78-.17-1.17-.29a18.69 18.69 0 01-10-6.48 16.41 16.41 0 004.3.55c7 0 15.2-2.59 23.7-7.5 33.44-19.28 61.64-68.2 61.64-106.79 0-6.94-1-19.9-7.84-28h-.11c8.71 1.39 13.67 7.32 16.46 14.17a40.34 40.34 0 011.91 6c0 .11 0 .22.07.34.09.36.18.74.25 1.11s.14.72.21 1.08a2 2 0 01.05.28q.12.58.21 1.14c.21 1.44.39 2.83.51 4.17 0 .39.07.75.09 1.12s.05.56.06.83v.35c0 .3 0 .59.05.88a2.09 2.09 0 010 .25v1.09c.08.62.09 1.33.09 1.9z"
                style={{
                  WebkitTransformOrigin: 258.065,
                  MsTransformOrigin: 258.065,
                  transformOrigin: 258.065,
                }}
                className="animable"
                opacity="0.25"
              ></path>
              <path
                d="M208.14 288.88l-9.59-5.64C187.06 276.53 180 262.25 180 242c0-18.16 5.7-38.06 15.11-56.34l10.89 6.25c-9.43 18.28-15.67 38-15.67 56.14-.01 19.86 6.75 33.95 17.81 40.83z"
                style={{
                  WebkitTransformOrigin: 194.07,
                  MsTransformOrigin: 194.07,
                  transformOrigin: 194.07,
                }}
                fill="#263238"
                className="animable"
              ></path>
              <path
                d="M299.46 133l-.09-.08-10.37-6.04-.14-.09a32.88 32.88 0 00-16.47-4.17c-8.58 0-18.28 2.81-28.55 8.73-19.54 11.29-37 31.54-48.74 54.28l10.84 6.37-.24.45c11.75-22.49 29.16-42.85 48.49-55 17.5-11 34.11-11.18 45.71-4.12z"
                style={{
                  WebkitTransformOrigin: 247.5,
                  MsTransformOrigin: 247.5,
                  transformOrigin: 247.5,
                }}
                fill="#455A64"
                className="animable"
              ></path>
            </g>
            <path
              d="M303.33 168.3c0 38.59-28.24 87.51-61.66 106.8a59.47 59.47 0 01-15.67 6.48 34.31 34.31 0 01-8.05 1 23 23 0 01-8.18-1.39c-4.15-3.48-6.65-8.52-8.15-13.7a62.3 62.3 0 01-2.16-16.71c0-38.6 28.24-87.51 61.66-106.81a59.76 59.76 0 0115.64-6.47 34.34 34.34 0 018-1 23.08 23.08 0 018.18 1.4c4.15 3.47 6.65 8.52 8.15 13.7a62.25 62.25 0 012.24 16.7z"
              style={{
                WebkitTransformOrigin: 251.394,
                MsTransformOrigin: 251.394,
                transformOrigin: 251.394,
              }}
              fill="#FFF"
              className="animable"
              opacity="0.3"
            ></path>
          </g>
          <g
            style={{
              WebkitTransformOrigin: 166.814,
              MsTransformOrigin: 166.814,
              transformOrigin: 166.814,
            }}
            className="animable"
          >
            <path
              d="M172.14 343.48c1.89.42 5.5.8 5.5.8l2.59-5.49-5.81-2.69z"
              style={{
                WebkitTransformOrigin: 176.185,
                MsTransformOrigin: 176.185,
                transformOrigin: 176.185,
              }}
              fill="#FFA8A7"
              className="animable"
            ></path>
            <path
              d="M158 339.38a38.47 38.47 0 0014.67 4.17l3.12-4.7-15.68-10.57z"
              style={{
                WebkitTransformOrigin: 166.895,
                MsTransformOrigin: 166.895,
                transformOrigin: 166.895,
              }}
              fill="#1DD21D"
              className="animable"
            ></path>
            <path
              d="M158 339.38a38.47 38.47 0 0014.67 4.17l3.12-4.7-15.68-10.57z"
              style={{
                WebkitTransformOrigin: 166.895,
                MsTransformOrigin: 166.895,
                transformOrigin: 166.895,
              }}
              className="animable"
              opacity="0.3"
            ></path>
            <path
              d="M171.69 343.47l2.64.39 2-6s-2.59-1.24-2.66-1.2-1.98 6.81-1.98 6.81z"
              style={{
                WebkitTransformOrigin: 174.01,
                MsTransformOrigin: 174.01,
                transformOrigin: 174.01,
              }}
              fill="#1DD21D"
              className="animable"
            ></path>
            <path
              d="M171.69 343.47l2.64.39 2-6s-2.59-1.24-2.66-1.2-1.98 6.81-1.98 6.81z"
              style={{
                WebkitTransformOrigin: 174.01,
                MsTransformOrigin: 174.01,
                transformOrigin: 174.01,
              }}
              className="animable"
              opacity="0.4"
            ></path>
            <path
              d="M136.7 306.08a6.31 6.31 0 00-6.42 3.32 13.37 13.37 0 00-1 6.77c.6 5.29 4.57 16.84 4.57 16.84z"
              style={{
                WebkitTransformOrigin: 132.948,
                MsTransformOrigin: 132.948,
                transformOrigin: 132.948,
              }}
              fill="#1DD21D"
              className="animable"
            ></path>
            <path
              d="M136.7 306.08a6.31 6.31 0 00-6.42 3.32 13.37 13.37 0 00-1 6.77c.6 5.29 4.57 16.84 4.57 16.84z"
              style={{
                WebkitTransformOrigin: 132.948,
                MsTransformOrigin: 132.948,
                transformOrigin: 132.948,
              }}
              className="animable"
              opacity="0.4"
            ></path>
            <path
              d="M131 430.29c-.46.17-.72 1-.92 3.12-.12 1.3-.57 3 1.41 4.07a5 5 0 004.68-.05c1.72-1.06 2.58-3 4.59-4.61 1.77-1.38 4-2.4 5.41-4.92s1.2-4.19-1.73-4.19c-4.7 0-6.11 2.59-8.53 2.64-1.31.03-4.42 3.77-4.91 3.94z"
              style={{
                WebkitTransformOrigin: 138.483,
                MsTransformOrigin: 138.483,
                transformOrigin: 138.483,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M131 429.25v2.12c0 1 4.4 2.57 5.63.21.09-.2.57-3.1.57-3.1z"
              style={{
                WebkitTransformOrigin: 134.1,
                MsTransformOrigin: 134.1,
                transformOrigin: 134.1,
              }}
              fill="#FFA8A7"
              className="animable"
            ></path>
            <path
              d="M151.41 442.59s-.83 0-1.16 3.41c-.13 1.3-.44 4 0 4.93s2.85 1.39 5.55 1.63a14.55 14.55 0 015.69 2.2 12.16 12.16 0 005.76 1.59c2.55 0 4.71-.25 6.53-1.69 1-.76 2.19-2.37 1.29-3.59a2.67 2.67 0 00-1.36-.71c-1.13-.43-2.28-.82-3.43-1.18a36.24 36.24 0 01-5.05-1.93c-2.38-1.18-5-2.49-6.83-4.49z"
              style={{
                WebkitTransformOrigin: 162.705,
                MsTransformOrigin: 162.705,
                transformOrigin: 162.705,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M151.42 440.83v2.46c0 .89 1 2.24 3.78 2.16 1.93-.06 3.27-.57 3.2-2.16V441z"
              style={{
                WebkitTransformOrigin: 154.911,
                MsTransformOrigin: 154.911,
                transformOrigin: 154.911,
              }}
              fill="#FFA8A7"
              className="animable"
            ></path>
            <path
              d="M129.81 401.2a58.13 58.13 0 012.55-11s-.7-9.5-1-18.51a239.43 239.43 0 01.77-27.66s30.61-2.9 31.11 8.34-.23 39.85-.62 44.79-.8 9.57-1.84 18.75c-.9 7.92-2.36 25.09-2.36 25.09a7.41 7.41 0 01-7-.18s-2.3-23.06-2.57-28.76c-.34-7.38 1.38-11.46 1.38-11.46s-.65-4.64-1.79-14.2c-.8-6.75-1.87-14.43-1.87-14.43s-1 9.51-1.68 14.81c-.59 4.92-1.59 8.81-3.07 18-1.28 8-4.77 24.78-4.77 24.78a5.22 5.22 0 01-6-.3s-2.24-21.16-1.24-28.06z"
              style={{
                WebkitTransformOrigin: 146.481,
                MsTransformOrigin: 146.481,
                transformOrigin: 146.481,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M146.57 372l-1.45-9.4s-6-1.89-9-5.44c0 0 1.61 4.13 7.16 6.35l2.23 7.91.38 6.53z"
              style={{
                WebkitTransformOrigin: 141.345,
                MsTransformOrigin: 141.345,
                transformOrigin: 141.345,
              }}
              className="animable"
            ></path>
            <path
              style={{
                WebkitTransformOrigin: 146.36,
                MsTransformOrigin: 146.36,
                transformOrigin: 146.36,
              }}
              fill="#FFA8A7"
              d="M151.59 303.31L150.16 313.35 141.13 313.04 142 301.31 151.59 303.31z"
              className="animable"
            ></path>
            <path
              d="M158.08 290.92s2.42 3.55 2.16 3.91a6.15 6.15 0 01-2 .7z"
              style={{
                WebkitTransformOrigin: 159.17,
                MsTransformOrigin: 159.17,
                transformOrigin: 159.17,
              }}
              fill="#F28F8F"
              className="animable"
            ></path>
            <path
              d="M154.29 290.33a3 3 0 113 2.91 2.95 2.95 0 01-3-2.91zm.65 0a2.3 2.3 0 102.26-2.33 2.3 2.3 0 00-2.26 2.32z"
              style={{
                WebkitTransformOrigin: 157.289,
                MsTransformOrigin: 157.289,
                transformOrigin: 157.289,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M151.35 281.08c4.8.94 6.22 3 6.79 10.62.6 7.95-.17 10.46-1.2 11.55-.69.73-4.61.92-6.6.41-2.48-.64-8.1-2.54-10.79-6.49-3.15-4.66-4.06-11-.8-13.69 4.58-3.76 10.64-2.78 12.6-2.4z"
              style={{
                WebkitTransformOrigin: 147.527,
                MsTransformOrigin: 147.527,
                transformOrigin: 147.527,
              }}
              fill="#FFA8A7"
              className="animable"
            ></path>
            <path
              d="M151.52 303.86c2.06.23 4.85 0 5.42-.61a14.22 14.22 0 01-5.14-1z"
              style={{
                WebkitTransformOrigin: 154.23,
                MsTransformOrigin: 154.23,
                transformOrigin: 154.23,
              }}
              fill="#F28F8F"
              className="animable"
            ></path>
            <path
              d="M158.89 283l-.15.15c-1 .93-2.18 1.53-3.17 2.41a7.44 7.44 0 00-2.22 3.66c-.31 1.16-.42 2.53-1.67 3.08 0 0-.84-3.06-3.42-2.63s-2.91 3.76-1.23 5.75 3.2 1.2 3.2 1.2 0 4.4-1.92 6.92a13.31 13.31 0 01-6.3-.06c-2.51-.49-6.1-3.57-7.65-6.86-3.26-7-3.58-13.27.87-15.24.44-3.24 3-4.73 5.85-5.44 4.32-1.06 8.42 1.45 12.7.42a27.48 27.48 0 012.67-.68.54.54 0 01.31 0c.23.11.22.42.16.66a5.68 5.68 0 01-1.23 2.34 13.31 13.31 0 004.85-.7 6.25 6.25 0 01-1.65 5.02z"
              style={{
                WebkitTransformOrigin: 146.242,
                MsTransformOrigin: 146.242,
                transformOrigin: 146.242,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M135.87 283l-2.87-1.7a1.66 1.66 0 112.89 1.65z"
              style={{
                WebkitTransformOrigin: 134.555,
                MsTransformOrigin: 134.555,
                transformOrigin: 134.555,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M151.73 294l-.14-3.32a1.66 1.66 0 11.14 3.32z"
              style={{
                WebkitTransformOrigin: 152.456,
                MsTransformOrigin: 152.456,
                transformOrigin: 152.456,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              style={{
                WebkitTransformOrigin: 155.46,
                MsTransformOrigin: 155.46,
                transformOrigin: 155.46,
              }}
              fill="#263238"
              d="M159.9 289.63L151.01 290.92 151.38 291.54 159.91 290.3 159.9 289.63z"
              className="animable"
            ></path>
            <path
              d="M131.16 311.61c1.05-4.12 2.54-5.46 6.13-5.56a47.78 47.78 0 015.51.7c.38 1.75 4.88 2.93 9.43 2 1.36.39 5 1.37 6.07 1.78 3.23 1.17 4.79 4.52 5 15.14 0 0 .62 25.2 0 29.55a39.07 39.07 0 01-21.22 2c-9.69-2.17-10.84-6.2-10.84-6.2s.51-10.11.84-17.8c-.49-4.9-1.22-8.58-1.48-12.49a32.63 32.63 0 01.56-9.12z"
              style={{
                WebkitTransformOrigin: 147.037,
                MsTransformOrigin: 147.037,
                transformOrigin: 147.037,
              }}
              fill="#1DD21D"
              className="animable"
            ></path>
            <g
              style={{
                WebkitTransformOrigin: 147.037,
                MsTransformOrigin: 147.037,
                transformOrigin: 147.037,
              }}
              className="animable"
              opacity="0.6"
            >
              <path
                d="M131.16 311.61c1.05-4.12 2.54-5.46 6.13-5.56a47.78 47.78 0 015.51.7c.38 1.75 4.88 2.93 9.43 2 1.36.39 5 1.37 6.07 1.78 3.23 1.17 4.79 4.52 5 15.14 0 0 .62 25.2 0 29.55a39.07 39.07 0 01-21.22 2c-9.69-2.17-10.84-6.2-10.84-6.2s.51-10.11.84-17.8c-.49-4.9-1.22-8.58-1.48-12.49a32.63 32.63 0 01.56-9.12z"
                style={{
                  WebkitTransformOrigin: 147.037,
                  MsTransformOrigin: 147.037,
                  transformOrigin: 147.037,
                }}
                fill="#FFF"
                className="animable"
              ></path>
            </g>
            <path
              d="M141.79 304.15l-.07 1s.47 1.37 3.21 1.88c4.61.85 6.22-.58 6.22-.58l.12-.83s.44-.18 1.19.4a39.49 39.49 0 013.19 3.73s-.88-.26-3.76 1a13.73 13.73 0 01-9 .34 4.94 4.94 0 01-3.28-3.69c-.11-.91 1.39-3.6 2.18-3.25z"
              style={{
                WebkitTransformOrigin: 147.627,
                MsTransformOrigin: 147.627,
                transformOrigin: 147.627,
              }}
              fill="#1DD21D"
              className="animable"
            ></path>
            <path
              d="M141.79 304.15l-.07 1s.47 1.37 3.21 1.88c4.61.85 6.22-.58 6.22-.58l.12-.83s.44-.18 1.19.4a39.49 39.49 0 013.19 3.73s-.88-.26-3.76 1a13.73 13.73 0 01-9 .34 4.94 4.94 0 01-3.28-3.69c-.11-.91 1.39-3.6 2.18-3.25z"
              style={{
                WebkitTransformOrigin: 147.627,
                MsTransformOrigin: 147.627,
                transformOrigin: 147.627,
              }}
              className="animable"
              opacity="0.25"
            ></path>
            <path
              d="M157.94 310.32c4.1.51 7.34 1.37 9.13 5.28 3 6.62 6.31 12.06 8.7 14.62 2.55 2.73 13 7.53 15.36 7.15s4.7-3.09 5.92-2.39-1 3.33-.89 3.92 4.42.28 6.44-1.15 2.77 1.52.15 4.58-7.52 3.3-12.85 2.23-16.18-3.61-20.27-7c-5-4.09-5.84-7.45-7.95-11.11-7.68-7.84-3.74-16.13-3.74-16.13z"
              style={{
                WebkitTransformOrigin: 180.68,
                MsTransformOrigin: 180.68,
                transformOrigin: 180.68,
              }}
              fill="#FFA8A7"
              className="animable"
            ></path>
            <path
              d="M157.34 310.21s4.39 0 6.69 1.4 3.75 4.41 5.65 8.55a55.42 55.42 0 006.24 9.82c1 1.24 8.3 5.3 8.3 5.3a31.09 31.09 0 01-2.15 7.57s-7.34-1.84-11.76-4.21-11.14-11.86-11.14-11.86-4.73-6.28-1.83-16.57z"
              style={{
                WebkitTransformOrigin: 170.31,
                MsTransformOrigin: 170.31,
                transformOrigin: 170.31,
              }}
              fill="#1DD21D"
              className="animable"
            ></path>
            <path
              d="M157.34 310.21s4.39 0 6.69 1.4 3.75 4.41 5.65 8.55a55.42 55.42 0 006.24 9.82c1 1.24 8.3 5.3 8.3 5.3a31.09 31.09 0 01-2.15 7.57s-7.34-1.84-11.76-4.21-11.14-11.86-11.14-11.86-4.73-6.28-1.83-16.57z"
              style={{
                WebkitTransformOrigin: 170.31,
                MsTransformOrigin: 170.31,
                transformOrigin: 170.31,
              }}
              className="animable"
              opacity="0.15"
            ></path>
            <path
              d="M183.77 335a17.23 17.23 0 01-2.29 7.67 38.9 38.9 0 003.93.85s2.3-2.31 2.17-6.92z"
              style={{
                WebkitTransformOrigin: 184.533,
                MsTransformOrigin: 184.533,
                transformOrigin: 184.533,
              }}
              fill="#1DD21D"
              className="animable"
            ></path>
            <path
              d="M183.77 335a17.23 17.23 0 01-2.29 7.67 38.9 38.9 0 003.93.85s2.3-2.31 2.17-6.92z"
              style={{
                WebkitTransformOrigin: 184.533,
                MsTransformOrigin: 184.533,
                transformOrigin: 184.533,
              }}
              className="animable"
              opacity="0.3"
            ></path>
            <path
              d="M186 337.13a.47.47 0 01-.46.46.47.47 0 010-.93.47.47 0 01.46.47z"
              style={{
                WebkitTransformOrigin: 185.569,
                MsTransformOrigin: 185.569,
                transformOrigin: 185.569,
              }}
              fill="#455A64"
              className="animable"
            ></path>
          </g>
        </g>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood
            floodColor="#32DFEC"
            floodOpacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood floodColor="red" floodOpacity="0.5" result="PINK"></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
        </filter>
      </defs>
    </svg>
  );
};

export default AboutSvg;
