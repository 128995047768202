import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EducationSvg from "../../../images/AnimatingSvg/Education";

import styles from "./TokenUtility.module.css";
import "./TokenUtility.css";
import AgricultureSvg from "../../../images/AnimatingSvg/Agriculture";
import HealthSvg from "../../../images/AnimatingSvg/Health";
import EnergySvg from "../../../images/AnimatingSvg/Energy";

const TokenUtility = () => {
  const arrowRef = useRef(null);
  let data = [
    {
      img: <EducationSvg />,
      title: "Education",
    },
    // {
    //   img: <EnergySvg />,
    //   title: "Energy",
    // },
    {
      img: <HealthSvg />,
      title: "Health",
    },
    {
      img: <AgricultureSvg />,
      title: "Agriculture",
    },
    {
      img: <EducationSvg />,
      title: "Education",
    },
    // {
    //   img: <EnergySvg />,
    //   title: "Energy",
    // },
    {
      img: <HealthSvg />,
      title: "Health",
    },
    {
      img: <AgricultureSvg />,
      title: "Agriculture",
    },
  ];
  const settings = {
    className: "center",
    centerPadding: 0,
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4.34,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 2.34,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.32,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,

          centerMode: false,
        },
      },
    ],
  };

  return (
    <div
      id="tokenUtility"
      className={[styles.wrapper, "sectionPadding"].join(" ")}
    >
      <div className={styles.headingContainer}>
        {" "}
        <h3 className={["sectionHeading", styles.heading].join(" ")}>
          Token
          <span className="highlight"> Utility</span>
        </h3>
      </div>
      <Slider ref={arrowRef} {...settings}>
        {data.map((el, i) => (
          <div className={styles.sliderWrapper} key={i}>
            <div
              key={i}
              className={[styles.utilityTokenCard, "project"].join(" ")}
            >
              <figure className={styles.figure}>{el.img}</figure>
              <figcaption>
                <h3 className={styles.title}>{el.title}</h3>
              </figcaption>
            </div>
          </div>
        ))}
      </Slider>{" "}
    </div>
  );
};

export default TokenUtility;
