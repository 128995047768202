import React from "react";
import HeroSection from "../Components/Home/HeroSection/HeroSection";
import AboutUs from "../Components/Home/AboutUs/AboutUs";
import Tokenomics from "../Components/Home/Tokenomics/Tokenomics";
import TokenUtility from "../Components/Home/TokenUtility/TokenUtility";
import Team from "../Components/Home/Team/Team";
import Projects from "../Components/Home/Projects/Projects";
import UpComingProjects from "../Components/Home/UpComingProjects/UpComingProjects";
import RoadMap from "../Components/Home/RoadMap/RoadMap";

import ImageSlider from "../Components/Home/ImageSlider/ImageSlider";
import VideoSlider from "../Components/Home/VideoSlider/VideoSlider";

const Home = () => {
  return (
    <>
      <HeroSection />
      <AboutUs />
      <Tokenomics />
      <ImageSlider />
      <VideoSlider />
      <Team />
      <TokenUtility />
      {/* <Projects /> */}
      {/* <UpComingProjects /> */}
      <RoadMap />
    </>
  );
};

export default Home;
