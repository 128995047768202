import React, { useState } from "react";
import ReactPlayer from "react-player";
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./VideoSlider.module.css";
import { faq } from "../../../images/images";
import "./VideoSlider.css";

const VideoSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderData = [
    "https://www.youtube.com/embed/vyM61IO5mCQ",
    "https://www.youtube.com/embed/191iogQhaQU",
    "https://www.youtube.com/embed/iatHXYt3wt0",
    "https://www.youtube.com/embed/aob4XSmpITs",
    "https://www.youtube.com/embed/F2YfEtGNcrI",
    "https://www.youtube.com/embed/NmfAzhPaoH8",
    "https://www.youtube.com/embed/aU6OlhKQF8E",
    "https://www.youtube.com/embed/pYTxXG1aRQQ",
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow isActive={!currentSlide} />,
    nextArrow: (
      <CustomNextArrow isActive={currentSlide === sliderData.length - 1} />
    ),
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  return (
    <div id="videoSlider" className="sectionPadding">
      <div className={["appContainer", styles.wrapper].join(" ")}>
        <div className={styles.sliderWrapper}>
          <Slider {...settings}>
            {sliderData.map((video, i) => (
              <div key={i} className={styles.videoContainer}>
                <ReactPlayer
                  url={video}
                  className={styles.reactPlayer}
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default VideoSlider;
const CustomNextArrow = ({ isActive, onClick }) => {
  // Custom previous arrow component
  return (
    <button
      className={`${styles.nextButton} ${isActive ? styles.activeButton : ""}`}
      onClick={onClick}
    >
      <BiSolidChevronRight className={styles.arrow} />{" "}
    </button>
  );
};

const CustomPrevArrow = ({ isActive, onClick }) => {
  // Custom next arrow component
  return (
    <button
      onClick={onClick}
      className={`${styles.previousButton} ${isActive ? styles.activeButton : ""
        }`}
    >
      <BiSolidChevronLeft className={styles.arrow} />
    </button>
  );
};
